import classes from './MWCalculators.module.css';

import LeftNav from "../General/LeftNav/LeftNav";

import mwBanner from '../../assets/images/mw_banner.jpg';
import fglToken from '../../assets/images/general/fgl-token.png';
import waxToken from '../../assets/images/general/wax.png';

import { useEffect, useState } from "react";

interface IPartPicker {
  value: number;
  label: string;
}

const MWCalculators = () => {
  // General
  const [partFindMultiplier, setPartFindMultiplier] = useState(1);
  const [fglValue, setFGLValue] = useState(0.055);
  const [questHours, setQuestHours] = useState(16);
  const [levelArray, setLevelArray] = useState<number[] | []>([]);
  const [averageNFTValue, setAverageNFTValue] = useState(100);
  const [packSize, setPackSize] = useState(1);
  const [packSizeArray, setPackSizeArray] = useState<number[] | []>([]);
  const [dailyEnergy, setDailyEnergy] = useState(0);
  const [energyRegen, setEnergyRegen] = useState(0);
  const [questsPerDay, setQuestsPerDay] = useState(0);

  // Token ROI
  const [roiPaid, setRoiPaid] = useState(25);
  const [roiMechaLevel, setRoiMechaLevel] = useState(1);
  const [roiQuestType, setRoiQuestType] = useState("long");
  const [roiFGLPerDay, setRoiFGLPerDay] = useState(0);
  const [roiXPPerDay, setRoiXPPerDay] = useState(0);
  const [roiDaysToROI, setRoiDaysToRoi] = useState(0);
  const [roiTokenFindPercentage, setRoiTokenFindPercentage] = useState(0);
  
  // NFT Finding
  const [nftArm, setNftArm] = useState(0);
  const [nftBack, setNftBack] = useState(0);
  const [nftShoulder, setNftShoulder] = useState(0);
  const [nftTail, setNftTail] = useState(0);
  const [nftArmor, setNftArmor] = useState(0);
  const [nftBattery, setNftBattery] = useState(0);
  const [nftClaws, setNftClaws] = useState(0);
  const [nftEye, setNftEye] = useState(0);
  const [nftHardDrive, setNftHardDrive] = useState(0);
  const [nftProcessor, setNftProcessor] = useState(0);
  const [nftRAM, setNftRam] = useState(0);
  const [nftSensor, setNftSensor] = useState(0);
  const [nftTeeth, setNftTeeth] = useState(0);
  
  const [nftLuck, setNftLuck] = useState(0);
  const [nftPercentFind, setNftPercentFind] = useState(0);
  const [nftRepairPerQuest, setNftRepairPerQuest] = useState(0);
  const [nftDailyRepairCost, setNftDailyRepairCost] = useState(0);
  const [nftQuestsToFind, setNftQuestsToFind] = useState(0);
  const [nftRepairCostToFind, setNftRepairCostToFind] = useState(0);
  const [nftRepairCostToFindInWAX, setNftRepairCostToFindInWAX] = useState(0);
  const [nftDaysToFind, setNftDaysToFind] = useState(0);

  const [batteryEfficiency, setBatteryEfficiency] = useState(0);

  const [partRarityArray, setParyRarityArray] = useState<IPartPicker[]>([]);
  
  let clicksEnabled = true;

  // Initialize calculations on startup
  useEffect(() => {       
    // Update calcs
    updateCalculations();

    // Init the level array
    let levels:number[] = [];
    for (let i:number = 1; i <= 100; i++ ) {
      levels.push(i);
    }
    setLevelArray(levels);

    // Init the pack size array
    let sizes:number[] = [];
    for (let i:number = 1; i <= 8; i++ ) {
      sizes.push(i);
    }
    setPackSizeArray(sizes);

    // Init the part rarity values
    partRarityArray.push({value: 0, label:"None"});
    partRarityArray.push({value: 2, label:"Common"});
    partRarityArray.push({value: 3, label:"Uncommon"});
    partRarityArray.push({value: 4, label:"Rare"});
    partRarityArray.push({value: 5, label:"Very Rare"});
    partRarityArray.push({value: 6, label:"Ultra Rare"});
    partRarityArray.push({value: 7, label:"Epic"});
    partRarityArray.push({value: 8, label:"Mythic"});

  }, []);

  // Update the calculations when any inputs are changed
  useEffect(() => {       
    updateCalculations();    

  }, [roiPaid, roiMechaLevel, roiQuestType, packSize, nftArm, nftArmor, nftBack, nftClaws, nftEye, nftHardDrive, 
    nftProcessor, nftRAM, nftSensor, nftShoulder, nftTail, nftTeeth, nftBattery]);


  const clickSponsor = () => {       
    window.open("https://www.mechaworld.io/");    
  }

  const updateCalculations = () => {
    // Daily Energy
    let daily:number = packSize * 0.25 * 24;
    if (daily > (packSize * 4)) {
      daily = packSize * 4;
    }

    // Max energy pool
    let maxEnergyPool:number = daily;

    // Energy Regen
    let regen:number = packSize * 0.25;
    if (regen > 2) {
      regen = 2;
    }

    // Quest Type
    let energyPerQuest:number = 0;
    let baseTokensPerQuest:number = 0;
    let xpPerQuest:number = 0;
    let partWearPerQuest:number = 0;
    let baseFind:number = 0;    
    let questLength:number = 0;
    switch (roiQuestType) {
      case "short":
        questLength = 0.25;
        energyPerQuest = 1;
        baseTokensPerQuest = 0.55;
        xpPerQuest = 1;
        partWearPerQuest = 1;
        baseFind = 0.000001;        
        break;
      case "medium":
        questLength = 1.5;
        energyPerQuest = 4;
        baseTokensPerQuest = 2.5;
        xpPerQuest = 4;
        partWearPerQuest = 4;
        baseFind = 0.000004;        
        break;
      case "long":
        questLength = 4;
        energyPerQuest = 8;
        baseTokensPerQuest = 6;
        xpPerQuest = 8;
        partWearPerQuest = 8;
        baseFind = 0.00001;        
        break;
    }

    // Battery efficiency
    let efficiency:number = 0;
    switch (+nftBattery) {
      case 2:
        efficiency = 0.1;
        break;
      case 3:
        efficiency = 0.15;
        break;
      case 4:
        efficiency = 0.2;
        break;
      case 5:
        efficiency = 0.3;
        break;
      case 6:
        efficiency = 0.4;
        break;
      case 7:
        efficiency = 0.5;
        break;
      case 8:
        efficiency = 0.6;
        break;
    }

    // How many quests can we fit in the given day length
    let questsForHours:number = (questHours / questLength) + 1;
    
    // Add some regen to the daily
    daily += questHours * regen;

    let quests:number = daily / (energyPerQuest - (energyPerQuest * efficiency));

    // Limited by X hours in a day
    if (quests > questsForHours) {
      quests = questsForHours;
    }

    // Token find percentage
    let findPercentage:number = 0.5 + (roiMechaLevel*0.01);
    if (findPercentage > 1) {
      findPercentage = 1;
    }

    // FGL per day    
    let fglPerDay:number = quests * findPercentage * baseTokensPerQuest;
    
    // XP Per day
    let xpPerDay:number = quests * xpPerQuest;

    // Days for ROI
    let daysForRoi:number = roiPaid / (fglPerDay * fglValue);

    // Luck
    let luck:number = +nftArm + +nftArmor + +nftBack + +nftClaws + +nftEye + +nftHardDrive + +nftProcessor + 
      +nftRAM + +nftSensor + +nftShoulder + +nftTail + +nftTeeth;
    
    // If they have a battery, it gives one more luck
    let luckWithBattery:number = luck;
    if (nftBattery > 0) {
      luckWithBattery++;
    }
        
    // % find
    let percentFind:number = (20 + (roiMechaLevel * 0.8)) * luckWithBattery * baseFind * 100 * partFindMultiplier;

    // Quests to find
    let questsToFind:number = 0;
    if (percentFind > 0) {
      questsToFind = 100 / (percentFind);
    }

    // Days to find
    let daysToFind:number = questsToFind / quests;

    // Repair per quest
    let repairQuest:number = 0;
    if (nftArm > 0) {
      repairQuest += (nftArm-1) * partWearPerQuest * 0.1;
    }
    if (nftArmor > 0) {
      repairQuest += (nftArmor-1) * partWearPerQuest * 0.1;
    }
    if (nftBack > 0) {
      repairQuest += (nftBack-1) * partWearPerQuest * 0.1;
    }
    if (nftClaws > 0) {
      repairQuest += (nftClaws-1) * partWearPerQuest * 0.1;
    }
    if (nftEye > 0) {
      repairQuest += (nftEye-1) * partWearPerQuest * 0.1;
    }
    if (nftHardDrive > 0) {
      repairQuest += (nftHardDrive-1) * partWearPerQuest * 0.1;
    }
    if (nftProcessor > 0) {
      repairQuest += (nftProcessor-1) * partWearPerQuest * 0.1;
    }
    if (nftRAM > 0) {
      repairQuest += (nftRAM-1) * partWearPerQuest * 0.1;
    }
    if (nftSensor > 0) {
      repairQuest += (nftSensor-1) * partWearPerQuest * 0.1;
    }
    if (nftShoulder > 0) {
      repairQuest += (nftShoulder-1) * partWearPerQuest * 0.1;
    }
    if (nftTail > 0) {
      repairQuest += (nftTail-1) * partWearPerQuest * 0.1;
    }
    if (nftTeeth > 0) {
      repairQuest += (nftTeeth-1) * partWearPerQuest * 0.1;
    }
    if (nftBattery > 0) {
      repairQuest += (nftBattery-1) * partWearPerQuest * 0.1;
    }

    // Daily Repair Cost
    let dailyWear:number = partWearPerQuest * quests;

    // Daily repair
    let dailyRepair:number = 0;

    if (nftArm > 0) {
      dailyRepair += (nftArm-1) * dailyWear * 0.1;
    }
    if (nftArmor > 0) {
      dailyRepair += (nftArmor-1) * dailyWear * 0.1;
    }
    if (nftBack > 0) {
      dailyRepair += (nftBack-1) * dailyWear * 0.1;
    }
    if (nftClaws > 0) {
      dailyRepair += (nftClaws-1) * dailyWear * 0.1;
    }
    if (nftEye > 0) {
      dailyRepair += (nftEye-1) * dailyWear * 0.1;
    }
    if (nftHardDrive > 0) {
      dailyRepair += (nftHardDrive-1) * dailyWear * 0.1;
    }
    if (nftProcessor > 0) {
      dailyRepair += (nftProcessor-1) * dailyWear * 0.1;
    }
    if (nftRAM > 0) {
      dailyRepair += (nftRAM-1) * dailyWear * 0.1;
    }
    if (nftSensor > 0) {
      dailyRepair += (nftSensor-1) * dailyWear * 0.1;
    }
    if (nftShoulder > 0) {
      dailyRepair += (nftShoulder-1) * dailyWear * 0.1;
    }
    if (nftTail > 0) {
      dailyRepair += (nftTail-1) * dailyWear * 0.1;
    }
    if (nftTeeth > 0) {
      dailyRepair += (nftTeeth-1) * dailyWear * 0.1;
    }
    if (nftBattery > 0) {
      dailyRepair += (nftBattery-1) * dailyWear * 0.1;
    }

    // Cost to find
    let costToFind:number = dailyRepair * daysToFind;
    let costToFindInWax:number = costToFind * fglValue;

    // Set General Results
    setDailyEnergy(maxEnergyPool);
    setQuestsPerDay(quests);  
    setEnergyRegen(regen);
    setBatteryEfficiency(efficiency);

    // Set ROI Results
    setRoiFGLPerDay(fglPerDay);
    setRoiXPPerDay(xpPerDay);
    setRoiDaysToRoi(daysForRoi);
    setRoiTokenFindPercentage(findPercentage*100);
    
    // Set NFT Results
    setNftLuck(luckWithBattery);
    setNftPercentFind(percentFind);
    setNftQuestsToFind(questsToFind);
    setNftDaysToFind(daysToFind);
    setNftRepairPerQuest(repairQuest);
    setNftDailyRepairCost(dailyRepair);
    setNftRepairCostToFind(costToFind);
    setNftRepairCostToFindInWAX(costToFindInWax);
  }

  const changeWaxPaid = (e:any) => {    
    if (clicksEnabled) {
      clicksEnabled = false;
            
      setRoiPaid(e.target.value);
    }
  }

  const changeRoiLevel = (e:any) => {    
    if (clicksEnabled) {
      clicksEnabled = false;
            
      setRoiMechaLevel(e.target.value);
    }
  }

  const changeRoiQuest = (e:any) => {    
    if (clicksEnabled) {
      clicksEnabled = false;
            
      setRoiQuestType(e.target.value);
    }
  }

  const changePackSize = (e:any) => {    
    if (clicksEnabled) {
      clicksEnabled = false;
            
      setPackSize(e.target.value);
    }
  }

  const changePartRarity = (part:string, e:any) => {    
    if (clicksEnabled) {
      clicksEnabled = false;
            
      switch (part) {
        case "arm":
          setNftArm(e.target.value);
          break;
        case "back":
          setNftBack(e.target.value);
          break;
        case "shoulder":
          setNftShoulder(e.target.value);
          break;
        case "tail":
          setNftTail(e.target.value);
          break;    
        case "armor":
          setNftArmor(e.target.value);
          break;
        case "battery":
          setNftBattery(e.target.value);
          break;
        case "claws":
          setNftClaws(e.target.value);
          break;
        case "eye":
          setNftEye(e.target.value);
          break;
        case "harddrive":
          setNftHardDrive(e.target.value);
          break;
        case "processor":
          setNftProcessor(e.target.value);
          break;
        case "ram":
          setNftRam(e.target.value);
          break;
        case "sensor":
          setNftSensor(e.target.value);
          break;
        case "teeth":
          setNftTeeth(e.target.value);
          break;                                      
      }      
    }
  }

  
  
  return (    
    <div className={classes['main-content']}>
      <LeftNav />
      <div className={classes['center-content']}>                        
        <div className={classes['mission-holder']}>
        <img src={mwBanner} 
            onClick={() => clickSponsor()}
            className={classes['banner-desktop']}
            />    
          <div className={classes['battle-top']}>Mecha World Calculators</div>
          
          {partFindMultiplier > 1 ?
            <div className={classes['battle-top-banner']}>{partFindMultiplier}x NFT Find Multiplier in effect!</div>
          : null }

          <div className={classes['leaderboard-top-row']}> 
            <fieldset className={classes['details-block']}>
              <legend className={classes['details-title']}>Inputs</legend>
              
              
              
              <div className={classes['details-column']}>
                <div className={classes['calc-title']}>Assumptions:</div>
                <div className={classes['orbatroid-step']}>- Average values shown</div>
                <div className={classes['orbatroid-step']}>- <span className={classes['text-white']}>{questHours}</span> hours / day of questing</div>   
                <div className={classes['orbatroid-step']}>- FGL Token Value: <span className={classes['text-white']}>{fglValue}</span> WAX</div>   
                <div className={classes['orbatroid-step']}>- Common NFT Find Value: <span className={classes['text-white']}>{averageNFTValue}</span> WAX</div>   
                <div className={classes['orbatroid-step']}>- All Batteries give <span className={classes['text-white']}>1</span> luck</div>                
              </div>   

              <div className={classes['details-column']}>
                <div className={classes['calc-title']}>Account Inputs:</div>                
                <div className={classes['calc-group']}>
                  <div className={classes['calc-label']}>Mecha Level:</div>
                  <div className={classes['calc-value']}>                    
                    <select onChange={(e) => changeRoiLevel(e)}>
                    {levelArray.map( ( level ) => (
                      level === roiMechaLevel ? 
                      <option selected key={level} value={level}>{level}</option>              
                      :
                      <option key={level} value={level}>{level}</option>                    
                    ))}
                    </select>
                  </div>
                </div>
                <div className={classes['calc-group']}>
                  <div className={classes['calc-label']}>Number of Mechas Registered:</div>
                  <div className={classes['calc-value']}>                    
                    <select onChange={(e) => changePackSize(e)}>
                      {packSizeArray.map( ( size ) => (
                        size === packSize ? 
                        <option selected key={size} value={size}>{size}</option>              
                        :
                        <option key={size} value={size}>{size}</option>                    
                      ))}
                    </select>
                  </div>
                </div>
                <div className={classes['calc-group']}>
                  <div className={classes['calc-label']}>Quest Type:</div>
                  <div className={classes['calc-value']}>
                    <select onChange={(e) => changeRoiQuest(e)}>
                      {roiQuestType === 'short' ?
                        <option value="short" selected>Short</option>
                      :
                        <option value="short">Short</option>
                      }
                      {roiQuestType === 'medium' ?
                        <option value="medium" selected>Medium</option>
                      :
                        <option value="medium">Medium</option>
                      }
                      {roiQuestType === 'long' ?
                        <option value="long" selected>Long</option>
                      :
                        <option value="long">Long</option>
                      }
                    </select>
                  </div>
                </div>
              </div>       

              <div className={classes['details-column']}>
                <div className={classes['calc-title']}>Mecha Setup:</div>
                <div className={classes['calc-group']}>
                  <div className={classes['calc-label']}>Arm:</div>
                  <div className={classes['calc-value']}>
                    <select onChange={(e) => changePartRarity("arm", e)}>
                      {partRarityArray.map( ( part ) => (
                        part.value === nftArm ? 
                        <option selected key={part.value} value={part.value}>{part.label}</option>              
                        :
                        <option key={part.value} value={part.value}>{part.label}</option>                    
                      ))}
                    </select>
                  </div>
                </div>
                <div className={classes['calc-group']}>
                  <div className={classes['calc-label']}>Back:</div>
                  <div className={classes['calc-value']}>
                    <select onChange={(e) => changePartRarity("back", e)}>
                      {partRarityArray.map( ( part ) => (
                        part.value === nftBack ? 
                        <option selected key={part.value} value={part.value}>{part.label}</option>              
                        :
                        <option key={part.value} value={part.value}>{part.label}</option>                    
                      ))}
                    </select>
                  </div>
                </div>
                <div className={classes['calc-group']}>
                  <div className={classes['calc-label']}>Shoulder:</div>
                  <div className={classes['calc-value']}>
                    <select onChange={(e) => changePartRarity("shoulder", e)}>
                      {partRarityArray.map( ( part ) => (
                        part.value === nftShoulder ? 
                        <option selected key={part.value} value={part.value}>{part.label}</option>              
                        :
                        <option key={part.value} value={part.value}>{part.label}</option>                    
                      ))}
                    </select>
                  </div>
                </div>
                <div className={classes['calc-group']}>
                  <div className={classes['calc-label']}>Tail:</div>
                  <div className={classes['calc-value']}>
                    <select onChange={(e) => changePartRarity("tail", e)}>
                      {partRarityArray.map( ( part ) => (
                        part.value === nftTail ? 
                        <option selected key={part.value} value={part.value}>{part.label}</option>              
                        :
                        <option key={part.value} value={part.value}>{part.label}</option>                    
                      ))}
                    </select>
                  </div>
                </div>
                <div className={classes['calc-group']}>
                  <div className={classes['calc-label']}>Armor:</div>
                  <div className={classes['calc-value']}>
                    <select onChange={(e) => changePartRarity("armor", e)}>
                      {partRarityArray.map( ( part ) => (
                        part.value === nftArmor ? 
                        <option selected key={part.value} value={part.value}>{part.label}</option>              
                        :
                        <option key={part.value} value={part.value}>{part.label}</option>                    
                      ))}
                    </select>
                  </div>
                </div>
                <div className={classes['calc-group']}>
                  <div className={classes['calc-label']}>Battery:</div>
                  <div className={classes['calc-value']}>
                    <select onChange={(e) => changePartRarity("battery", e)}>
                      {partRarityArray.map( ( part ) => (
                        part.value === nftBattery ? 
                        <option selected key={part.value} value={part.value}>{part.label}</option>              
                        :
                        <option key={part.value} value={part.value}>{part.label}</option>                    
                      ))}
                    </select>
                  </div>
                </div>
                <div className={classes['calc-group']}>
                  <div className={classes['calc-label']}>Claws:</div>
                  <div className={classes['calc-value']}>
                    <select onChange={(e) => changePartRarity("claws", e)}>
                      {partRarityArray.map( ( part ) => (
                        part.value === nftClaws ? 
                        <option selected key={part.value} value={part.value}>{part.label}</option>              
                        :
                        <option key={part.value} value={part.value}>{part.label}</option>                    
                      ))}
                    </select>
                  </div>
                </div>
                <div className={classes['calc-group']}>
                  <div className={classes['calc-label']}>Eye:</div>
                  <div className={classes['calc-value']}>
                    <select onChange={(e) => changePartRarity("eye", e)}>
                      {partRarityArray.map( ( part ) => (
                        part.value === nftEye ? 
                        <option selected key={part.value} value={part.value}>{part.label}</option>              
                        :
                        <option key={part.value} value={part.value}>{part.label}</option>                    
                      ))}
                    </select>
                  </div>
                </div>
                <div className={classes['calc-group']}>
                  <div className={classes['calc-label']}>Hard Drive:</div>
                  <div className={classes['calc-value']}>
                    <select onChange={(e) => changePartRarity("harddrive", e)}>
                      {partRarityArray.map( ( part ) => (
                        part.value === nftHardDrive ? 
                        <option selected key={part.value} value={part.value}>{part.label}</option>              
                        :
                        <option key={part.value} value={part.value}>{part.label}</option>                    
                      ))}
                    </select>
                  </div>
                </div>
                <div className={classes['calc-group']}>
                  <div className={classes['calc-label']}>Processor:</div>
                  <div className={classes['calc-value']}>
                    <select onChange={(e) => changePartRarity("processor", e)}>
                      {partRarityArray.map( ( part ) => (
                        part.value === nftProcessor ? 
                        <option selected key={part.value} value={part.value}>{part.label}</option>              
                        :
                        <option key={part.value} value={part.value}>{part.label}</option>                    
                      ))}
                    </select>
                  </div>
                </div>
                <div className={classes['calc-group']}>
                  <div className={classes['calc-label']}>RAM:</div>
                  <div className={classes['calc-value']}>
                    <select onChange={(e) => changePartRarity("ram", e)}>
                      {partRarityArray.map( ( part ) => (
                        part.value === nftRAM ? 
                        <option selected key={part.value} value={part.value}>{part.label}</option>              
                        :
                        <option key={part.value} value={part.value}>{part.label}</option>                    
                      ))}
                    </select>
                  </div>
                </div>
                <div className={classes['calc-group']}>
                  <div className={classes['calc-label']}>Sensor:</div>
                  <div className={classes['calc-value']}>
                    <select onChange={(e) => changePartRarity("sensor", e)}>
                      {partRarityArray.map( ( part ) => (
                        part.value === nftSensor ? 
                        <option selected key={part.value} value={part.value}>{part.label}</option>              
                        :
                        <option key={part.value} value={part.value}>{part.label}</option>                    
                      ))}
                    </select>
                  </div>
                </div>
                <div className={classes['calc-group']}>
                  <div className={classes['calc-label']}>Teeth:</div>
                  <div className={classes['calc-value']}>
                    <select onChange={(e) => changePartRarity("teeth", e)}>
                      {partRarityArray.map( ( part ) => (
                        part.value === nftTeeth ? 
                        <option selected key={part.value} value={part.value}>{part.label}</option>              
                        :
                        <option key={part.value} value={part.value}>{part.label}</option>                    
                      ))}
                    </select>
                  </div>
                </div>
              </div>              
            </fieldset>
            
          </div>   

          <div className={classes['leaderboard-top-row']}> 
            <fieldset className={classes['details-block']}>
              <legend className={classes['details-title']}>Results</legend>

              <div className={classes['details-column']}>
                <div className={classes['calc-title']}>Questing:</div>
                <div className={classes['calc-group']}>
                  <div className={classes['calc-label']}>Max Energy Pool:</div>
                  <div className={classes['calc-value-green']}>{dailyEnergy.toFixed(2)}</div>                  
                </div>
                <div className={classes['calc-group']}>
                  <div className={classes['calc-label']}>Energy Regeneration:</div>
                  <div className={classes['calc-value-green']}>{energyRegen.toFixed(2)} / hour</div>                  
                </div>
                <div className={classes['calc-group']}>
                  <div className={classes['calc-label']}>Battery Efficiency:</div>
                  <div className={classes['calc-value-green']}>{(batteryEfficiency*100).toFixed(0)}%</div>                  
                </div>
                <div className={classes['calc-group']}>
                  <div className={classes['calc-label']}>Quests Per Day:</div>
                  <div className={classes['calc-value-green']}>{questsPerDay.toFixed(2)}</div>
                </div>                
              </div>   
                                                                 
              <div className={classes['details-column']}>
                <div className={classes['calc-title']}>Tokens:</div>
                <div className={classes['calc-group']}>
                  <div className={classes['calc-label']}>FGL / day:</div>
                  <div className={classes['calc-value-green']}>{roiFGLPerDay.toFixed(4)}</div>
                  <img src={fglToken} alt="FGL Token"  className={classes['calc-token']} />
                </div>
                <div className={classes['calc-group']}>
                  <div className={classes['calc-label']}>XP / day:</div>
                  <div className={classes['calc-value-green']}>{roiXPPerDay.toFixed(2)}</div>
                </div>    
                <div className={classes['calc-group']}>
                  <div className={classes['calc-label']}>Chance to find tokens per quest:</div>
                  <div className={classes['calc-value-green']}>{roiTokenFindPercentage.toFixed(0)}%</div>
                </div>             
              </div> 

              <div className={classes['details-column']}>
                <div className={classes['calc-title']}>NFT Finding:</div>
                <div className={classes['calc-group']}>
                  <div className={classes['calc-label']}>Level:</div>
                  <div className={classes['calc-value-green']}>{roiMechaLevel}</div>
                </div>
                <div className={classes['calc-group']}>
                  <div className={classes['calc-label']}>Luck:</div>
                  <div className={classes['calc-value-green']}>{nftLuck}</div>
                </div>
                <div className={classes['calc-group']}>
                  <div className={classes['calc-label']}>% to Find:</div>
                  <div className={classes['calc-value-green']}>{nftPercentFind.toFixed(4)}%</div>
                </div>
                <div className={classes['calc-group']}>
                  <div className={classes['calc-label']}># of Quests to Find:</div>
                  <div className={classes['calc-value-green']}>{nftQuestsToFind.toFixed(1)}</div>
                </div>
                <div className={classes['calc-group']}>
                  <div className={classes['calc-label']}>Days to Find:</div>
                  <div className={classes['calc-value-green']}>{nftDaysToFind.toFixed(2)}</div>
                </div>
                <div className={classes['calc-group']}>
                  <div className={classes['calc-label']}>Repair Cost Per Quest:</div>
                  <div className={classes['calc-value-green']}>{nftRepairPerQuest.toFixed(4)}</div>
                  <img src={fglToken} alt="FGL Token"  className={classes['calc-token']} />
                </div> 
                <div className={classes['calc-group']}>
                  <div className={classes['calc-label']}>Daily Repair Cost:</div>
                  <div className={classes['calc-value-green']}>{nftDailyRepairCost.toFixed(4)}</div>
                  <img src={fglToken} alt="FGL Token"  className={classes['calc-token']} />
                </div>               
                <div className={classes['calc-group']}>
                  <div className={classes['calc-label']}>Cost to Find:</div>
                  <div className={classes['calc-value-green']}>{nftRepairCostToFind.toFixed(4)}</div>
                  <img src={fglToken} alt="FGL Token"  className={classes['calc-token']} />
                  <div className={classes['calc-value-green']}>&nbsp;({nftRepairCostToFindInWAX.toFixed(4)}</div>
                  <img src={waxToken} alt="WAX Token"  className={classes['calc-token']} />
                  <div className={classes['calc-value-green']}>)</div>
                </div>
                <div className={classes['calc-group']}>
                  <div className={classes['calc-label']}>Common NFT Value:</div>
                  <div className={classes['calc-value-green']}>{averageNFTValue}</div>
                </div>                
              </div> 
                 
            </fieldset>
            
          </div>   
        </div>                 
      </div>
    </div>           
  );
};

export default MWCalculators;