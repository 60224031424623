export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const USER_RESET_ERROR = "USER_RESET_ERROR";

export const VIEW_SHIP_DETAILS = "VIEW_SHIP_DETAILS";
export const CLEAR_SHIP_DETAILS = "CLEAR_SHIP_DETAILS";
export const SET_GENERAL_ERROR = "SET_GENERAL_ERROR";
export const SET_GENERAL_BUSY_MESSAGE = "SET_GENERAL_BUSY_MESSAGE";
export const SET_BUSY_AUTO_LOGIN = "SET_BUSY_AUTO_LOGIN";
export const SET_SIGNING_TRANSACTION = "SET_SIGNING_TRANSACTION";
export const SET_WAX_ERROR = "SET_WAX_ERROR";

export const UPDATE_MOBILE_MODE = "UPDATE_MOBILE_MODE";

export const CLEAR_BATTLE = "CLEAR_BATTLE";
export const SHOW_BATTLE_LOG = "SHOW_BATTLE_LOG";
export const HIDE_BATTLE_LOG = "HIDE_BATTLE_LOG";
export const SHOW_BATTLE_RESULTS = "SHOW_BATTLE_RESULTS";
export const HIDE_BATTLE_RESULTS = "HIDE_BATTLE_RESULTS";

export const SHOW_BATTLE_CONFIRMATION = "SHOW_BATTLE_CONFIRMATION";
export const HIDE_BATTLE_CONFIRMATION = "HIDE_BATTLE_CONFIRMATION";

export const CLEAR_GAME_ERROR = "";
export const CLEAR_PLAYER_ERROR = "";
export const CLEAR_WAX_ERROR = "";

export const WATCH_BATTLE = "WATCH_BATTLE";

export const CLEAR_ANNOUNCEMENT = "CLEAR_ANNOUNCEMENT";

export const GET_BATTLE_STREAM_REQUEST = "GET_BATTLE_STREAM_REQUEST";
export const GET_BATTLE_STREAM_SUCCESS = "GET_BATTLE_STREAM_SUCCESS";
export const GET_BATTLE_STREAM_FAILURE = "GET_BATTLE_STREAM_FAILURE";

export const FIND_BATTLES = "FIND_BATTLES";
export const FIND_BATTLES_SUCCESS = "FIND_BATTLES_SUCCESS";
export const FIND_BATTLES_FAILURE = "FIND_BATTLES_FAILURE";