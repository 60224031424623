import axios from "../../axios-cc";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";

import { FIND_BATTLES, GET_BATTLE_STREAM_REQUEST } from "../actions/actionTypes";
import { findBattlesFailure, findBattlesSuccess, getBattleStreamFailure, getBattleStreamSuccess, setGeneralBusyMessage } from "../actions/gameActions";
import { FindBattlesRequest, GetBattleStreamRequest, IBattleStreamPayload } from "../types/gameTypes";

const getBattleStream = (lastBattleId:number) =>
  axios.post<IBattleStreamPayload>("/api/get-battle-stream", { lastBattleId: lastBattleId });
const findBattles = (battleId:string, playerName:string) =>
  axios.post<IBattleStreamPayload>("/api/find-battles", { battleId: battleId, playerName: playerName });

/*
  Worker Saga: Fired on GET_BATTLE_STREAM_REQUEST action   
*/
function* getBattleStreamSaga( action: GetBattleStreamRequest ) {
  try {
    const response: AxiosResponse<IBattleStreamPayload> = yield call(getBattleStream, action.lastBattleId);

    // Clear the general message
    yield put (setGeneralBusyMessage(""));

    if (response.data.error || response.data.fatalError) {
      yield put(
        getBattleStreamFailure({
          error: response.data.error,
          fatalError: response.data.fatalError,
        })
      );
    } else {
      yield put(
        getBattleStreamSuccess({
          battles: response.data.battles,
        })
      );
     
    }
  } catch (e:any) {
    yield put(
      getBattleStreamFailure({
        error: "",
        fatalError: e.message
      })
    );
  }
}

/*
  Worker Saga: Fired on FIND_BATTLES action   
*/
function* findBattlesSaga( action: FindBattlesRequest ) {
  try {
    const response: AxiosResponse<IBattleStreamPayload> = yield call(findBattles, action.battleId, action.playerName);

    // Clear the general message
    yield put (setGeneralBusyMessage(""));

    if (response.data.error || response.data.fatalError) {
      yield put(
        findBattlesFailure({
          error: response.data.error,
          fatalError: response.data.fatalError,
        })
      );
    } else {
      yield put(
        findBattlesSuccess({
          battles: response.data.battles,
        })
      );
     
    }
  } catch (e:any) {
    yield put(
      findBattlesFailure({
        error: "",
        fatalError: e.message
      })
    );
  }
}

/*
  Starts worker saga on latest dispatched `FETCH_TODO_REQUEST` action.
  Allows concurrent increments.
*/
function* viewerSaga() {
  yield all([takeLatest(GET_BATTLE_STREAM_REQUEST, getBattleStreamSaga)]);  
  yield all([takeLatest(FIND_BATTLES, findBattlesSaga)]);  
}

export default viewerSaga;