import { useDispatch, useSelector } from "react-redux";
import classes from './FindBattle.module.css';

import { findBattles, setGeneralBusyMessage, watchBattle } from "../../store/actions/gameActions";
import { getGameStateSelector } from "../../store/selectors/gameSelectors";
import { GameState } from "../../store/types/gameTypes";
import LeftNav from "../General/LeftNav/LeftNav";

import { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import FindBattleRow from "./FindBattleRow/FindBattleRow";
import BattleRow from "../General/BattleRow/BattleRow";

const FindBattle = (props:any) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [battleId, setBattleId] = useState("");  
  const [playerName, setPlayerName] = useState("");  
  
  const gameState:GameState = useSelector(getGameStateSelector);
  
  let clicksEnabled = true;

  // If we come to this page with an ID in the URL, grab it
  useEffect(() => {       
    if (props.match.params.id) {
      dispatch(findBattles(props.match.params.id,""));  
      setBattleId(props.match.params.id);
    }

  }, [props.match.params.id]);

  const clickWatchBattle = (battleIndex:number) => {    
    if (clicksEnabled) {
      clicksEnabled = false;
      
      dispatch(watchBattle(gameState.foundBattles[battleIndex]));
      history.push('/show-battle');
    }
  }

  const changeId = (e:any) => {    
    if (clicksEnabled) {
      clicksEnabled = false;
            
      setBattleId(e.target.value);
    }
  }

  const changePlayerName = (e:any) => {    
    if (clicksEnabled) {
      clicksEnabled = false;
            
      setPlayerName(e.target.value);
    }
  }

  const clickFindBattle = () => {    
    if (clicksEnabled && (battleId !== "" || playerName !== "")) {
      clicksEnabled = false;

      // Set the general message
      dispatch(setGeneralBusyMessage("Retrieving Data..."));
            
      // Get the battles
      dispatch(findBattles(battleId, playerName));  
    }
  }
  
  return (    
    <div className={classes['main-content']}>
      <LeftNav />
      <div className={classes['center-content']}>                        
        <div className={classes['mission-holder']}>
          <div className={classes['battle-top']}>Battle Finder</div>
          <div className={classes['leaderboard-top-row']}>                                                         
            <div className={classes['details-column']}>
              <div className={classes['detail-title']}>Battle ID:</div>
              <div className={classes['detail-value']}>
                <input type="text" name="name" value={battleId} onChange={changeId} />     
              </div>
            </div>  
            <div className={classes['details-column']}>
              <div className={classes['detail-title']}>Player:</div>
              <div className={classes['detail-value']}>
                <input type="text" name="name" value={playerName} onChange={changePlayerName} />     
              </div>
            </div>         
            <div className={classes['details-column']}>
              <div onClick={() => clickFindBattle()}  className={classes['stream-button']}>                  
                  <div className={classes['stream-text']}>GO</div>
              </div>
            </div>              
          </div>

          <div className={classes["stream-display"]}> 
            {gameState.foundBattles.length === 1 ?              
              <FindBattleRow clickWatchBattle={clickWatchBattle}
                battleResult={gameState.foundBattles[0]} rowIndex={0} />  
              
            :                   
              gameState.foundBattles.map( ( battle, index ) => {                                            
                return (<BattleRow key={index} clickWatchBattle={clickWatchBattle}
                  battleResult={battle} rowIndex={index} />
                );
              })}     
          </div> 
        </div>                 
      </div>
    </div>           
  );
};

export default FindBattle;