import { useDispatch, useSelector } from "react-redux";
import classes from './MobileBattleHistory.module.css';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import {ReactComponent as CheckboxCheckedIcon} from '../../../assets/svg/checkbox-checked.svg';
import {ReactComponent as CheckboxUnCheckedIcon} from '../../../assets/svg/checkbox-unchecked.svg';

import { useEffect, useState } from "react";

import BattleRow from "./MobileBattleRow/MobileBattleRow";
import MobileTopNav from "../MobileTopNav/MobileTopNav";
import MobileSideDrawer from "../MobileSideDrawer/MobileSideDrawer";
import { useHistory } from "react-router-dom";
import { getBattleStream, watchBattle } from "../../../store/actions/gameActions";
import { GameState, IBattleResult } from "../../../store/types/gameTypes";
import { getGameStateSelector } from "../../../store/selectors/gameSelectors";

const MobileBattleHistory = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const [modes, setGameModes] = useState<number[] | []>([1,2,3,4]);
  const [results, setResults] = useState<string[] | []>(['win','loss']);
  const [types, setTypes] = useState<string[] | []>(['regular','rematch']);
  const [battles, setBattles] = useState<IBattleResult[] | []>([]);
  const [battlesFiltered, setBattlesFiltered] = useState<IBattleResult[] | []>([]);
  const [lastBattle, setLastBattle] = useState(0);
  const [showFilters, setShowFilters] = useState(false);

  const [streaming, setStreaming] = useState<boolean>(true);
  const [getFirstLoad, setGetFirstLoad] = useState<boolean>(true);

  const [sideDrawerIsVisible, setSideDrawerIsVisible] = useState(false);
  
  const gameState:GameState = useSelector(getGameStateSelector);
  
  let clicksEnabled = true;

  // Start streaming
  useEffect(() => {
    let timer:any = setTimeout(streamBattles, 5000);
          
    // Clear timeout if the component is unmounted
    return () => {      
      if (timer)
        clearTimeout(timer);
    }
  });

  // Load one battle stream right away
  useEffect(() => {
    streamBattles();
  }, []);

  // Update the battle list whenever we get new results from the server
  useEffect(() => {       
    // Add the new entries to the full list
    let localBattles:IBattleResult[] = battles.slice();        
    localBattles = localBattles.concat(gameState.battleStream);

    setBattles(localBattles);

    // Update the latest battle ID to grab next time
    if (localBattles.length > 0) {
      setLastBattle(localBattles[localBattles.length-1].battleId)
    }

  }, [gameState.battleStream]);

  // Update the filtered battle list whenever the filters change
  useEffect(() => {   
    // Now let's figure out the filtered ones
    let localBattlesFiltered:IBattleResult[] = [];  
    let bOK:boolean;
    
    for (let i:number = 0; i < battles.length; i++) {
      bOK = true;

      if ((battles[i].battleType === 1 && !modes.find((element:any) => element === 1)) ||
        (battles[i].battleType === 0 && !modes.find((element:any) => element === 2)))
      {
        bOK = false;
      }

      if ((battles[i].battleType === 3 && !modes.find((element:any) => element === 4)) ||
        (battles[i].battleType === 2 && !modes.find((element:any) => element === 3)))
      {
        bOK = false;
      }
      
      if ((battles[i].battleWon && !results.find((element:any) => element === "win")) ||
        (!battles[i].battleWon && !results.find((element:any) => element === "loss")))
      {
        bOK = false;
      }

      if ((!battles[i].bRematch && !types.find((element:any) => element === "regular")) ||
        (battles[i].bRematch && !types.find((element:any) => element === "rematch")))
      {
        bOK = false;
      }


      if (bOK) {
        localBattlesFiltered.push(battles[i]);
      }
    }
    
    setBattlesFiltered(localBattlesFiltered);
   

   
  }, [battles, modes, results, types]);

  const streamBattles = () => {    
    if (streaming && (getFirstLoad || lastBattle > 0)) {      
      dispatch(getBattleStream(lastBattle));   
      setGetFirstLoad(false);
    } 
  };

  const toggleMode = (mode:number) => {
    if (clicksEnabled) {
      clicksEnabled = false;
    
      // Does this mode exist in the galaxies?
      let localModes:number[] = modes.slice();    
      let foundIndex:number = -1;
      for (let i:number = 0; i < modes.length; i++) {
        if (modes[i] === mode) {
          foundIndex = i;
        }
      }

      if (foundIndex > -1) {
        // Remove it
        localModes.splice(foundIndex, 1);
      } else {
        // Add it
        localModes.push(mode);
      }

      // Update the filter    
      setGameModes(localModes);      
    }
  }

  const clickWatchBattle = (battleIndex:number) => {    
    if (clicksEnabled) {
      clicksEnabled = false;
      
      dispatch(watchBattle(battles[battleIndex]));
      history.push('/show-battle');
    }
  }

  const toggletStreaming = (stream:boolean) => {
    if (clicksEnabled) {
      clicksEnabled = false;
      setStreaming(stream);
    }
  }

  const toggleResult = (result:string) => {
    if (clicksEnabled) {
      clicksEnabled = false;
    
      // Does this mode exist in the results?
      let localResults:string[] = results.slice();    
      let foundIndex:number = -1;
      for (let i:number = 0; i < results.length; i++) {
        if (results[i] === result) {
          foundIndex = i;
        }
      }

      if (foundIndex > -1) {
        // Remove it
        localResults.splice(foundIndex, 1);
      } else {
        // Add it
        localResults.push(result);
      }

      // Update the filter    
      setResults(localResults);  
    }
  }

  const toggleType = (type:string) => {
    if (clicksEnabled) {
      clicksEnabled = false;
    
      // Does this mode exist in the results?
      let localTypes:string[] = types.slice();    
      let foundIndex:number = -1;
      for (let i:number = 0; i < types.length; i++) {
        if (types[i] === type) {
          foundIndex = i;
        }
      }

      if (foundIndex > -1) {
        // Remove it
        localTypes.splice(foundIndex, 1);
      } else {
        // Add it
        localTypes.push(type);
      }

      // Update the filter    
      setTypes(localTypes);  
    }
  }

  const sideDrawerClosedHandler = () => {
    setSideDrawerIsVisible(false);
  };

  const sideDrawerToggleHandler = () => {
    setSideDrawerIsVisible(!sideDrawerIsVisible);
  };

  const toggleShowFilters = () => {
    if (showFilters) {
      setShowFilters(false);
    } else {
      setShowFilters(true);     
    }
  }

  return (    
    <div className={classes['main-content']}>
      <div className={classes['top-nav-content']}>
        <MobileTopNav drawerToggleClicked={sideDrawerToggleHandler} />  
        <MobileSideDrawer
          isOpen={sideDrawerIsVisible}
          closeFunction={sideDrawerClosedHandler}
        />
      </div>
      <div className={classes['center-content']}>                        
        <div className={classes['mission-holder']}>
          <div className={classes['battle-top']}>Battle History</div>
          <div onClick={() => toggleShowFilters()} className={classes['checkbox-holder-main']}>
            {showFilters ?
              <CheckboxCheckedIcon className={classes['checkbox-icon']} />
            :
              <CheckboxUnCheckedIcon className={classes['checkbox-icon']} />
            }
            <div className={classes['checkbox-message']}>Show Filters</div>              
          </div>
          {showFilters ?
          <div className={classes['leaderboard-top-row']}> 
            <fieldset className={classes['details-block']}>
              <legend className={classes['details-title']}>Filter</legend>
                                                
              <div className={classes['details-column']}>
                <div className={classes['detail-title']}>Game Mode:</div>
                <div className={classes['detail-value']}>
                  <div onClick={() => toggleMode(1)} className={classes['checkbox-holder']}>
                    {modes.find(element => element === 1) ?
                      <CheckboxCheckedIcon className={classes['checkbox-icon']} />
                    :
                      <CheckboxUnCheckedIcon className={classes['checkbox-icon']} />
                    }
                    <div className={classes['checkbox-message']}>Weekly Battles</div>              
                  </div>
                  <div onClick={() => toggleMode(2)} className={classes['checkbox-holder']}>
                    {modes.find(element => element === 2) ?
                      <CheckboxCheckedIcon className={classes['checkbox-icon']} />
                    :
                      <CheckboxUnCheckedIcon className={classes['checkbox-icon']} />
                    }
                    <div className={classes['checkbox-message']}>Seasonal Battles</div>              
                  </div>    
                  <div onClick={() => toggleMode(3)} className={classes['checkbox-holder']}>
                    {modes.find(element => element === 3) ?
                      <CheckboxCheckedIcon className={classes['checkbox-icon']} />
                    :
                      <CheckboxUnCheckedIcon className={classes['checkbox-icon']} />
                    }
                    <div className={classes['checkbox-message-gold']}>GOLD Battles</div>              
                  </div>    
                  <div onClick={() => toggleMode(4)} className={classes['checkbox-holder']}>
                    {modes.find(element => element === 4) ?
                      <CheckboxCheckedIcon className={classes['checkbox-icon']} />
                    :
                      <CheckboxUnCheckedIcon className={classes['checkbox-icon']} />
                    }
                    <div className={classes['checkbox-message-bronze']}>BRONZE Battles</div>              
                  </div>                 
                </div>
              </div> 

              <div className={classes['details-column']}>
                <div className={classes['detail-title']}>Results:</div>
                <div className={classes['detail-value']}>
                  <div onClick={() => toggleResult('win')} className={classes['checkbox-holder']}>
                    {results.find(element => element === 'win') ?
                      <CheckboxCheckedIcon className={classes['checkbox-icon']} />
                    :
                      <CheckboxUnCheckedIcon className={classes['checkbox-icon']} />
                    }
                    <div className={classes['checkbox-message']}>Wins</div>              
                  </div>
                  <div onClick={() => toggleResult('loss')} className={classes['checkbox-holder']}>
                    {results.find(element => element === 'loss') ?
                      <CheckboxCheckedIcon className={classes['checkbox-icon']} />
                    :
                      <CheckboxUnCheckedIcon className={classes['checkbox-icon']} />
                    }
                    <div className={classes['checkbox-message']}>Losses</div>              
                  </div>                 
                </div>
              </div> 

              <div className={classes['details-column']}>
                <div className={classes['detail-title']}>Type:</div>
                <div className={classes['detail-value']}>
                  <div onClick={() => toggleType('regular')} className={classes['checkbox-holder']}>
                    {types.find(element => element === 'regular') ?
                      <CheckboxCheckedIcon className={classes['checkbox-icon']} />
                    :
                      <CheckboxUnCheckedIcon className={classes['checkbox-icon']} />
                    }
                    <div className={classes['checkbox-message']}>Regular</div>              
                  </div>
                  <div onClick={() => toggleType('rematch')} className={classes['checkbox-holder']}>
                    {types.find(element => element === 'rematch') ?
                      <CheckboxCheckedIcon className={classes['checkbox-icon']} />
                    :
                      <CheckboxUnCheckedIcon className={classes['checkbox-icon']} />
                    }
                    <div className={classes['checkbox-message']}>Rematch</div>              
                  </div>                 
                </div>
              </div>                             
            </fieldset>
          </div>    
          : null }  
           <TransitionGroup className={classes["stream-display"]}>                    
            {battlesFiltered.map( ( battle, index ) => (              
              <CSSTransition key={battle.battleId} timeout={2000} classNames="item">
                <BattleRow clickWatchBattle={clickWatchBattle}
                    battleResult={battle} rowIndex={index} />  
              </CSSTransition>
            ))}     
          </TransitionGroup>   
        </div>                 
      </div>
    </div>           
  );
};

export default MobileBattleHistory;