import classes from './FindBattleRow.module.css';

import BattleShipDisplay from '../../../components/General/BattleRow/BattleShipDisplay/BattleShipDisplay';
import {ReactComponent as WatchIcon} from '../../../assets/svg/eye.svg';
import fglToken from '../../../assets/images/general/fgl-token.png';
import projectileIcon from '../../../assets/images/general/icons/weapon-projectile.png';
import armorIcon from '../../../assets/images/general/icons/armor.png';
import shieldsIcon from '../../../assets/images/general/icons/shields.png';

import goldIcon from '../../../assets/images/game/512x512-gold-animated.gif';
import bronzeIcon from '../../../assets/images/game/512x512 bronze.png';

import { IBattleResult } from '../../../store/types/gameTypes';


interface BattleRowProps {
  battleResult:IBattleResult;   
  rowIndex:number;
  clickWatchBattle:Function;  
}

const FindBattleRow = ( { battleResult, rowIndex, clickWatchBattle }:BattleRowProps ) => {         
    let localBattleDate:Date = new Date(battleResult.battleDate);

    
    return (        
        <div className={classes['battle-row']}>
            <div className={classes['battle-left']}>
                <div className={classes['battle-left-row']}>
                    {battleResult.battleType === 0 ?
                        <div className={classes['battle-type']}>W</div>
                    : battleResult.battleType === 1 ?
                        <div className={classes['battle-type']}>S</div>
                    : battleResult.battleType === 2 ?
                        <img alt="Gold" src={goldIcon} className={classes['token-image']} />                  
                    : battleResult.battleType === 3 ?
                        <img alt="Bronze" src={bronzeIcon} className={classes['token-image']} />  
                    : null }
                                                        
                    {battleResult.bPaid ?
                        <img alt="FGL Tokens" src={fglToken} className={classes['token-image']} />  
                        :
                        <img alt="FGL Tokens" src={fglToken} className={classes['token-image-bw']} />  
                    }   

                    {battleResult.powerBuff === 3 ?
                        <img alt="Damage" src={projectileIcon} className={classes['token-image']} />  
                    : battleResult.powerBuff === 2 ?
                        <img alt="Shields" src={shieldsIcon} className={classes['token-image']} />  
                    : battleResult.powerBuff === 1 ?
                        <img alt="Armor" src={armorIcon} className={classes['token-image']} />  
                    : <div className={classes['placeholder']}>&nbsp;</div>
                    }   
                </div>
                <div className={classes['battle-left-row']}>
                    <div className={classes['battle-date']}>{localBattleDate.toLocaleDateString('en-US')}&nbsp;{localBattleDate.toLocaleTimeString()}</div>            
                </div>
                <div onClick={() => clickWatchBattle(rowIndex)} className={classes['watch-button']}>
                    <WatchIcon className={classes['watch-icon']} /> &nbsp; Watch Battle
                </div> 
            </div>
            
            <div className={classes['detailed-view']}>
                <div className={classes['player-detail-group']}>
                    <div className={classes['player-details']}>
                        {battleResult.attackerAvatar > 0 ?
                            <img src={'https://cdn.cosmicclash.io/avatars/avatar_' + battleResult.attackerAvatar + '.png'} className={classes['avatar-image']} />
                        : null }    
                        <div className={classes['battle-name']}>{battleResult.playerName}</div>                                        
                        {battleResult.bRematch ?
                            <div className={classes['battle-rematch-top']}>Rematch</div>
                        : null
                        }
                    </div>
                    {battleResult.battleWon ?
                        <div className={classes['battle-win']}>WIN</div>                    
                    : null
                    } 
                </div>
                <div className={classes['battle-fleets']}>
                    <div className={classes['battle-fleet']}>
                        {battleResult.playerFleet.map( ( ship, index ) => (
                            <BattleShipDisplay key={index} rowIndex={rowIndex} shipIndex={index} 
                                ship={ship} />
                        ))}                      
                    </div>
                    <div className={classes['battle-fleet']}>
                        {battleResult.enemyFleet.map( ( ship, index ) => (
                            <BattleShipDisplay key={index} rowIndex={rowIndex} shipIndex={index} 
                                ship={ship} />
                        ))}                      
                    </div>
                </div>
                <div className={classes['player-detail-group']}>
                    {!battleResult.battleWon ?
                        <div className={classes['battle-win']}>WIN</div>                    
                    : null
                    }
                    <div className={classes['enemy-details']}>                    
                        {battleResult.defenderAvatar > 0 ?
                            <img src={'https://cdn.cosmicclash.io/avatars/avatar_' + battleResult.defenderAvatar + '.png'} className={classes['token-image']} />
                        : null }    
                        <div className={classes['battle-name']}> {battleResult.enemyName}</div>                    
                    </div>
                </div>
            </div>                                        
        </div>        
    );
};

export default FindBattleRow;
