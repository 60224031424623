import classes from './BattleResults.module.css';
import {ReactComponent as CrossIcon} from '../../assets/svg/cross.svg';
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { hideBattleResults, showBattleLog } from '../../store/actions/gameActions';
import { getGameStateSelector } from '../../store/selectors/gameSelectors';

import discordIcon from '../../assets/images/general/discord_white.png';
import twitterIcon from '../../assets/images/general/twitter_white.png';
import telegramIcon from '../../assets/images/general/telegram_white.png';

const BattleResults = () => {    
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const gameState = useSelector(getGameStateSelector);

  // Close this popup
  const showBattleLogClick = () => {
    dispatch(showBattleLog());
  }

  const hideResultsClick = () => {
    dispatch(hideBattleResults());

    // If we are on the show-battle page, go to the home page 
    if (location.pathname === '/show-battle' ) {
      history.push("/");
    } 
  }

  const socialClick = (socialType) => {
   switch (socialType) {
     case "discord":
       window.open("https://discord.gg/XPcBsVHY8W");
       break;
      case "twitter":
        window.open("https://twitter.com/FGL_NFT");
        break;
      case "telegram":
       window.open("https://t.me/fgl_nft");
       break;
   }
  }

  const clickSponsor = () => {
    console.log("result", gameState.battleResult);


    if (gameState.battleResult.battleType === 2) {
      window.open(gameState.battleResult.sponsorLinkGold);
    } else {
      window.open(gameState.battleResult.sponsorLinkBronze);
    }    
  }

  let output = null;
  
  if (gameState.battleResult) {
    output = 
    <div className={classes['main-content']}>           
      <div className={classes['popup-content-image']}>         
        <div className={classes['cross-holder']}>
          <Link onClick={() => hideResultsClick()} className={classes['link']} to="/" >
            <CrossIcon className={classes['cross-icon']} />
          </Link>
        </div>                       
        <div className={classes['popup-title']}>Thanks for watching!</div>                       
       
        <div className={classes['sponsor-text']}>This battle was sponsored by:</div>  
        {gameState.battleResult.battleType === 2 ?
          <img src={gameState.battleResult.sponsorImageGold} 
            onClick={() => clickSponsor()}
            alt="Sponsor" className={classes['sponsor-image']} />  
        :
          <img src={gameState.battleResult.sponsorImageBronze} 
              onClick={() => clickSponsor()}
              alt="Sponsor" className={classes['sponsor-image']} />  
        }
        
        <div className={classes['cc-section']}>
          <div className={classes['cc-learn-more']}>Join the Cosmic Clash Movement:</div>
          <div className={classes['cc-socials']}>
            <div className={classes['cc-social']} onClick={() => socialClick('discord')}>
              <img alt="FGL Discord"  className={classes['cc-social-image']} src={discordIcon} />
            </div>
            <div className={classes['cc-social']} onClick={() => socialClick('twitter')}>
              <img alt="FGL Twitter"  className={classes['cc-social-image']} src={twitterIcon} />
            </div>
            <div className={classes['cc-social']} onClick={() => socialClick('telegram')}>
              <img alt="FGL Telegram"  className={classes['cc-social-image']} src={telegramIcon} />
            </div>
          </div>
        </div>
       
        <div className={classes['button-section']}>                    
          <div onClick={() => showBattleLogClick()} className={classes['return-button-log']}>Show Battle Log</div>
          <div onClick={() => hideResultsClick()} className={classes['return-button']}>Return Home</div>          
        </div>
      </div>
    </div>;
  }
  
  return output;
};

export default BattleResults;