import Phaser from "phaser";

class Ship extends Phaser.Physics.Arcade.Sprite {    
  constructor(config, shipWidth, shipSpacer, ship, baseScale, isEnemy, targetY, shieldSprite, armorSprite, levelSprite, shieldText, armorText, shieldDisplay, timeScale, game, upgradeSprite, pairedSprite) {      
    super(config.scene, config.x, config.y, config.key, config.frame);            
    this.shipWidth = shipWidth;
    this.shipSpacer = shipSpacer;
    this.game = game;
    this.ship = ship;  
    this.isEnemy = isEnemy;  
    this.shieldSprite = shieldSprite;
    this.armorSprite = armorSprite;
    this.levelSprite = levelSprite;
    this.shieldText = shieldText;
    this.armorText = armorText;
    this.shieldDisplay = shieldDisplay;
    this.rotateCompleteFunction = null;
    this.turnCompleteFunction = null;
    this.fireCompleteFunction = null;
    this.destroyCompleteFunction = null;
    this.moveCompleteFunction = null;
    this.baseScale = baseScale;
    this.timeScale = timeScale;    
    this.showSwarm = false;
    this.isSwarm = false;
    this.isDebuffed = false;
    this.isFrozen = false;
    this.baseAnimationName = 'engines' + config.key;
    this.pairedSprite = pairedSprite;
    this.pairedPositionRight = false;
   
    this.showUpgrade = false;
    this.isUpgraded = false;
    this.upgradeSprite = upgradeSprite;
    this.upgradeSprite.alpha = 0;
    this.upgradeSprite.on('animationcomplete', () => this.upgradeAnimationComplete());

    this.floatingArmorText = null;
    this.floatingShieldText = null;
    this.floatingTextOffset = 40;
       
    config.scene.add.existing(this);
    
    if (this.ship.spritesheet_frames > 1) {      
      let frameNames = this.anims.generateFrameNames(config.key, {
        start: 1, end: this.ship.spritesheet_frames, zeroPad: 0,
        prefix: '', suffix: '.png'
      });      
      this.anims.create({ key: 'engines' + config.key, frames: frameNames, frameRate: 10 / this.timeScale, repeat: -1 });
      this.anims.play('engines' + config.key);

      // Scale it down
      this.scale = (this.baseScale * this.ship.image_scale) * 2;             

    } else {
      // Scale it down
      this.scale = this.baseScale * this.ship.image_scale;       
    }

    if (this.ship.upgraded_spritesheet_frames > 1) {  
      let frameNames;
      if (isEnemy) {    
        frameNames = this.anims.generateFrameNames('enemyShipUpgraded' + this.ship.id, {
          start: 1, end: this.ship.upgraded_spritesheet_frames, zeroPad: 0,
          prefix: '', suffix: '.png'
        });      

        this.anims.create({ key: 'enginesUpgradedEnemy' + this.ship.id, frames: frameNames, frameRate: 10 / this.timeScale, repeat: -1 });
        
      } else {
        frameNames = this.anims.generateFrameNames('playerShipUpgraded' + this.ship.id, {
          start: 1, end: this.ship.upgraded_spritesheet_frames, zeroPad: 0,
          prefix: '', suffix: '.png'
        }); 

        this.anims.create({ key: 'enginesUpgradedPlayer' + this.ship.id, frames: frameNames, frameRate: 10 / this.timeScale, repeat: -1 });
      }
      

      // Scale it down
      this.scale = (this.baseScale * this.ship.image_scale) * 2;             

    }


    // IF this is an enemy ship, flip it
    if (isEnemy) {
      this.setFlipY(true);       
      this.speedY = (this.ship.speed / 20) / this.timeScale;
      this.shieldDisplay.setFlipY(true);      
    } else {
      this.speedY = -1 * ((this.ship.speed / 20) / this.timeScale);
    }
        
    // Init some variables
    this.targetY = targetY;
    this.shipSpeed = 10 / this.timeScale;  
    
    // Init bars to full
    this.shieldSprite.setFrame("shields_" + this.ship.rarity + "_10.png");
    this.armorSprite.setFrame("armor_" + this.ship.rarity + "_10.png");      

    // Set stats sprites to 0 if needed
    if (this.ship.originalShields === 0) {
      this.shieldSprite.setFrame("shields_" + this.ship.rarity + "_01.png");
    } 
    if (this.ship.originalArmor === 0) {
      this.armorSprite.setFrame("armor_" + this.ship.rarity + "_01.png");      
    } 

    // Init the texts
    this.shieldText.text = this.ship.originalShields.toString().padStart(3,'0');
    this.armorText.text = this.ship.originalArmor.toString().padStart(3,'0');    

    // Init the "previous" settings
    this.previousShields = this.ship.originalShields;
    this.previousArmor = this.ship.originalArmor;

    // Hide the shields
    this.showingShields = true;
    this.shieldDisplay.alpha = 0;
    this.shieldDisplay.on('animationcomplete', () => this.shieldAnimationComplete());

    // Some shields scale up a bit bigger than others
    if (this.ship.galaxy === 2 || this.ship.galaxy === 6) {
      this.shieldDisplay.scale = this.scale * 1.5;
    } else {
      this.shieldDisplay.scale = this.scale * 1.2;
    }

    // Scale the upgrade animation
    this.upgradeSprite.scale = this.scale * 1.2;

    // Scale the shield pairing
    this.pairedSprite.scaleX = this.baseScale * 0.1;
    this.pairedSprite.scaleY = this.baseScale * 6;    
    this.pairedSprite.alpha = 0;
        
    // Special swarming code
    this.on('animationcomplete', () => this.swarmComplete()); 
  }

  addMarked(markedSprite) {
    this.markedSprite = markedSprite;
    this.markedSprite.alpha = 0;  
    
    // Scale the marked animation
    this.markedSprite.scale = this.scale * 0.5
  }

  addFloatingTexts(floatingShieldText, floatingArmorText) {
    this.floatingArmorText = floatingArmorText;
    this.floatingArmorText.alpha = 0;

    this.floatingShieldText = floatingShieldText;
    this.floatingShieldText.alpha = 0;
  }
  
  addSpecial(specialSprite) {
    this.specialSprite = specialSprite;

    // IF this is an enemy ship, flip it
    if (this.isEnemy) {
      this.specialSprite.setFlipY(true);     
    } else {      
      this.specialSprite.setFlipY(false);      
    }

    // Scale the special if we have one        
    this.specialSprite.alpha = 0;
    this.specialSprite.scale = this.scale * 1.2;      
    this.specialSprite.on('animationcomplete', () => this.specialAnimationComplete());          
  }

  rotateToShip(currentShip, targetSprite, targetShip, rotateCompleteFunction, turnCompleteFunction) {        
    this.rotateCompleteFunction = rotateCompleteFunction;   
    this.turnCompleteFunction = turnCompleteFunction;       
        
    // Some special abilities target friendly ships, we will not rotate towards them, but will "fire" at them
    switch (this.ship.special_name) {
      case "repair":          
        // Skip to firing
        this.rotateComplete();

        // If it idled, flash it yellow
        if (currentShip.shipIdled) {
          this.flashYellow();
        }
        break;
      
      default:
        // If this is a shield battery targeting a friendly, skip to firing
        if (this.ship.special_name === 'shield_battery' && this.ship.isPlayerShip === targetShip.isPlayerShip) {
          this.rotateComplete();
        
        } else if (targetShip.wasResurrected) {
          // Special case when a ship was resurrected, we want to show it but not fire or anything
          this.rotateComplete();

        } else {
          // If this ship has an attack value + isn't disabled, rotate it to the target
          //  - EXCEPT if it has an emp coil special heh
          if ((currentShip.currentWeaponPower > 0 && !currentShip.isDisabled && currentShip.onCooldown === false && currentShip.tractorBeamVulnerable === 0) ||
            currentShip.special_name === 'emp_pulse') {
            let angle;
          
            if (this.isEnemy) {
              angle = Phaser.Math.Angle.BetweenY(this.x,this.y,targetSprite.x,targetSprite.y);          
              angle *= -1;
            } else {
              angle = Phaser.Math.Angle.BetweenY(targetSprite.x,targetSprite.y,this.x,this.y);                
              angle *= -1;
            }
            
            // Tween it
            this.scene.tweens.add(
              {
                targets: this,
                rotation: angle,				
                ease: Phaser.Math.Easing.Quadratic.In,
                duration: 2000 * this.timeScale, 
                delay: 0,
                onComplete: () => this.rotateComplete()  
              }
            );   
          } else {
            // Flash it yellow just to show it moved
            this.flashYellow();

            // Skip to the next ship, we don't have a friendly fire special and don't have an attack value
            setTimeout(this.turnCompleteFunction, 1000);      
          }
        }
        break;
    }

    // If the ship is disabled, flash it red
    if (currentShip.isDisabled || currentShip.onCooldown || currentShip.tractorBeamVulnerable > 0) {
      // Tween a tint on and off
      this.scene.tweens.addCounter({
        from: 0,
        to: 254,
        duration: 200 * this.timeScale,
        onUpdate: (tween) =>
        {
            const value = Math.floor(tween.getValue());

            this.setTint(Phaser.Display.Color.GetColor(value, 0, 0));
        },   
        onComplete: () => this.tintComplete()  
      });
    }         
  }

  changeSlot(currentShip, moveCompleteFunction) {        
    this.moveCompleteFunction = moveCompleteFunction;   
                         
    let tempX = (.5 * this.shipWidth) + (currentShip.currentBattleSlot * this.shipWidth) + ((currentShip.currentBattleSlot+1) * this.shipSpacer);

    // Tween it to the new position
    this.scene.tweens.add(
      {
        targets: [this, this.shieldDisplay],
        x: tempX,				
        ease: Phaser.Math.Easing.Quadratic.In,
        duration: 1000 * this.timeScale, 
        delay: 0,
        onComplete: () => this.moveComplete()  
      }
    );    

    // Tween it to the new position
    this.scene.tweens.add(
      {
        targets: [this, this.upgradeSprite],
        x: tempX,				
        ease: Phaser.Math.Easing.Quadratic.In,
        duration: 1000 * this.timeScale, 
        delay: 0,
        onComplete: () => this.moveComplete()  
      }
    );   

    // Tween it to the new position
    this.scene.tweens.add(
      {
        targets: [this, this.markedSprite],
        x: tempX,				
        ease: Phaser.Math.Easing.Quadratic.In,
        duration: 1000 * this.timeScale, 
        delay: 0,
        onComplete: () => this.moveComplete()  
      }
    );  
   
    // Tween displays
    this.scene.tweens.add(
      {
        targets: [this.shieldSprite, this.armorSprite],
        x:  tempX - (this.baseScale * 50),				
        ease: Phaser.Math.Easing.Quadratic.In,
        duration: 1000 * this.timeScale, 
        delay: 0 
      }
    );  

    // Tween level display
    this.scene.tweens.add(
      {
        targets: [this.levelSprite],
        x:  tempX - (this.baseScale * 50) - (this.baseScale * 175),				
        ease: Phaser.Math.Easing.Quadratic.In,
        duration: 1000 * this.timeScale, 
        delay: 0 
      }
    );  

    // Tween texts
    this.scene.tweens.add(
      {
        targets: [this.shieldText, this.armorText],
        x: (tempX - (this.baseScale * 50)) + (this.baseScale * 150),				
        ease: Phaser.Math.Easing.Quadratic.In,
        duration: 1000 * this.timeScale, 
        delay: 0 
      }
    );

    // Tween texts
    this.scene.tweens.add(
      {
        targets: [this.floatingArmorText, this.floatingShieldText],
        x: tempX - (this.baseScale * this.floatingTextOffset),			
        ease: Phaser.Math.Easing.Quadratic.In,
        duration: 1000 * this.timeScale, 
        delay: 0 
      }
    );
   
    // Tween shield connect
    if (this.pairedPositionRight) {
      this.scene.tweens.add(
        {
          targets: [this.pairedSprite],
          x:  tempX - (this.baseScale * 50) + (this.baseScale * 260),				
          ease: Phaser.Math.Easing.Quadratic.In,
          duration: 1000 * this.timeScale, 
          delay: 0 
        }
      ); 
    } else {
      this.scene.tweens.add(
        {
          targets: [this.pairedSprite],
          x:  tempX - (this.baseScale * 50) - (this.baseScale * 250),				
          ease: Phaser.Math.Easing.Quadratic.In,
          duration: 1000 * this.timeScale, 
          delay: 0 
        }
      ); 
    }
  }

  // This ship is dead, but lets move it anyways in case queen resurrects it
  changeSlotInstant(currentShip) {
    this.x = (.5 * this.shipWidth) + (currentShip.currentBattleSlot * this.shipWidth) + ((currentShip.currentBattleSlot+1) * this.shipSpacer);
    this.shieldSprite.x = this.x - (this.baseScale * 50);
    this.armorSprite.x = this.x - (this.baseScale * 50);
    this.levelSprite.x = this.x - (this.baseScale * 50) - (this.baseScale * 175);
    this.shieldText.x = (this.x - (this.baseScale * 50)) + (this.baseScale * 150);
    this.armorText.x = (this.x - (this.baseScale * 50)) + (this.baseScale * 150);
    this.shieldDisplay.x = this.x;
    this.upgradeSprite.x = this.x;
    this.markedSprite.x = this.x;
    this.floatingArmorText.x = this.x - (this.baseScale * this.floatingTextOffset);
    this.floatingShieldText.x = this.x - (this.baseScale * this.floatingTextOffset);
    if (this.pairedPositionRight) {
      this.pairedSprite.x = this.shieldSprite.x + (this.baseScale * 260);    
    } else {
      this.pairedSprite.x = this.shieldSprite.x - (this.baseScale * 250);    
    }
  }

  initSlot() {
    this.x = (.5 * this.shipWidth) + (this.ship.initialBattleSlot * this.shipWidth) + ((this.ship.initialBattleSlot+1) * this.shipSpacer);
    this.shieldDisplay.x = this.x;
    this.upgradeSprite.x = this.x;
    this.markedSprite.x = this.x;
    this.floatingArmorText.x = this.x - (this.baseScale * this.floatingTextOffset);
    this.floatingShieldText.x = this.x - (this.baseScale * this.floatingTextOffset);
    if (this.pairedPositionRight) {
      this.pairedSprite.x = this.shieldSprite.x + (this.baseScale * 260);    
    } else {
      this.pairedSprite.x = this.shieldSprite.x - (this.baseScale * 250);    
    } 
  }

  tintComplete() { 
    if (this.isDebuffed) {
      // tint it back to yellow
      this.scene.tweens.addCounter({
        from: 0,
        to: 255,
        duration: 500 * this.timeScale,
        onUpdate: (tween) =>
        {
            const value = Math.floor(tween.getValue());

            this.setTint(Phaser.Display.Color.GetColor(value, value, 0));
        }
      });   
    } else if (this.isFrozen) {
        // tint it to blue
        this.setTint(Phaser.Display.Color.GetColor(123, 194, 223));
        
    }  else {    
      // Tint it back to normal
      this.scene.tweens.addCounter({
        from: 0,
        to: 255,
        duration: 500 * this.timeScale,
        onUpdate: (tween) =>
        {
            const value = Math.floor(tween.getValue());

            this.setTint(Phaser.Display.Color.GetColor(value, value, value));
        }
      });   
    }    
  }

  rotateToZero(completeFunction) {    
    this.rotateCompleteFunction = completeFunction;

    // Tween it
    this.scene.tweens.add(
			{
				targets: this,
				rotation: 0,				
				ease: 'Power3',
				duration: 1000 * this.timeScale, 
				delay: 0,
				onComplete: () => this.rotateComplete()  
			}
    );  
    
    // Clear the tint
    //this.clearTint();
  }

  rotateComplete() {    
    this.rotateCompleteFunction();
  }

  moveComplete() {    
    this.moveCompleteFunction();
  }

  fireAtShip(targetShip, completeFunction) {    
    this.fireCompleteFunction = completeFunction;   
    
    // Tween it
    this.scene.tweens.add(
			{
				targets: this,
				rotation: 0,				
				ease: 'Power3',
				duration: 1000 * this.timeScale, 
				delay: 0,
				onComplete: () => this.fireComplete()  
			}
    ); 
  }

  fireComplete() {    
    this.fireCompleteFunction();
  }

  update() {    
    if (this.isEnemy) {
      this.y += this.speedY;
      if (this.y > this.targetY) {
        this.y = this.targetY;
        this.speedY = 0;        
      }
    } else {
      this.y += this.speedY;
      if (this.y < this.targetY) {
        this.y = this.targetY;
        this.speedY = 0;
      }
    }

    return this.speedY === 0;
  }

  showStatsDisplays() {
    // Place the armor + shield sprites
    this.shieldSprite.x = this.x - (this.baseScale * 50);
    this.armorSprite.x = this.x - (this.baseScale * 50);
    this.levelSprite.x = this.x - (this.baseScale * 50) - (this.baseScale * 175);
   
    if (this.isEnemy) {
      this.shieldSprite.y = this.y - (this.baseScale * 350) - (this.baseScale * 60);
      this.armorSprite.y = this.y - (this.baseScale * 350);
      this.levelSprite.y = this.y - (this.baseScale * 350) - ((this.baseScale * 60)/2);             
    } else {
      this.shieldSprite.y = this.y + (this.baseScale * 350);
      this.armorSprite.y = this.y + (this.baseScale * 350) + (this.baseScale * 60);
      this.levelSprite.y = this.y + (this.baseScale * 350) + ((this.baseScale * 60)/2);      
    }    

    // Start at 0 alpha and fade in
    this.shieldSprite.alpha = 0;
    this.armorSprite.alpha = 0;
    this.levelSprite.alpha = 0;

    // Tween it
    this.scene.tweens.add(
			{
				targets: [this.shieldSprite, this.armorSprite, this.levelSprite],
				alpha: 1,				
				ease: 'linear',
				duration: 1000 * this.timeScale, 
				delay: 0
			}
    ); 

    // Repeat for the texts
    this.shieldText.x = this.shieldSprite.x + (this.baseScale * 150);
    this.shieldText.y = this.shieldSprite.y - (this.baseScale * 23);
    this.armorText.x = this.armorSprite.x + (this.baseScale * 150);
    this.armorText.y = this.armorSprite.y - (this.baseScale * 23);
   
    // Start at 0 alpha and fade in
    this.shieldText.alpha = 0;
    this.armorText.alpha = 0;

    // Tween it
    this.scene.tweens.add(
			{
				targets: [this.shieldText, this.armorText],
				alpha: 1,				
				ease: 'linear',
				duration: 1000 * this.timeScale, 
				delay: 0
			}
    ); 

    // Place the shield display
    this.shieldDisplay.alpha = 0;
    this.shieldDisplay.x = this.x;
    this.shieldDisplay.y = this.y;

    if (this.isEnemy) {
      this.shieldDisplay.y += (this.scale * 170);
    } else {
      this.shieldDisplay.y -= (this.scale * 170);
    }

    // Place the upgrade
    this.upgradeSprite.alpha = 0;
    this.upgradeSprite.x = this.x;
    this.upgradeSprite.y = this.y;

    // Place the marked
    this.markedSprite.alpha = 0;
    this.markedSprite.x = this.x;
    this.markedSprite.y = this.y;

    // Place the floating texts
    this.floatingArmorText.alpha = 0;
    this.floatingArmorText.x = this.x - (this.baseScale * this.floatingTextOffset);
    this.floatingArmorText.y = this.y;
    this.floatingShieldText.alpha = 0;
    this.floatingShieldText.x = this.x - (this.baseScale * this.floatingTextOffset);
    this.floatingShieldText.y = this.y;

    // Place the paired sprite, start playing it    
    this.pairedSprite.y = this.shieldSprite.y + (this.baseScale * 25);
    this.pairedSprite.play("animationAquariusShieldConnect");
  }

  showShieldPairing(bShow, bRight) {
    this.pairedPositionRight = bRight;
    //console.log(`${this.ship.name} show shield pairing: ${bShow}, right? ${this.pairedPositionRight}`);
    if (bShow) {
      this.pairedSprite.alpha = 1;

      // Place it
      if (this.pairedPositionRight) {
        this.pairedSprite.x = this.shieldSprite.x + (this.baseScale * 260);    
      } else {
        this.pairedSprite.x = this.shieldSprite.x - (this.baseScale * 250);    
      }

    } else {
      this.pairedSprite.alpha = 0;
    }
  }

  triggerShields() {   
    // Shields depend on galaxy
    switch (this.ship.galaxy) {
      case 6:    
        this.showingShields = false;

        // Andromeda shields!
        this.shieldDisplay.play("animationDarkMatterShields");

        // Play the sound
        try {
          this.game.sound.play("dark_shields");
        } catch (e) {}
        break;
      case 5:    
        this.showingShields = false;

        // Andromeda shields!
        this.shieldDisplay.play("animationAquariusShields");

        // Play the sound
        try {
          this.game.sound.play("aquarius_water");
        } catch (e) {}
        break;
      case 4:    
        this.showingShields = false;

        // Andromeda shields!
        this.shieldDisplay.play("animationSpiralShields");

        // Play the sound
        try {
          this.game.sound.play("emp");
        } catch (e) {}
        break;
      
      case 3:    
        this.showingShields = false;

        // Andromeda shields!
        this.shieldDisplay.play("animationMyrmidonShields");

        // Play the sound
        try {
          this.game.sound.play("myrmidon_shield");
        } catch (e) {}
        break;
      case 2:    
        this.showingShields = false;

        // Andromeda shields!
        this.shieldDisplay.play("animationAndromedaShields");

        // Play the sound
        try {
          this.game.sound.play("andromeda_shields");
        } catch (e) {}
        break; 

      default:    
        // Show the shields, we use this for 2 part shield animations
        this.showingShields = true;

        // Milky way shields + sound
        this.shieldDisplay.play("animationShieldFadeIn");

        // Play the sound
        try {
          this.game.sound.play("shield");
        } catch (e) {}
    }
    
    this.shieldDisplay.alpha = 1;         
  }

  triggerSpecial(specialName) {
    // Show a special    
    switch (specialName) {
      case "missile_magnet":
        this.specialSprite.x = this.x;
        this.specialSprite.y = this.y;  
        if (this.isEnemy) {
          this.specialSprite.y += (this.scale * 170);
        } else {
          this.specialSprite.y -= (this.scale * 170);
        }
      
        this.specialSprite.play("animationMissileMagnet");
        this.specialSprite.alpha = 1; 
        break;
      case "reflect":
        this.specialSprite.x = this.x;
        this.specialSprite.y = this.y;  
        if (this.isEnemy) {
          this.specialSprite.y += (this.scale * 170);
        } else {
          this.specialSprite.y -= (this.scale * 170);
        }
                
        this.specialSprite.play("animationReflect");
        this.specialSprite.alpha = 1; 
        break;
      case "missile_shield":
        this.specialSprite.x = this.x;
        this.specialSprite.y = this.y;         
                
        this.specialSprite.play("animationPhalanxShield");
        this.specialSprite.alpha = 1; 
        break;
      case "hardened_shields":
        this.specialSprite.x = this.x;
        this.specialSprite.y = this.y;         
                
        this.specialSprite.play("animationAquariusBarrier");
        this.specialSprite.alpha = 1; 
        break;
      case "armor_buff":
        this.specialSprite.x = this.x;
        this.specialSprite.y = this.y;  
                                
        this.specialSprite.play("animationArmorUp");
        this.specialSprite.alpha = 1; 
        break;      
    }    
  }

  specialAnimationComplete() {    
    this.scene.tweens.add(
			{
				targets: this.specialSprite,
				alpha: 0,				
				ease: 'linear',
				duration: 300 * this.timeScale, 
				delay: 0
			}
    ); 
  }

  shieldAnimationComplete() {
    // If we were showing the shields, now hide them
    if (this.showingShields) {
      this.showingShields = false;
      
      this.shieldDisplay.play("animationShieldFadeOut");
    } else {
      // Hide them
      this.shieldDisplay.alpha = 0;
    } 
  }

  updateStatsDisplay(currentState) {    
    // If my shields or armor have increased past my initial values, update the initial values
    if (currentState.currentShields > this.ship.originalShields) {
      this.ship.originalShields = currentState.currentShields;
    }
    if (currentState.currentArmor > this.ship.originalArmor) {
      this.ship.originalArmor = currentState.currentArmor;
    }
    
    let myShieldRatio = Math.ceil((currentState.currentShields / this.ship.originalShields) * 10);
    let myArmorRatio = Math.ceil((currentState.currentArmor / this.ship.originalArmor) * 10);    
    
    if (myShieldRatio) {
      this.shieldSprite.setFrame(`shields_${this.ship.rarity}_${myShieldRatio.toString().padStart(2,'0')}.png`);
    } else {
      this.shieldSprite.setFrame("shields_" + this.ship.rarity + "_01.png")
    }
    if (myArmorRatio) {
      this.armorSprite.setFrame(`armor_${this.ship.rarity}_${myArmorRatio.toString().padStart(2,'0')}.png`);

      // If its still alive always show at least one bar
      if (myArmorRatio === 1 && currentState.currentArmor > 0) {
        this.armorSprite.setFrame("armor_" + this.ship.rarity + "_02.png");
      }
    } else {
      this.armorSprite.setFrame("armor_" + this.ship.rarity + "_01.png")
    }

    // Special case for reflect ships just show armor as bars
    if (currentState.special_name === 'reflect') {
      this.armorSprite.setFrame("armor_" + this.ship.rarity + "_0" + (currentState.currentArmor+1) + ".png");
    }
    
    // Update the texts
    this.shieldText.text = currentState.currentShields.toString().padStart(3,'0');
    this.armorText.text = currentState.currentArmor.toString().padStart(3,'0');

    // Update swarm variables
    this.showSwarm = currentState.showSwarm;
    this.isSwarm = currentState.isSwarm;

    // Other variables
    this.isDebuffed = currentState.turnsDebuffed> 0 ? true : false; 
    this.showUpgrade = currentState.showUpgrade;
    this.isUpgraded = currentState.isUpgraded;
    this.isFrozen = currentState.tractorBeamVulnerable > 0 ? true : false;

    // If I am debuffed, tint me
    if (this.isDebuffed) {
      // Yellow ish
      this.setTint(Phaser.Display.Color.GetColor(255, 255, 0));
    } else if (this.isFrozen) {
        // Blue ish
        this.setTint(Phaser.Display.Color.GetColor(123, 194, 223));
    } else {
      // Back to normal
      this.setTint(Phaser.Display.Color.GetColor(255, 255, 255));
    }

    // Floating targets
    let bShowArmorText = false;
    let bShowShieldText = false;
    
    // Check cloaking status first
    if (currentState.showCloaking && currentState.isCloaked) {
      // Show the red cloaking poof
      this.specialSprite.x = this.x;
      this.specialSprite.y = this.y;            
      this.specialSprite.play("animationDarkMatterCloud");
      this.specialSprite.alpha = 1; 

      // Fade the ship out a bit
      this.alpha = 0.5;    

      // Play a sound      
      try {
        this.game.sound.play("dark_cloaking");
      } catch (e) {}

    } else if (currentState.showCloaking && !currentState.isCloaked) {
      // Show a "miss!" text
      bShowArmorText = true;
      this.floatingArmorText.setTint(Phaser.Display.Color.GetColor(255, 255, 255));
      this.floatingArmorText.text = "Missed!";

      // Come back to full alpha
      this.scene.tweens.add(
        {
          targets: this,
          alpha: 1,				
          ease: 'linear',
          duration: 1000 * this.timeScale, 
          delay: 0
        }
      ); 

       // Play a sound      
       try {
        this.game.sound.play("dark_cloaking");
      } catch (e) {}

    } else {
      // Show floating text normally
    
      // Show a text for shield change + armor change
      if (currentState.currentArmor > this.previousArmor) {
        // Show armor text in green
        this.floatingArmorText.setTint(Phaser.Display.Color.GetColor(144, 238, 144));
        this.floatingArmorText.text = "+" + Math.floor(currentState.currentArmor - this.previousArmor);
        bShowArmorText = true;

      } else if (currentState.currentArmor < this.previousArmor) {
        // Show armor text in red
        this.floatingArmorText.setTint(Phaser.Display.Color.GetColor(255, 0, 0));
        this.floatingArmorText.text = "-" + Math.floor(this.previousArmor - currentState.currentArmor);
        bShowArmorText = true;      
      }

      if (currentState.currentShields > this.previousShields) {
        // Show shield text in green
        this.floatingShieldText.setTint(Phaser.Display.Color.GetColor(144, 238, 144));
        this.floatingShieldText.text = "+" + Math.floor(currentState.currentShields - this.previousShields);
        bShowShieldText = true;

      } else if (currentState.currentShields < this.previousShields) {
        // Show shield text in blue
        this.floatingShieldText.setTint(Phaser.Display.Color.GetColor(253, 255, 68));      
        this.floatingShieldText.text = "-" + Math.floor(this.previousShields - currentState.currentShields);      
        bShowShieldText = true;
      }
    }

    // Update previous values
    this.previousArmor = currentState.currentArmor;
    this.previousShields = currentState.currentShields; 

    // Tween them up and fade out
    if (bShowShieldText) {    
      this.floatingShieldText.y = this.y;
      this.floatingShieldText.alpha = 1;

      this.scene.tweens.add(
        {
          targets: this.floatingShieldText,
          y: this.y - (this.baseScale * 300),				
          ease: Phaser.Math.Easing.Quadratic.In,
          duration: 2000 * this.timeScale, 
          delay: 0      
        }
      ); 
      this.scene.tweens.add(
        {
          targets: this.floatingShieldText,
          alpha: 0,				
          ease: Phaser.Math.Easing.Quadratic.In,
          duration: 2000 * this.timeScale, 
          delay: 0
        }
      ); 
    }
    if (bShowArmorText) {    
      this.floatingArmorText.y = this.y;
      this.floatingArmorText.alpha = 1;

      this.scene.tweens.add(
        {
          targets: this.floatingArmorText,
          y: this.y - (this.baseScale * 300),				
          ease: Phaser.Math.Easing.Quadratic.In,
          duration: 4000 * this.timeScale, 
          delay: 0      
        }
      ); 
      this.scene.tweens.add(
        {
          targets: this.floatingArmorText,
          alpha: 0,				
          ease: Phaser.Math.Easing.Quadratic.In,
          duration: 4000 * this.timeScale, 
          delay: 0
        }
      ); 
    }

    
  }

  startSwarm() {
    // Just in case this was a resurrection, put these back up
    this.shieldSprite.alpha = 1;
    this.armorSprite.alpha = 1;
    this.levelSprite.alpha = 1;
    this.shieldText.alpha = 1;
    this.armorText.alpha = 1;
    
    // Quickly fade out the main ship sprite
    this.scene.tweens.add(
			{
				targets: this,
				alpha: 0,				
				ease: 'linear',
				duration: 300 * this.timeScale, 
				delay: 0,
        onComplete: () => this.deploySwarm()  
			}
    ); 
  }

  deploySwarm() {
    // Play the "Deploy swarm" animation
    this.anims.play('animationSwarmDeploy');
    this.alpha = 1;

    // Scale it down
    this.scale = (this.baseScale * 0.3) * 2;  
  }

  swarmComplete() {
    if (this.showSwarm) {
      // Replace the original ship with a swarm      
      this.anims.play('animationSwarmEngines');

      // Clear show swarm
      this.showSwarm = false;
    }
  }

  showDecoy () {
    // Replace the ship image with a decoy
    if (this.ship.isPlayerShip) {
      this.anims.play('enginesUpgradedPlayer' + this.ship.id);
    } else {
      this.anims.play('enginesUpgradedEnemy' + this.ship.id);
    }
  }

  startUpgrade(bUpgraded) {    
    // Are we upgrading or downgrading?
    if (bUpgraded) {
      // Instantly switch to the upgraded sprite
      if (this.ship.isPlayerShip) {
        this.anims.play('enginesUpgradedPlayer' + this.ship.id);
      } else {
        this.anims.play('enginesUpgradedEnemy' + this.ship.id);
      }
    } else {
      // Downgrade, switch back to normal      
      this.anims.play(this.baseAnimationName);      
    }

    // Play the upgrade animation once    
    this.upgradeSprite.play("animationSpiralUpgrade2");
    this.upgradeSprite.alpha = 1;
    
    // Play the sound
    try {
      this.game.sound.play("myrmidon_shield");
    } catch (e) {}
  }

  upgradeAnimationComplete() {    
    // Hide the upgrade sprite
    if (this.upgradeSprite) {     
      this.upgradeSprite.alpha = 0;
    } 
  }

  showUpgradedShip(bUpgraded) {
    if (bUpgraded) {
      // Instantly switch to the upgraded sprite
      if (this.ship.isPlayerShip) {
        this.anims.play('enginesUpgradedPlayer' + this.ship.id);
      } else {
        this.anims.play('enginesUpgradedEnemy' + this.ship.id);
      }
    } else {
      // Downgrade, switch back to normal      
      this.anims.play(this.baseAnimationName);      
    }
  }

  changeWeapon(newWeaponType, previousWeaponType) {
    // Switch to the new engine animation
    switch (newWeaponType) {
      case "missile":
        // Missile uses the original animation
        this.anims.play(this.baseAnimationName);  
        break;
      case "projectile":
        this.anims.play('animationAnnihilatorEnginesProjectile');
        break;
      case "corrosive":
        this.anims.play('animationAnnihilatorEnginesCorrosive');
        break;  
    }
  }


  applyMarked() {        
    // Play the marked animation 
    this.markedSprite.play("animationSpiralTargeted");
    this.markedSprite.alpha = 1;        
  }

  clearMarked() {           
    this.markedSprite.alpha = 0;        
  }
 
  startDestroy() {    
    this.shieldSprite.alpha = 0;
    this.armorSprite.alpha = 0;
    this.levelSprite.alpha = 0;
    this.shieldText.alpha = 0;
    this.armorText.alpha = 0;
    this.pairedSprite.alpha = 0;

    this.scene.tweens.add(
			{
				targets: this,
				alpha: 0,				
				ease: 'linear',
				duration: 300 * this.timeScale, 
				delay: 0
			}
    ); 
    /*
    this.scene.tweens.addCounter({
      from: 0,
      to: 254,
      duration: 200 * this.timeScale,
      onUpdate: (tween) =>
      {
          const value = Math.floor(tween.getValue());

          this.setTint(Phaser.Display.Color.GetColor(value, value, value));
      }
    });
    */
  }

  flashRed() {
    // Tween a tint on and off
    this.scene.tweens.addCounter({
      from: 0,
      to: 254,
      duration: 200 * this.timeScale,
      onUpdate: (tween) =>
      {
          const value = Math.floor(tween.getValue());

          this.setTint(Phaser.Display.Color.GetColor(value, 0, 0));
      },   
      onComplete: () => this.tintComplete()  
    });
  }

  flashYellow() {
    // Tween a tint on and off
    this.scene.tweens.addCounter({
      from: 0,
      to: 254,
      duration: 200 * this.timeScale,
      onUpdate: (tween) =>
      {
          const value = Math.floor(tween.getValue());

          this.setTint(Phaser.Display.Color.GetColor(value, value, 0));
      },   
      onComplete: () => this.tintComplete()  
    });
  }
}

export default Ship;