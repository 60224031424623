class BattleDisplay  {    
  constructor(scene, game, playerShips, enemyShips, battleResult, baseScale, timeScale, completeFunction) {      
    this.scene = scene;
    this.game = game;
    this.baseScale = baseScale;
    this.playerShipSprites = playerShips;
    this.enemyShipSprites = enemyShips;
    this.battleResult = battleResult
    this.battleStarted = false;
    this.battleStep = -1;
    this.previousBattleState = "";
    this.battleState = "start_next_step"
    this.attackingShip = null;
    this.defendingShip = null;
    this.attackingSprite = null;
    this.defendingSprite = null;    
    this.playerTurn = false;
    this.timeScale = timeScale;
    this.completeFunction = completeFunction;

    // Build some re-used sprites
    this.explosionLarge = this.scene.add.sprite(-1000, 0, "explosionLarge");
    this.explosionLarge.on('animationcomplete', () => this.onAnimationComplete('explosionLarge'));  
    this.explosionLarge.alpha = 0;    
    this.explosionLarge.scale = this.baseScale * 2; 
    this.disintegrate = this.scene.add.sprite(-1000, 0, "disintegrate");
    this.disintegrate.on('animationcomplete', () => this.onAnimationComplete('disintegrate'));  
    this.disintegrate.alpha = 0;    
    this.disintegrate.scale = this.baseScale * 2; 
    this.disintegrateAttacker = this.scene.add.sprite(-1000, 0, "disintegrateAttacker");
    this.disintegrateAttacker.on('animationcomplete', () => this.onAnimationComplete('disintegrateAttacker'));  
    this.disintegrateAttacker.alpha = 0;    
    this.disintegrateAttacker.scale = this.baseScale * 2; 
    this.explosionSmall = this.scene.add.sprite(-1000, 0, "explosionSmall");
    this.explosionSmall.on('animationcomplete', () => this.onAnimationComplete('explosionSmall'));
    this.explosionSmall.scale = this.baseScale * 0.5;
    this.explosionSmall.alpha = 0;  
    this.hitLaser = this.scene.add.sprite(-1000, 0, "hitLaser");    
    this.hitLaser.scale = this.baseScale * 0.25;
    this.hitLaser.on('animationcomplete', () => this.onAnimationComplete('hitLaser'));
    this.hitLaser.alpha = 0;  
    this.hitMassDriver = this.scene.add.sprite(-1000, 0, "hitMassDriver");    
    this.hitMassDriver.scale = this.baseScale * 0.15;
    this.hitMassDriver.on('animationcomplete', () => this.onAnimationComplete('hitMassDriver'));
    this.hitMassDriver.alpha = 0;  
    this.hitNuclearMissile = this.scene.add.sprite(-1000, 0, "hitNuclearMissile");    
    this.hitNuclearMissile.scale = this.baseScale * 0.3;
    this.hitNuclearMissile.on('animationcomplete', () => this.onAnimationComplete('hitNuclearMissile'));
    this.hitNuclearMissile.alpha = 0;  
    this.projectileLaser = this.scene.add.sprite(-1000, 0, "projectileLaser");    
    this.projectileLaser.scale = this.baseScale * 0.25;
    
    this.projectileSpiral = this.scene.add.sprite(-1000, 0, "projectileSpiral");    
    this.projectileSpiral.scale = this.baseScale * 0.5;
    this.projectileThundercat = this.scene.add.sprite(-1000, 0, "projectileThundercat");    
    this.projectileThundercat.scale = this.baseScale * 2;

    this.projectileAquarius = this.scene.add.sprite(-1000, 0, "projectileAquarius");    
    this.projectileAquarius.scale = this.baseScale * 0.5;
    this.projectileAquariusBig = this.scene.add.sprite(-1000, 0, "projectileAquariusBig");    
    this.projectileAquariusBig.scale = this.baseScale * 2;
    this.shieldConnect = this.scene.add.sprite(-1000, 0, "shieldConnect");    
    this.shieldConnect.scale = this.baseScale * 1;

    this.projectilePlasma = this.scene.add.sprite(-1000, 0, "projectilePlasma");    
    this.projectilePlasma.scale = this.baseScale * 0.25;
    this.projectileMassDriver = this.scene.add.sprite(-1000, 0, "projectileMassDriver");        
    this.projectileMassDriver.scale = this.baseScale * 0.15; 
    this.missileNuclear = this.scene.add.sprite(-1000, 0, "missileNuclear");    
    this.missileNuclear.scale = this.baseScale * 0.2;   
    this.missileStinger = this.scene.add.sprite(-1000, 0, "missileStinger");    
    this.missileStinger.scale = this.baseScale * 0.2;   
    this.nanobots = this.scene.add.sprite(-200, 0, "nanobots");    
    this.nanobots.scale = this.baseScale * 0.75;   
    this.nanobotsHealing = this.scene.add.sprite(-1000, 0, "nanobotsHealing");    
    this.nanobotsHealing.scale = this.baseScale * 0.75;
    this.nanobotsHealing.on('animationcomplete', () => this.onAnimationComplete('nanobotsHealing'));    
    this.disableOrb = this.scene.add.sprite(-1000, 0, "disableOrb");
    this.disableOrb.scale = this.baseScale * 0.25;
    this.spitBolt = this.scene.add.sprite(-1000, 0, "spitBolt");
    this.spitBolt.scale = this.baseScale * 0.25;
    this.drainOrb = this.scene.add.sprite(-1000, 0, "drainOrb");
    this.drainOrb.scale = this.baseScale * 0.5;

    this.missileFire = this.scene.add.sprite(-1000, 0, "missileFire");    
    this.missileFire.scale = this.baseScale * 0.2;  
    this.projectileFirewave = this.scene.add.sprite(-1000, 0, "projectileFirewave");    
    this.projectileFirewave.scale = this.baseScale * 0.5; 

    // Add win / lose animations
    this.winAnimation = this.scene.add.sprite(0, 0, "winAnimation");  
    this.winAnimation.alpha = 0;
    this.winAnimation.x = this.game.canvas.clientWidth / 2;
    this.winAnimation.y = this.game.canvas.clientHeight / 2;
    this.winAnimation.scale = this.baseScale * 2;
    this.loseAnimation = this.scene.add.sprite(0, 0, "loseAnimation");     
    this.loseAnimation.alpha = 0;
    this.loseAnimation.x = this.game.canvas.clientWidth / 2;
    this.loseAnimation.y = this.game.canvas.clientHeight / 2;
    this.loseAnimation.scale = this.baseScale * 2;

    this.currentWeapon = null;    
  }

  // Start battle!
  update() {    
    if (this.battleStarted) {
      if (this.battleState != this.previousBattleState) {        
        this.previousBattleState = this.battleState;
      }

      //console.log("battle state", this.battleState);

      switch (this.battleState) {
        case "start_next_step":
          // Get the next step
          this.battleStep++;

          // If we are in fast forward mode, find the next step with a death or a move
          if (this.scene.isFastForward) {            
            this.fastForward();                        
          }  
        
          // Stop if we are done
          if (this.battleStep >= this.battleResult.battleSteps.length) {
            break;
          }

          // Set some easy getters
          this.playerTurn = this.battleResult.battleSteps[this.battleStep].bPlayer;
          this.attackingShip = this.battleResult.battleSteps[this.battleStep].attackingShip;
          this.defendingShip = this.battleResult.battleSteps[this.battleStep].defendingShip;
          this.attackingSprite = this.findSpriteByIndex(this.playerTurn, this.attackingShip);
          
          // Some special abilities target friendly ships
          if (this.defendingShip.isPlayerShip === this.playerTurn) {
            // Target a friendly  
            this.defendingSprite = this.findSpriteByIndex(this.playerTurn, this.defendingShip);
          } else {
            // Target an enemy ship normally
            this.defendingSprite = this.findSpriteByIndex(!this.playerTurn, this.defendingShip);
          }
                   
          console.log(`Starting step ${this.battleStep} ${this.playerTurn ? 'Player' : 'Opponent'} ${this.attackingShip.name} targeting ${this.defendingShip.name} ressurection? ${this.defendingShip.wasResurrected}`);          
          
          // Has an annihilator changed weapon?
          if (this.attackingShip.showWeaponChange) {
            // Update the sprite to show the new weapon
            this.attackingSprite.changeWeapon(this.attackingShip.weapon_type, this.attackingShip.previousWeaponType);

            // Continue on normally
          }

          // Is this a "move" event?
          if (this.attackingShip.slotChanged) {
            // We are moving a ship from slot to slot
            this.battleState = "ship_move_start";

          } else if (this.attackingShip.showShieldLeech) {
            // We leeched shields, show a shield animation and move on
            // We have received an "armor up" buff, just show the buff + move on
            this.attackingSprite.triggerShields();

            // Update the stats
            this.attackingSprite.updateStatsDisplay(this.attackingShip);

            // Ship is done and ready for the next step
            this.battleState = "start_next_step";  

          } else if (this.attackingShip.showShieldPair) {   
            // Handle pairing logic
            this.checkShieldPairing();
                      
            // Move to next step
            this.battleState = "start_next_step";
          } else if (this.defendingShip.showDamageBoost) {
            // Show the fiery powerup thingy
            this.defendingSprite.triggerSpecial("missile_shield");

            // Move to next step
            this.battleState = "start_next_step";

          } else if (this.defendingShip.showCloaking && this.defendingShip.isCloaked) {
            // Show the ship going into stealth
            this.defendingSprite.updateStatsDisplay(this.defendingShip);
            
            // Move to next step
            this.battleState = "start_next_step";
        
          } else if (this.defendingShip.showingPairDamage) {   
            // Skip to the part where we just show the damage done
            this.weaponHit();
                      
            // Move to next step
            this.battleState = "start_next_step";

          } else if (this.defendingShip.showArmorUp) {
            // We have received an "armor up" buff, just show the buff + move on
            this.defendingSprite.triggerSpecial("armor_buff");

            // Update the stats
            this.defendingSprite.updateStatsDisplay(this.defendingShip);

            // Ship is done and ready for the next step
            this.battleState = "start_next_step";  
          } else if (this.attackingShip.showUpgrade) {
            // Spiral ship has upgraded / downgraded            

            // If the ship has been destroyed, remove it!
            if (this.attackingShip.currentArmor <= 0) {
              // Blow it up!
              this.explosionLarge.x = this.attackingSprite.x;
              this.explosionLarge.y = this.attackingSprite.y;   
              this.explosionLarge.alpha = 1;   
              this.explosionLarge.anims.play('animationExplosionLarge');         
              
              // Clear "marked"
              this.attackingSprite.clearMarked();

              // Tell the ship
              this.attackingSprite.startDestroy(); 
                    
              // Large explosion
              try {
                this.game.sound.play("explode_big");
              } catch (e) {}
            } else {
              // We need to show the upgrade for this ship
              this.attackingSprite.startUpgrade(this.attackingShip.isUpgraded);

              // Update the stats
              this.attackingSprite.updateStatsDisplay(this.attackingShip);
            }

            // Ship is done and ready for the next step
            this.battleState = "start_next_step";  

          } else if (this.attackingShip.tractorBeamStateChanged && this.attackingShip.showTractorBeam === false) {
            // A tractor beam ship has "broken" its lock          
            this.attackingSprite.showUpgradedShip(false);

            // If there is a defending ship, make it OK now
            if (this.defendingShip.currentArmor > 0 && this.defendingSprite) {
              // Update the stats
              this.defendingSprite.updateStatsDisplay(this.defendingShip);
            }

            // Ship is done and ready for the next step
            this.battleState = "start_next_step";  

          } else if (this.attackingShip.endHardenedShields) {
            // Just flash it red and move on
            this.attackingSprite.flashRed();

            // Ship is done and ready for the next step
            this.battleState = "start_next_step";  

          } else {
            // Start rotating the first ship towards its target
            this.battleState = "ship_rotate_target_start";  
          }          
          break;
        case "ship_rotate_target_start":
          // Now in the waiting state
          this.battleState = "ship_rotate_target_wait";
          
          // Tell the attacking ship to rotate
          this.attackingSprite.rotateToShip(this.attackingShip, this.defendingSprite, this.defendingShip, () => this.rotateComplete(), () => this.turnComplete());          
          break;
        case "ship_fire_target_start":                    
          // Now in the waiting state
          this.battleState = "ship_fire_target_wait";

          // Fire!
          this.fireAtTarget();
          break;
        case "ship_rotate_neutral_start":
          // Tell the attacking ship to rotate back to 0
          this.attackingSprite.rotateToZero(() => this.rotateComplete());

          // Now in the waiting state
          this.battleState = "ship_rotate_neutral_wait";
          break;
        case "ship_move_start":
          // Now in the waiting state
          this.battleState = "ship_move_wait";
          
          // Tell the attacking ship to rotate
          this.attackingSprite.changeSlot(this.attackingShip, () => this.moveComplete());
          
          // The "defending" sprite is the dead ship we are taking the place of, move it invisible
          this.defendingSprite.changeSlotInstant(this.defendingShip);          
          break;       
      }

      if (this.battleStep >= this.battleResult.battleSteps.length) {        
        this.battleStarted = false;

        // Call the complete function on the parent
        this.completeFunction();

        // Show win / lose
        this.showFinalAnimation();
      }      
    }
  }

  skipToEnd() {
    if (this.battleStarted) {
      // Show all ships in their final locations
      this.battleStarted = false;

      // Wait a second for anything in progress to stop
      setTimeout(() => this.continueSkip(), 2000);
    }
  }

  continueSkip() {
    // Update player ships
    for (let i = 0; i < this.playerShipSprites.length; i++) {
      // Find it in the final fleet
      for (let j = 0; j < this.battleResult.playerFleet.length; j++) {
        if (this.playerShipSprites[i].ship.id === this.battleResult.playerFleet[j].id) {
          // Update the stats on it
          this.playerShipSprites[i].updateStatsDisplay(this.battleResult.playerFleet[j]);

          // Hide the pairing
          this.playerShipSprites[i].showShieldPairing(false,false);

          // If it is dead, remove it
          if (this.battleResult.playerFleet[j].currentArmor <= 0) {
            this.playerShipSprites[i].startDestroy();
          }
        }
      }
    }

    // Update enemy ships
    for (let i = 0; i < this.enemyShipSprites.length; i++) {
      // Find it in the final fleet
      for (let j = 0; j < this.battleResult.enemyFleet.length; j++) {
        if (this.enemyShipSprites[i].ship.id === this.battleResult.enemyFleet[j].id) {
          // Update the stats on it
          this.enemyShipSprites[i].updateStatsDisplay(this.battleResult.enemyFleet[j]);

          // Hide the pairing
          this.enemyShipSprites[i].showShieldPairing(false,false);

          // If it is dead, remove it
          if (this.battleResult.enemyFleet[j].currentArmor <= 0) {
            this.enemyShipSprites[i].startDestroy();
          }
        }
      }
    }

    // Show win / lose
    this.showFinalAnimation();
  }

  // show the final animation
  showFinalAnimation() {
    // Show win / lose animation
    if (this.battleResult.battleWon) {
      this.winAnimation.play("animationWin");
      this.winAnimation.alpha = 1; 
      try {
        this.game.sound.play("win");
      } catch (e) {}
    } else {
      this.loseAnimation.play("animationLose");
      this.loseAnimation.alpha = 1;
      try {
        this.game.sound.play("lose");
      } catch (e) {}
    }
  }

  rotateComplete() {
    // Ship is ready to fire!
    if (this.battleState === "ship_rotate_target_wait") {
      this.battleState = "ship_fire_target_start";
    } else {
      // Ship has fired and is ready for next step
      this.battleState = "start_next_step";
    }
  }

  moveComplete() {
    // Sometimes a slot change is combined with a pairing update
    this.checkShieldPairing();
    
    // Ship is done and ready for the next step
    this.battleState = "start_next_step";    
  }

  checkShieldPairing() {
    if (this.attackingShip.showShieldPair) {   
      //console.log(`${this.attackingShip.name} pairing. Slot: ${this.attackingShip.currentBattleSlot} PairSlot: ${this.attackingShip.shieldPairSlot}`);         
      // We are linking / unlinking shield
      if (this.attackingShip.shieldPairSlot > -1) {
        // This ship is now linked
        if (this.attackingShip.currentBattleSlot > this.attackingShip.shieldPairSlot) {
          // Show it on the left
          this.attackingSprite.showShieldPairing(true,false);
        } else {
          // Show it on the right
          this.attackingSprite.showShieldPairing(true,true);
        }                          

        // Also show it on the target, only if its different
        if (this.attackingShip.id !== this.defendingShip.id) {
          //console.log(`${this.defendingShip.name} pairing. Slot: ${this.defendingShip.currentBattleSlot} PairSlot: ${this.defendingShip.shieldPairSlot}`);         
          if (this.defendingShip.currentBattleSlot > this.defendingShip.shieldPairSlot) {
            // Show it on the left
            this.defendingSprite.showShieldPairing(true,false);
          } else {
            // Show it on the right
            this.defendingSprite.showShieldPairing(true,true);
          }
        }
      } else {
        // Turn it off
        this.attackingSprite.showShieldPairing(false,true);
      }
    }               
  }

  turnComplete() {
    // This ship skipped it's turn   
    
    // Update the stats on the defender + attacker
    this.attackingSprite.updateStatsDisplay(this.attackingShip);
    this.defendingSprite.updateStatsDisplay(this.defendingShip);
    
    // Next step
    this.battleState = "start_next_step";    
  }

  fireAtTarget() {
    // If we are showing a resurrection, skip to firecomplete
    if (this.defendingShip.wasResurrected) {
      this.fireComplete();
      return;
    }
    
    // If this is a shield battery "firing" at a friendly trigger it's shields animation
    if (this.attackingShip.initialSpecialName === 'shield_battery' && this.attackingShip.isPlayerShip === this.defendingShip.isPlayerShip) {
      this.defendingSprite.triggerShields();
      this.fireComplete();
      return;
    }

    // If the target is a missile magnet, trigger its special animation + play a sound
    if (this.defendingShip.initialSpecialName === 'missile_magnet' && this.attackingShip.weapon_type === 'missile') {
      this.defendingSprite.triggerSpecial(this.defendingShip.initialSpecialName);
      try {
        this.game.sound.play("magnet");
      } catch (e) {}
    }

    // What are we firing?
    this.currentWeapon = null;
    switch (this.attackingShip.special_name) {
      case "emp_pulse":
        this.currentWeapon = this.disableOrb;   
        this.currentWeapon.play("animationDisableOrb");    
        
        // Play the sound
        try {
          this.game.sound.play("emp");
        } catch (e) {}
        break;
      case "tracking":
        this.currentWeapon = this.disableOrb;   
        this.currentWeapon.play("animationSpiralTargetBeam");    
        
        // Play the sound
        try {
          this.game.sound.play("emp");
        } catch (e) {}
        break;
      case "shield_debuff":
        // If draining, use the shield drain special
        if (this.attackingShip.showShieldDrain) {
          this.currentWeapon = this.drainOrb;   
          this.currentWeapon.play("animationSpiralShieldDrain");    

          // Play the sound
          try {
            this.game.sound.play("emp");
          } catch (e) {}
        } else {
          // Normal attack
          this.currentWeapon = this.projectileSpiral;   
          this.currentWeapon.play("animationSpiralProjectile"); 
          
          // Play the sound
          try {
            this.game.sound.play("spiral_projectile");          
          } catch (e) {}
        }                
        break;
      case "tractor_beam":
        this.currentWeapon = this.projectileAquariusBig;   
        this.currentWeapon.play("animationAquariusWaterGlobe");    
        
        // Play the sound
        try {
          this.game.sound.play("aquarius_explosion");
        } catch (e) {}

        // Show the upgraded form to show tractor beam       
        this.attackingSprite.showUpgradedShip(true);
        break;
      case "spit_debuff":
        this.currentWeapon = this.spitBolt;   
        this.currentWeapon.play("animationSpit");    
        
        // Play the sound
        try {
          this.game.sound.play("andromeda_projectile");  
        } catch (e) {}
        break;
      case "repair":
        // Only show the nanos if it didn't idle
        if (this.attackingShip.shipIdled === false) {
          this.currentWeapon = this.nanobots;   
          this.currentWeapon.play("animationNanobots");   
          
          // Play the sound
          try {
            this.game.sound.play("repair");
          } catch (e) {}
        }
        break;     
      default:        
        if (this.attackingShip.currentWeaponPower > 0) {
          switch (this.attackingShip.weapon_name) {
            case "laser":
              this.currentWeapon = this.projectileLaser;   
              this.currentWeapon.play("animationLaser"); 
              
              // Play the sound
              try {
                this.game.sound.play("laser");          
              } catch (e) {}
              break;
            case "mass_driver":
              this.currentWeapon = this.projectileMassDriver;        
              this.currentWeapon.play("animationMassDriver");

              // Play the sound
              try {
                this.game.sound.play("mass_driver");
              } catch (e) {}
              break;
            case "nuclear_missile":
              this.currentWeapon = this.missileNuclear;  
              this.currentWeapon.play("animationNuclearMissileCluster");  
              
              // Play the sound
              try {
                this.game.sound.play("nuclear_missile");
              } catch (e) {}
              break;
            case "plasma":
              this.currentWeapon = this.projectilePlasma;   
              this.currentWeapon.play("animationPlasmaGun"); 
              
              // Play the sound
              try {
                this.game.sound.play("andromeda_projectile");          
              } catch (e) {}
              break;
            case "stinger":
              this.currentWeapon = this.missileStinger;  
              this.currentWeapon.play("animationStingerMissile");  
              
              // Play the sound
              try {
                this.game.sound.play("andromeda_missile");
              } catch (e) {}
              break;
            case "firewave":
              this.currentWeapon = this.projectileFirewave;   
              this.currentWeapon.play("animationFirewave"); 
              
              // Play the sound
              try {
                this.game.sound.play("flamewave_attack");          
              } catch (e) {}
              break;
            case "fire_missile":
              this.currentWeapon = this.missileFire;  
              this.currentWeapon.play("animationFireMissile");  
              
              // Play the sound
              try {
                this.game.sound.play("nuclear_missile");
              } catch (e) {}
              break;
            case "ball_lightning":
              // Play the big one if this is the thundercat
              if (this.attackingShip.id === 58) {
                this.currentWeapon = this.projectileThundercat;   
                this.currentWeapon.play("animationSpiralProjectile"); 
                
                // Play the sound
                try {
                  this.game.sound.play("super_lightning");          
                } catch (e) {}

              } else {
                this.currentWeapon = this.projectileSpiral;   
                this.currentWeapon.play("animationSpiralProjectile"); 
                
                // Play the sound
                try {
                  this.game.sound.play("spiral_projectile");          
                } catch (e) {}
              }
              break;
            case "shock_missile":
              this.currentWeapon = this.missileFire;   
              this.currentWeapon.play("animationSpiralMissile"); 
              
              // Play the sound
              try {
                this.game.sound.play("nuclear_missile");          
              } catch (e) {}
              break;

            case "lightning_wave":
              this.currentWeapon = this.projectileFirewave;   
              this.currentWeapon.play("animationSpiralCorrosive"); 
              
              // Play the sound
              try {
                this.game.sound.play("spiral_projectile");          
              } catch (e) {}
              break;

            case "shard_blast":
              // Play the big one if this is the leviathan
              if (this.attackingShip.id === 68) {
                this.currentWeapon = this.projectileAquariusBig;   
                this.currentWeapon.play("animationAquariusProjectile"); 
                
                // Play the sound
                try {
                  this.game.sound.play("aquarius_rocket");          
                } catch (e) {}

              } else {
                this.currentWeapon = this.projectileAquarius;   
                this.currentWeapon.play("animationAquariusProjectile"); 
                
                // Play the sound
                try {
                  this.game.sound.play("aquarius_rocket");          
                } catch (e) {}
              }
              break;
            case "shard_missile":
              this.currentWeapon = this.missileFire;   
              this.currentWeapon.play("animationAquariusMissile"); 
              
              // Play the sound
              try {
                this.game.sound.play("nuclear_missile");          
              } catch (e) {}
              break;

            case "shard_wave":
              this.currentWeapon = this.projectileFirewave;   
              this.currentWeapon.play("animationAquariusCorrosive"); 
              
              // Play the sound
              try {
                this.game.sound.play("aquarius_rocket");          
              } catch (e) {}
              break;

            case "dark_laser":
              // Laser
              this.currentWeapon = this.projectileAquarius;   
              this.currentWeapon.play("animationDarkMatterProjectile"); 
              
              // Play the sound
              try {
                this.game.sound.play("dark_laser");          
              } catch (e) {}              
              break;
            case "dark_missile":
              this.currentWeapon = this.missileFire;   
              this.currentWeapon.play("animationDarkMatterMissile"); 
              
              // Play the sound
              try {
                this.game.sound.play("nuclear_missile");          
              } catch (e) {}
              break;

            case "dark_wave":
              this.currentWeapon = this.projectileFirewave;   
              this.currentWeapon.play("animationDarkMatterCorrosive"); 
              
              // Play the sound
              try {
                this.game.sound.play("dark_corrosive");          
              } catch (e) {}
              break;
              
          }
        }
    }

    // Make sure we are actually firing something
    if (this.currentWeapon) {
      // Set its starting point
      this.currentWeapon.x = this.attackingSprite.x;
      this.currentWeapon.y = this.attackingSprite.y;
      
      // Rotate the weapon to target the ship
      this.currentWeapon.rotation = this.attackingSprite.rotation;
      if (!this.playerTurn) {
        this.currentWeapon.rotation += 3.14159;
      }
     
      // Tween it
      this.scene.tweens.add(
        {
          targets: this.currentWeapon,
          x: this.defendingSprite.x, 
          y: this.defendingSprite.y,				
          ease: 'linear',
          duration: 500, 
          delay: 0,
          onComplete: () => this.weaponHit()  
        }
      );      
    } else {
      // Skip to next move
      this.fireComplete();
    }
  }

  weaponHit() {  
    // Special case for the "reflect" defensive shield for now
    //  Also make sure to catch the reflect edge case when it turns into a swarm
    if (this.defendingShip.special_name === 'reflect' || 
      (this.defendingShip.initialSpecialName === 'reflect' && this.defendingShip.showSwarm)) {

      // Don't play a hit animation

      // Trigger the special shields
      this.defendingSprite.triggerSpecial(this.defendingShip.initialSpecialName);

      // Fire the weapon BACK at the shooter

      // Set its starting point
      this.currentWeapon.x = this.defendingSprite.x;
      this.currentWeapon.y = this.defendingSprite.y;
      
      // Rotate the weapon to target the ship
      if (this.attackingShip.isPlayerShip) {
        this.currentWeapon.rotation = this.attackingSprite.rotation + 3.1416;        
      } else {        
        this.currentWeapon.rotation = this.attackingSprite.rotation + 6.2832;        
      }

      // Tween it
      this.scene.tweens.add(
        {
          targets: this.currentWeapon,
          x: this.attackingSprite.x, 
          y: this.attackingSprite.y,				
          ease: 'linear',
          duration: 500, 
          delay: 0,
          onComplete: () => this.weaponHitReflect()  
        }
      ); 
      
      // Play the sound
      try {
        this.game.sound.play("andromeda_shields");
      } catch (e) {}
      
      // Don't process anything else
      return;

    }

    // Hack to make it work for photon reducer
    let specialName = this.attackingShip.special_name;
    if (this.attackingShip.special_name ==="shield_debuff" && !this.attackingShip.showShieldDrain) {
      specialName = "";
    }

    // Play the "hit" animation for the current weapon
    //   Unless it missed!
    if (!this.defendingShip.showCloaking || this.defendingShip.isCloaked) {   
      switch (specialName) {
        case "emp_pulse":      
          // No hit animation, but flash the ship red
          this.defendingSprite.flashRed();
          break;
        case "tracking":
          // Apply "marked" to the ship
          this.defendingSprite.applyMarked();
          break;
        case "shield_debuff":
          // No hit animation, but flash the ship yellow        
          this.defendingSprite.flashYellow();        
          break;     
        case "repair":
          // Show the healing
          this.nanobotsHealing.x = this.defendingSprite.x;
          this.nanobotsHealing.y = this.defendingSprite.y;
          this.nanobotsHealing.alpha = 1;
          this.nanobotsHealing.play("animationNanobotsHealing");    
          break;
        default:        
          switch (this.attackingShip.weapon_name) {
            case "laser": 
            case "shard_blast":               
              this.hitLaser.x = this.defendingSprite.x;
              this.hitLaser.y = this.defendingSprite.y;
              this.hitLaser.alpha = 1;
              this.hitLaser.play("animationLaserSplat");         
              break;
            case "mass_driver":
              this.hitMassDriver.x = this.defendingSprite.x;
              this.hitMassDriver.y = this.defendingSprite.y;
              this.hitMassDriver.alpha = 1;
              this.hitMassDriver.play("animationMassDriverSplat"); 
              break;
            case "plasma":           
              this.hitLaser.x = this.defendingSprite.x;
              this.hitLaser.y = this.defendingSprite.y;
              this.hitLaser.alpha = 1;
              this.hitLaser.play("animationPlasmaSplat");         
              break;
            case "dark_laser":           
              this.hitLaser.x = this.defendingSprite.x;
              this.hitLaser.y = this.defendingSprite.y;
              this.hitLaser.alpha = 1;
              this.hitLaser.play("animationDarkMatterSplat");         
              break;
            case "firewave":
            case "shard_wave":   
            case "dark_wave":
              if (this.defendingShip.hitShields) {
                // If it hits shields it kinda fizzles
                this.hitLaser.x = this.defendingSprite.x;
                this.hitLaser.y = this.defendingSprite.y;
                this.hitLaser.alpha = 1;
                this.hitLaser.play("animationFirewaveSplat"); 
              } else {
                // If it hits armor it "burns"
                this.hitLaser.x = this.defendingSprite.x;
                this.hitLaser.y = this.defendingSprite.y;
                this.hitLaser.alpha = 1;
                this.hitLaser.play("animationFirewaveBurn"); 
              }        
              break;
            case "ball_lightning":                
              this.hitLaser.x = this.defendingSprite.x;
              this.hitLaser.y = this.defendingSprite.y;
              this.hitLaser.alpha = 1;
              this.hitLaser.play("animationSpiralUpgrade1");         
              break;
            case "nuclear_missile":
            case "stinger":
            case "fire_missile":
            case "shock_missile":
            case "shard_missile":
            case "dark_missile":
              // Play a small explosion
              this.explosionSmall.x = this.defendingSprite.x;
              this.explosionSmall.y = this.defendingSprite.y;
              this.explosionSmall.alpha = 1;
              this.explosionSmall.play("animationExplosionSmall");      
              break;
          }

          // Show the shield if needed
          if (this.defendingShip.hitShields) {
            // Only if the shields are not hardened
            if (this.defendingShip.showHardenedShields) {
              this.defendingSprite.triggerSpecial("hardened_shields");
            } else {
              this.defendingSprite.triggerShields();
            }
          } else {
            // Small explosion
            // Special sound for firewave hitting armor
            if (this.attackingShip.weapon_name === 'firewave') {
              try {
                this.game.sound.play("flamewave_hit");
              } catch (e) {}
            } else {
              try {
                this.game.sound.play("explode_small");
              } catch (e) {}
            }
          }

          // Trigger special if needed
          if (this.defendingShip.showMissileShield) {
            this.defendingSprite.triggerSpecial("missile_shield");
          }       
      }
      
      // Special case for weapons that "bounce"

    }  
      
    // Remove the sprite
    if (this.currentWeapon) {
      this.currentWeapon.x = -400;
      this.currentWeapon.y = 0;
      this.currentWeapon = null;
    }    

    // Fire complete!
    this.fireComplete();
  }

  weaponHitReflect() {          
    switch (this.attackingShip.weapon_name) {
      case "laser":                    
        this.hitLaser.x = this.attackingSprite.x;
        this.hitLaser.y = this.attackingSprite.y;
        this.hitLaser.alpha = 1;
        this.hitLaser.play("animationLaserSplat");         
        break;
      case "mass_driver":
        this.hitMassDriver.x = this.attackingSprite.x;
        this.hitMassDriver.y = this.attackingSprite.y;
        this.hitMassDriver.alpha = 1;
        this.hitMassDriver.play("animationMassDriverSplat"); 
        break;
      case "plasma": 
        this.hitLaser.x = this.attackingSprite.x;
        this.hitLaser.y = this.attackingSprite.y;
        this.hitLaser.alpha = 1;
        this.hitLaser.play("animationPlasmaSplat");         
        break;
      case "firewave": 
        if (this.defendingShip.hitShields) {
          // If it hits shields it kinda fizzles
          this.hitLaser.x = this.attackingSprite.x;
          this.hitLaser.y = this.attackingSprite.y;
          this.hitLaser.alpha = 1;
          this.hitLaser.play("animationFirewaveSplat"); 
        } else {
          // If it hits armor it "burns"
          this.hitLaser.x = this.attackingSprite.x;
          this.hitLaser.y = this.attackingSprite.y;
          this.hitLaser.alpha = 1;
          this.hitLaser.play("animationFirewaveBurn"); 
        }        
        break;
      case "nuclear_missile":
      case "stinger":
      case "fire_missile":
        // Play a small explosion
        this.explosionSmall.x = this.attackingSprite.x;
        this.explosionSmall.y = this.attackingSprite.y;
        this.explosionSmall.alpha = 1;
        this.explosionSmall.play("animationExplosionSmall");      
        break;
    }

    // Show the shield if needed
    if (this.attackingShip.hitShields) {
      this.attackingSprite.triggerShields();
    } else {
      // Small explosion
      try {
        this.game.sound.play("explode_small");
      } catch (e) {}
    }
          
    // Remove the sprite
    this.currentWeapon.x = -400;
    this.currentWeapon.y = 0;
    this.currentWeapon = null;

    // Fire complete!
   this.fireComplete();
  }

  fireComplete() {
    // Special case:  if this is an AOE ship firing at multiple targets, keep going without going back to "neutral"
    //   - shield drain also hits multiple targets
    if ((this.attackingShip.special_name === 'aoe' || this.attackingShip.special_name === 'chain' || this.attackingShip.special_name === 'shield_debuff') && (this.battleStep + 1) < this.battleResult.battleSteps.length) {
      let nextAttacker = this.battleResult.battleSteps[this.battleStep+1].attackingShip; 
      if (nextAttacker.id === this.attackingShip.id) {        
        this.battleState = "start_next_step";            
      } else {
        // Ship has fired, rotate back    
        this.battleState = "ship_rotate_neutral_start";    
      }       
    } else {
      // Ship has fired, rotate back    
      this.battleState = "ship_rotate_neutral_start";    
    }

    // Update the ships stats
    this.attackingSprite.updateStatsDisplay(this.attackingShip);
    this.defendingSprite.updateStatsDisplay(this.defendingShip);
    
    // If the ship has been destroyed, remove it!
    if (this.defendingShip.currentArmor <= 0) {
      // Blow it up!
      this.explosionLarge.x = this.defendingSprite.x;
      this.explosionLarge.y = this.defendingSprite.y;   
      this.explosionLarge.alpha = 1;   
      this.explosionLarge.anims.play('animationExplosionLarge');         
      
      // Clear "marked"
      this.defendingSprite.clearMarked();

      // Tell the ship
      this.defendingSprite.startDestroy(); 
            
      // Large explosion
      try {
        this.game.sound.play("explode_big");
      } catch (e) {}
    } else if (this.attackingShip.currentArmor <= 0) {
      // In the case of a reflect, check if the ATTACKING ship blowed up
      this.explosionLarge.x = this.attackingSprite.x;
      this.explosionLarge.y = this.attackingSprite.y;   
      this.explosionLarge.alpha = 1;   
      this.explosionLarge.anims.play('animationExplosionLarge');         
      
      // Clear "marked"
      this.attackingSprite.clearMarked();

      // Tell the ship
      this.attackingSprite.startDestroy(); 
      
      // Large explosion
      try {
        this.game.sound.play("explode_big");
      } catch (e) {}    
    } else if (this.defendingShip.showSwarm) {      
      // We have "destroyed" a swarm ship, show the disintegration animation
      //  Note - also trigger this during a mass_swarm ressurection
      this.disintegrate.x = this.defendingSprite.x;
      this.disintegrate.y = this.defendingSprite.y;   
      this.disintegrate.alpha = 1;   
      this.disintegrate.anims.play('animationAndromedaDisintegration');  
      
      // Clear "marked"
      this.defendingSprite.clearMarked();
           
      // Tell the ship
      this.defendingSprite.startSwarm(); 

      // Play the swarm sound
      try {
        this.game.sound.play("andromeda_swarm");
      } catch (e) {}
    } else if (this.attackingShip.showSwarm) {
      

      // We have "destroyed" a swarm ship, show the disintegration animation
      this.disintegrateAttacker.x = this.attackingShip.x;
      this.disintegrateAttacker.y = this.attackingShip.y;   
      this.disintegrateAttacker.alpha = 1;   
      this.disintegrateAttacker.anims.play('animationAndromedaDisintegration');   
           
      // Clear "marked"
      this.attackingSprite.clearMarked();

      // Tell the ship
      this.attackingSprite.startSwarm(); 

      // Play the swarm sound
      try {
        this.game.sound.play("andromeda_swarm");
      } catch (e) {}
    } else if (this.defendingShip.showDecoy) {
      // We have "destroyed" a dark matter ship, show a poof + the decoy
      this.disintegrate.x = this.defendingSprite.x;
      this.disintegrate.y = this.defendingSprite.y;   
      this.disintegrate.alpha = 1;   
      this.disintegrate.anims.play('animationDarkMatterCloud');  
      
      // Clear "marked"
      this.defendingSprite.clearMarked();
           
      // Tell the ship
      this.defendingSprite.showDecoy(); 

      // Play the decoy sound
      try {
        this.game.sound.play("dark_cloaking");
      } catch (e) {}
    } else if (this.attackingShip.showDecoy) {      
      // We have "destroyed" a dark matter ship, show a poof + the decoy
      this.disintegrateAttacker.x = this.attackingShip.x;
      this.disintegrateAttacker.y = this.attackingShip.y;   
      this.disintegrateAttacker.alpha = 1;   
      this.disintegrateAttacker.anims.play('animationDarkMatterCloud');   
           
      // Clear "marked"
      this.attackingSprite.clearMarked();

      // Tell the ship
      this.attackingSprite.showDecoy(); 

      // Play the swarm sound
      try {
        this.game.sound.play("dark_cloaking");
      } catch (e) {}
    }
  }
  
  onAnimationComplete(animationName) {   
    // Fade it out
    this.scene.tweens.add(
      {
        targets: this[animationName],
        alpha: 0,				
        ease: 'linear',
        duration: 100, 
        delay: 0
      }
    ); 
  }

  findSpriteByIndex(bPlayer, ship) {
    let sprite = null;
    if (bPlayer) {
      for (let i = 0; i < this.battleResult.playerFleet.length; i++) {
        if (ship.id === this.battleResult.playerFleet[i].id) {
          sprite = this.playerShipSprites[i];
        }
      }
    } else {
      for (let i = 0; i < this.battleResult.enemyFleet.length; i++) {
        if (ship.id === this.battleResult.enemyFleet[i].id) {
          sprite = this.enemyShipSprites[i];
        }
      }
    }
    
    return sprite;
  }
  
  fastForward () {
    let stepFound = false;    
    let step = null;
    for (let i = this.battleStep; i < this.battleResult.battleSteps.length; i++) {
      if (this.battleResult.battleSteps[i].defendingShip.currentArmor === 0 || 
        this.battleResult.battleSteps[i].attackingShip.currentArmor === 0 ||
        this.battleResult.battleSteps[i].defendingShip.showSwarm ||
        this.battleResult.battleSteps[i].defendingShip.showDecoy ||
        this.battleResult.battleSteps[i].defendingShip.wasResurrected ||
        this.battleResult.battleSteps[i].attackingShip.slotChanged || 
        this.battleResult.battleSteps[i].attackingShip.showUpgrade ||
        this.battleResult.battleSteps[i].attackingShip.showShieldPair ||
        this.battleResult.battleSteps[i].attackingShip.endHardenedShields) {

        // Found it!

        // Update all the ships to the current state
        for (let j = this.battleStep; j < i; j++) {
          step = this.battleResult.battleSteps[j];
          this.instantUpdateShipStats(step.attackingShip);
          this.instantUpdateShipStats(step.defendingShip);
        }

        // Skip to this step
        this.battleStep = i;
        stepFound = true;
        break;
      }
    }
    
    // If no step found we are done
    if (stepFound === false) {
      this.battleStep = this.battleResult.battleSteps.length;              
    }
  }

  instantUpdateShipStats(ship) {
    // If it is a player ship
    if (ship.isPlayerShip) {
      for (let i = 0; i < this.playerShipSprites.length; i++) {        
        if (this.playerShipSprites[i].ship.id === ship.id) {
          // Update the stats on it
          this.playerShipSprites[i].updateStatsDisplay(ship);
        }        
      }
    } else {
      for (let i = 0; i < this.enemyShipSprites.length; i++) {        
        if (this.enemyShipSprites[i].ship.id === ship.id) {
          // Update the stats on it
          this.enemyShipSprites[i].updateStatsDisplay(ship);
        }        
      }                  
    }  
  }
}

export default BattleDisplay;