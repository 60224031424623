import { useDispatch } from 'react-redux';
import classes from './GeneralErrorModal.module.css';
import { clearGameError, setGeneralBusyMessage, setGeneralError } from '../../../store/actions/gameActions';
import {ReactComponent as CrossIcon} from '../../../assets/svg/cross.svg';
import { clearWaxError } from '../../../store/actions/waxActions';

interface GeneralErrorProps {
  message: string;
}

const GeneralErrorModal = ( { message }:GeneralErrorProps ) => {
  const dispatch = useDispatch();
    
  const clickOK = () => {
    // Hide this modal
    dispatch(setGeneralError("")); 

    // Also clear out any other basic errors
    dispatch(clearGameError());    
    dispatch(clearWaxError());
    dispatch(setGeneralBusyMessage(""));
  }

 return(
  <div className={classes['main-content']}>           
    <div className={classes['popup-content-image']}>         
      <div onClick={() => clickOK()} className={classes['cross-holder']}>        
        <CrossIcon className={classes['cross-icon']} />        
      </div>                       
      <div className={classes['popup-title']}>Error</div>                             
      <div className={classes['message-text']}>{message}</div>      
      <div className={classes['button-section']}>                   
        <div onClick={() => clickOK()} className={classes['return-button']}>OK</div>
      </div>
    </div>
  </div>
  );
};

export default GeneralErrorModal;