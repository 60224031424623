import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import classes from './LeftNav.module.css';
import profileImage from '../../../assets/images/general/icons/profile.png';
import ccImage from '../../../assets/images/game/App_Icon_v2.png';
import { updateMobileMode } from '../../../store/actions/gameActions';
import { GameState } from '../../../store/types/gameTypes';
import { getGameStateSelector } from '../../../store/selectors/gameSelectors';
import finderImage from '../../../assets/images/general/icons/crafting.png';
import historyImage from '../../../assets/images/general/icons/history.png';
import mwImage from '../../../assets/images/mw_logo.png';

import {ReactComponent as RadioUnCheckedIcon} from '../../../assets/svg/radio-unchecked.svg';
import {ReactComponent as RadioCheckedIcon} from '../../../assets/svg/radio-checked.svg';


const LeftNav = () => { 
  const location = useLocation();
  const dispatch = useDispatch();
    
  const gameState:GameState = useSelector(getGameStateSelector);

  let clicksEnabled:boolean = true;

  const clickMobileMode = (bMobile:boolean) => {
    if (clicksEnabled){
      clicksEnabled=false;

      dispatch(updateMobileMode(bMobile));      
    }
  }

  // Go to the community site
  const goCosmicClash = () => {
    window.open("https://cosmicclash.io/")
  };

  return (
    <div className={classes['left-nav']}>
      
      <div className={classes['player-block']}>
        <img alt="Profile" src={profileImage} className={classes['nav-icon']} />
        <div className={classes['player-info']}>
          <div className={classes['player-name']}>Guest</div>          
        </div>
      </div>

      <fieldset className={classes['mode-block']}>
          <legend className={classes['mode-title']}>Mode</legend>
          <div className={classes['mode-radio-group']}>
            <div className={classes['mode-radio-holder']} onClick={() => clickMobileMode(false)}>          
              {!gameState.mobileMode ?
                <RadioCheckedIcon className={classes['radio-icon']} />
              :
                <RadioUnCheckedIcon className={classes['radio-icon']} />
              }          
              <div className={classes['mode-radio-text']}>Desktop</div>
            </div>
            <div className={classes['mode-radio-holder']} onClick={() => clickMobileMode(true)}>
              {gameState.mobileMode ?
                <RadioCheckedIcon className={classes['radio-icon']} />
              :
                <RadioUnCheckedIcon className={classes['radio-icon']} />
              }
              <div className={classes['mode-radio-text']}>Mobile</div>
            </div>
          </div>
        </fieldset>   

                  
      <Link className={classes['link']} to="/" >
        <div className={classes['nav-block']}>
          <img alt="Home" src={historyImage} className={classes['nav-icon']} />
          <div className={location.pathname === '/' ? [classes['nav-title'],classes['nav-title-active']].join(' ') : classes['nav-title']}>
            Battle Stream
          </div>
        </div>               
      </Link>

      <Link className={classes['link']} to="/find-battle" >
        <div className={classes['nav-block']}>
          <img alt="Find" src={finderImage} className={classes['nav-icon']} />
          <div className={location.pathname === '/find-battle' ? [classes['nav-title'],classes['nav-title-active']].join(' ') : classes['nav-title']}>
            Battle Finder
          </div>
        </div>               
      </Link>

      <Link className={classes['link']} to="/mw-calculators" >
        <div className={classes['nav-block']}>
          <img alt="Calculators" src={mwImage} className={classes['nav-icon']} />
          <div className={location.pathname === '/mw-calculators' ? [classes['nav-title'],classes['nav-title-active']].join(' ') : classes['nav-title']}>
            Calculators
          </div>
        </div>               
      </Link>

      <fieldset className={classes['orbatroid-block']}>
        <legend className={classes['orbatroid-title']}>Cosmic Clash</legend>
        <div className={classes['orbatroid-radio-group']}>
          {/*
          <div className={classes['crafting-step']}>
            <div className={classes['orbatroid-group']}>
              <img alt="Tower Defense" src={prisonBreak} className={classes['orbatroid-icon']} />
              <div className={classes['orbatroid-text']}>Prison Break!</div>
            </div>            
          </div>
          <div className={classes['orbatroid-step']}>- New Event!</div>
          <div className={classes['orbatroid-step']}>- Free to Play</div>          
          <div className={classes['orbatroid-step']}>Starts in: <br />{formattedTimeLeft}</div>
          
          */}
          <div className={classes['orbatroid-step-title']}>Join the Cosmic Clash movement!</div>
          <div className={classes['orbatroid-step']}>- Tournaments!</div>
          <div className={classes['orbatroid-step']}>- Community Events!</div>
          <div className={classes['orbatroid-step']}>- Win big prizes!</div>
          
          <div onClick={() => goCosmicClash()} className={classes['nav-block-two']}> 
            <img alt="Community" src={ccImage} className={classes['community-icon']} />           
            <div className={classes['nav-title']}>Check it out!</div>
          </div>
        </div>
      </fieldset>
      
      {/*
      <div onClick={() => refresh()} className={classes['logout-block']}>
        <RefreshIcon className={classes['refresh-icon']} />        
        <div className={classes['nav-title']}>Synchronize</div>
      </div> 

      
      <div onClick={() => clickLogout()} className={classes['logout-block']}>
        <LogoutIcon className={classes['logout-icon']} />      
        <div className={classes['nav-title']}>Logout</div>
      </div>           
      */}
    </div>
  );
};

export default LeftNav;
