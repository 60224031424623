import { useDispatch, useSelector } from 'react-redux';
import { resetError } from '../../../store/actions/waxActions';
import { getUserSelector } from '../../../store/selectors/userSelectors';
import { WaxState } from '../../../store/types/waxTypes';
import {ReactComponent as AtomicIcon} from '../../../assets/svg/atomic.svg';
import classes from './MobileErrorModal.module.css';
import {ReactComponent as CrossIcon} from '../../../assets/svg/cross.svg';
import {ReactComponent as LogoutIcon} from '../../../assets/svg/exit.svg';
import { useHistory } from 'react-router-dom';
import { logout } from '../../../store/service/ualService';

const MobileErrorModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  
  const user:WaxState = useSelector(getUserSelector);
  
  const clickOK = () => {
    // Hide this modal
    dispatch(resetError()); 
  }

  const clickAtomicHub = () => {
    window.open("https://wax.atomichub.io/market?collection_name=cosmicclashx&order=desc&schema_name=starshipx&sort=created&symbol=WAX");
    dispatch(resetError()); 
  }

  const clickLogout = () => {
    // Call the logout function
    logout(history);
   }

  let output = null;
  switch (user.errorCode) {
    case 103: 
      output = 
      <div className={classes['main-content']}>           
        <div className={classes['popup-content-image']}>                                         
          <div className={classes['popup-title-small']}>No ships!</div>                             
          <div className={classes['message-text-top']}>You must own at least 5 unique starship NFTs from the cosmicclashx collection to play.</div>
          <div className={classes['message-text']}>Please purchase them on Atomic Hub to get started.</div>      
          <div className={classes['button-holder']}>
            <button className={classes['atomic-button']} onClick={() => clickAtomicHub()}>
              <div className={classes['atomic-text']}>AtomicHub</div>
              <AtomicIcon  className={classes['atomic-icon']} />
            </button>
            <button onClick={() => clickLogout()} className={classes['logout-block']}>              
              <div className={classes['logout-text']}>Logout</div>
              <LogoutIcon className={classes['logout-icon']} />      
            </button>
          </div>
        </div>
      </div>;     
    break;
    default:
      output = 
        <div className={classes['main-content']}>           
          <div className={classes['popup-content-image']}>         
            <div onClick={() => clickOK()} className={classes['cross-holder']}>        
              <CrossIcon className={classes['cross-icon']} />        
            </div>                       
            <div className={classes['popup-title']}>Error</div>                             
            <div className={classes['message-text']}>{user.error}</div>      
            <div className={classes['button-section']}>                   
              <div onClick={() => clickOK()} className={classes['return-button']}>OK</div>
            </div>
          </div>
        </div>;
    break;

  }


  return (
    <div className={classes['wrapper']}>
        <div className={classes['section']}>
           {output}             
        </div>  
    </div>                  
  );
};

export default MobileErrorModal;