import { useDispatch, useSelector } from 'react-redux';
import classes from './MobileTopNav.module.css';
import profileImage from '../../../assets/images/general/icons/profile.png';
import { useHistory } from 'react-router';
import { logout } from '../../../store/service/ualService';
import { setGeneralBusyMessage, updateMobileMode } from '../../../store/actions/gameActions';
import { GameState } from '../../../store/types/gameTypes';
import { getGameStateSelector } from '../../../store/selectors/gameSelectors';


interface MobileTopNavProps {  
  drawerToggleClicked: Function;  
}

const MobileTopNav = ( { drawerToggleClicked }:MobileTopNavProps ) => { 
  const dispatch = useDispatch();
  const history = useHistory();

  const gameState:GameState = useSelector(getGameStateSelector);

  let clicksEnabled:boolean = true;

  const refresh = () => {
    if (clicksEnabled){
      clicksEnabled=false;

      // Set a busy message
      dispatch(setGeneralBusyMessage("Synching data..."));
      
    }
  }

  // Log the user out
  const clickLogout = () => {    
    // Call the logout function
    logout(history);    
  }

   // Toggle mobile mode
   const toggleMobileMode = () => {    
    if (clicksEnabled){
      clicksEnabled=false;
      
      if (gameState.mobileMode) {
        dispatch(updateMobileMode(false));
      } else {
        dispatch(updateMobileMode(true));
      }
    }
  }


  return (
    <div className={classes['top-nav']}>
      
      <div className={classes['player-block']}>
        <img src={profileImage} className={classes['nav-icon']} />
        <div className={classes['player-info']}>
          <div className={classes['player-name-box']}>
            <div className={classes['player-name']}>Guest</div>     
            <div onClick={() => toggleMobileMode()} className={classes['game-mode-button']}>
              {gameState.mobileMode ? 'Mobile' : 'Desktop' }
            </div>                 
          </div>          
        </div>
      </div>
      
      <div className={classes['toggle']}>
        <div className={classes['toggle-lines']} onClick={() => drawerToggleClicked()}>
          <div></div>
          <div></div>          
        </div>        
      </div>         
    </div>
  );
};

export default MobileTopNav;
