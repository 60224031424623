import classes from './BattleShipDisplay.module.css';
import {ReactComponent as CrossIcon} from '../../../../assets/svg/clear.svg';
import { IShip } from '../../../../store/types/gameTypes';
import ReactTooltip from "react-tooltip";

interface BattleShipDisplayProps {
  ship:IShip;
  rowIndex:number;
  shipIndex:number;
}

const BattleShipDisplay = ( { ship, rowIndex, shipIndex }:BattleShipDisplayProps ) => {  
  
    
  let imgStyle = {};
  let image = "";  
  
  // Scale things up a bit
  let scale:number = ship.image_scale + +0.2;
  if (scale > 0.9) {
    scale = 0.9;
  }
  
  imgStyle = {
    "max-width": (scale*100) + '%'
  };

  image = ship.image;   
  
  if (ship.currentArmor === 0) {
    imgStyle = {
      "max-width": (scale*100) + '%',
      "filter": "grayscale(100%)" 
    };
  }
    
  return (
    <div data-tip data-for={'shipTip'+rowIndex+'_'+shipIndex+'_'+ship.isPlayerShip} className={classes['battle-step']}>
      {ship  ?
        <div className={classes['battle-side']}>
          <div className={[classes['ship-holder'],classes['ship-holder-'+ship.rarity]].join(' ')}>                
            <div  className={classes['middle-row']}>
              <img alt="Ship" style={imgStyle} src={image}></img>
            </div>
            {ship.currentArmor === 0 ? 
              <div className={classes['cross-holder']}>          
                <CrossIcon className={classes['cross-icon']} />          
              </div>
              : null
            }
            <div className={[classes['level-holder'],classes['level-holder-'+ship.rarity]].join(' ')}>{ship.level}</div>
          </div>          
        </div>
      : 
        <div  className={classes['empty-block']}>&nbsp;</div> 
      }     
      <ReactTooltip backgroundColor="rgba(113,7,116,1)" arrowColor="rgba(113,7,116,1)" className={classes['tip-text']} id={'shipTip'+rowIndex+'_'+shipIndex+'_'+ship.isPlayerShip} place="top" effect="solid">
        {ship.name} LVL {ship.level}
      </ReactTooltip> 
    </div>
  );
};

export default BattleShipDisplay;
