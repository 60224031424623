import { useDispatch, useSelector } from "react-redux";
import classes from './MobileShipDetail.module.css';
import { getGameStateSelector } from "../../../store/selectors/gameSelectors";
import { GameState } from "../../../store/types/gameTypes";
import { clearShipDetails } from "../../../store/actions/gameActions";
import {ReactComponent as CrossIcon} from '../../../assets/svg/cross.svg';
import ReactTooltip from "react-tooltip";

import fglToken from '../../../assets/images/general/fgl-token.png';
import shipIcon from '../../../assets/images/general/icons/ship.png';
import levelIcon from '../../../assets/images/general/icons/level.png';
import rarityIcon from '../../../assets/images/general/icons/rarity.png';
import projectileIcon from '../../../assets/images/general/icons/weapon-projectile.png';
import missileIcon from '../../../assets/images/general/icons/weapon-missile.png';
import armorIcon from '../../../assets/images/general/icons/armor.png';
import shieldsIcon from '../../../assets/images/general/icons/shields.png';
import specialIcon from '../../../assets/images/general/icons/special.png';

const MobileShipDetail = () => {
  const dispatch = useDispatch();
  const gameState:GameState = useSelector(getGameStateSelector);
  
  // Close this popup
  const closeShipDetail = () => {
    dispatch(clearShipDetails());
  }

  if (gameState.shipDetails && gameState.shipDetails.name) {        
    let weaponImage = projectileIcon;
    let weaponTipText = "Common beam weapon";
    switch (gameState.shipDetails.weapon_name) {
      case "mass_driver":
        weaponImage = projectileIcon;
        weaponTipText = "A midrange projectile weapon";
        break;
      case "plasma":
        weaponImage = projectileIcon;
        weaponTipText = "Projectile weapon of the bug ships!";
        break;
      case "nuclear_missile":
        weaponImage = missileIcon;
        weaponTipText = "Basic missiles, ignores shields";
        break;
      case "nuclear_missile":
        weaponImage = missileIcon;
        weaponTipText = "Basic missiles, ignores shields";
        break;
      case "stinger":
        weaponImage = missileIcon;
        weaponTipText = "Advanced missiles, ignores shields";
        break;
        
    }

    let specialText = "N/A";
    switch (gameState.shipDetails.special_name) {
      case "shield_battery":
        specialText = "Shield Increase";
        break;
      case "repair":
        specialText = "Ship Repair";
        break;
      case "missile_magnet":
        specialText = "Missile Attract";
        break;
      case "emp_pulse":
        specialText = "Disable Ability";
        break;
      case "fires_twice":
        specialText = "Fires Twice";
        break;
      case "cooldown":
        specialText = "Fires every other round";
        break;
      case "spit_debuff":
        specialText = "Reduces the enemies weapon power";
        break;
      case "reflect":
        specialText = "Reflects the attack back at the attacker";
        break;
      case "mass_swarm":
        specialText = "Fills the board with swarms each time it attacks";
        break;
      
    }
  
    return (
      <div onClick={() => closeShipDetail()} className={classes['wrapper']}>
        <div className={classes['popup-content-image']}>
          <div className={classes['popup-top-row']}>
            <div className={classes['text-collected']}>{gameState.shipDetails.count} / {gameState.shipDetails.max}</div>
            <div className={classes['cross-holder']}>              
              <CrossIcon className={classes['cross-icon']} />              
            </div> 
                      
            <div className={classes['left-column']}>                          
              <div  className={classes['ship-image']}>
                <img className={classes['ship-image']} src={gameState.shipDetails.image}></img>
              </div>                                                                             
            </div>
            <div className={classes['right-side']}>
              <div className={classes['stats-block']}>
                <div className={classes['stat-row']}>
                  <img data-tip data-for="nameTip" className={classes['stat-image']} src={shipIcon} />
                  <div className={classes['stat-details']}>
                    <div className={classes['stat-base-text']}>{gameState.shipDetails.name.toUpperCase()}</div>                      
                  </div>   
                  <ReactTooltip backgroundColor="rgba(113,7,116,0.8)" arrowColor="rgba(113,7,116,0.8)" className={classes['tip-text']} id="nameTip" place="top" effect="solid">
                    The name of the ship
                  </ReactTooltip>               
                </div>
                <div className={classes['stat-row']}>
                  <img data-tip data-for="levelTip" className={classes['stat-image']} src={levelIcon} />
                  <div className={classes['stat-details']}>
                    <div className={classes['stat-base-text']}>Level {gameState.shipDetails.level}</div>                      
                  </div>   
                  <ReactTooltip backgroundColor="rgba(113,7,116,0.8)" arrowColor="rgba(113,7,116,0.8)" className={classes['tip-text']} id="levelTip" place="top" effect="solid">
                    The level of the ship
                  </ReactTooltip>               
                </div>
                <div className={classes['stat-row']}>
                  <img data-tip data-for="rarityTip" className={classes['stat-image']} src={rarityIcon} />
                  <div className={classes['stat-details']}>
                    <div className={[classes['ship-rarity'],classes['ship-rarity-'+gameState.shipDetails.rarity]].join(' ')}>{gameState.shipDetails.rarity.toUpperCase()}</div>
                  </div>  
                  <ReactTooltip backgroundColor="rgba(113,7,116,0.8)" arrowColor="rgba(113,7,116,0.8)" className={classes['tip-text']} id="rarityTip" place="top" effect="solid">
                    The rarity of the ship
                  </ReactTooltip>                
                </div>
              
                <div className={classes['stat-row']}>
                  <img data-tip data-for="weaponTip" className={classes['stat-image']} src={weaponImage} />
                  <div className={classes['stat-details']}>
                    <div className={classes['stat-base-text']}>{gameState.shipDetails.base_attack}</div>  
                    {gameState.shipDetails.level > 1 ? 
                      <div className={classes['stat-upgrade-text']}>+{gameState.shipDetails.upgrade.weapon*(gameState.shipDetails.level-1)}</div>  
                      : 
                      null
                    }
                    {gameState.shipDetails.currentPowerup === 1 ? 
                      <div className={classes['stat-powerup-text']}>+{Math.ceil(gameState.shipDetails.upgrade.weapon/2)}</div>  
                      : 
                      null
                    }
                  </div>
                  <ReactTooltip backgroundColor="rgba(113,7,116,0.8)" arrowColor="rgba(113,7,116,0.8)" className={classes['tip-text']} id="weaponTip" place="top" effect="solid">
                    {weaponTipText}
                  </ReactTooltip>
                </div>
                <div className={classes['stat-row']}>
                  <img                  
                    data-tip data-for="shieldTip"     
                    className={classes['stat-image']} 
                    src={shieldsIcon} />
                  <div className={classes['stat-details']}>
                    <div className={classes['stat-base-text']}>{gameState.shipDetails.base_shields}</div>  
                    {gameState.shipDetails.level > 1 ? 
                      <div className={classes['stat-upgrade-text']}>+{gameState.shipDetails.upgrade.shields*(gameState.shipDetails.level-1)}</div>  
                      : 
                      null
                    }
                    {gameState.shipDetails.currentPowerup === 2 ? 
                      <div className={classes['stat-powerup-text']}>+{Math.ceil(gameState.shipDetails.upgrade.shields/2)}</div>  
                      : 
                      null
                    }
                  </div>
                  <ReactTooltip backgroundColor="rgba(113,7,116,0.8)" arrowColor="rgba(113,7,116,0.8)" className={classes['tip-text']} id="shieldTip" place="top" effect="solid">
                    Shields block any beam or projectile weapons
                  </ReactTooltip>
                </div>
                <div className={classes['stat-row']}>
                  <img 
                    data-tip data-for="armorTip"   
                    className={classes['stat-image']} 
                    src={armorIcon} />
                  <div className={classes['stat-details']}>
                    <div className={classes['stat-base-text']}>{gameState.shipDetails.base_armor}</div>  
                    {gameState.shipDetails.level > 1 ? 
                      <div className={classes['stat-upgrade-text']}>+{gameState.shipDetails.upgrade.armor*(gameState.shipDetails.level-1)}</div>  
                      : 
                      null
                    }
                    {gameState.shipDetails.currentPowerup === 3 ? 
                      <div className={classes['stat-powerup-text']}>+{Math.ceil(gameState.shipDetails.upgrade.armor/2)}</div>  
                      : 
                      null
                    }                    
                  </div>
                  <ReactTooltip  backgroundColor="rgba(113,7,116,0.8)" arrowColor="rgba(113,7,116,0.8)" className={classes['tip-text']} id="armorTip" place="top" effect="solid">
                    Armor absorbs missile and non-shielded beam or projectile attacks
                  </ReactTooltip>
                </div>
                <div className={classes['stat-row']}>
                  <img 
                    data-tip data-for="specialTip"   
                    className={classes['stat-image']} 
                    src={specialIcon} />                 
                  <div className={classes['stat-details']}>{specialText}</div>
                  <ReactTooltip backgroundColor="rgba(113,7,116,0.8)" arrowColor="rgba(113,7,116,0.8)" className={classes['tip-text']} id="specialTip" place="top" effect="solid">
                    Some ships have a special ability!
                  </ReactTooltip>
                </div>    
                <div className={classes['stat-row']}>
                  <img 
                    src={fglToken}
                    data-tip data-for="costTip"                     
                    className={classes['stat-image']} />
                  <div className={classes['stat-details']}>{gameState.shipDetails.deploymentCost.toFixed(4)}</div>
                  <ReactTooltip backgroundColor="rgba(113,7,116,0.8)" arrowColor="rgba(113,7,116,0.8)" className={classes['tip-text']} id="costTip" place="top" effect="solid">
                    Cost to deploy this ship for battle
                  </ReactTooltip>
                </div>                          
              </div>    
            </div>        
          </div>  
          <div className={classes['description-text']}>{gameState.shipDetails.description}</div>        
        </div>        
      </div>  
    );
  } else {
    return (<div>Loading...</div>);
  }
  
};

export default MobileShipDetail;