import classes from './BattleLogStep.module.css';
import armorImage from '../../assets/images/general/icons/armor.png';
import shieldsImage from '../../assets/images/general/icons/shields.png';
import {ReactComponent as CrossIcon} from '../../assets/svg/clear.svg';
import { IBattleStep, IShip } from '../../store/types/gameTypes';

interface BattleLogStepProps {
  step: IBattleStep,
  deathsOnly: boolean
}

const BattleLogStep = ( {step, deathsOnly}:BattleLogStepProps ) => {  
  let leftShip:IShip | null;
  let rightShip:IShip | null;
  if (step.attackingShip.isPlayerShip) {
    leftShip = step.attackingShip;
    rightShip = step.defendingShip;  

    // Clear the idling flag on the defender
    rightShip.shipIdled = false;
  } else {
    leftShip = step.defendingShip;
    rightShip = step.attackingShip;

    // Clear the idling flag on the defender
    leftShip.shipIdled = false;
  }

  // If left idled, right ship should be blank, and vice versa
  if (leftShip.shipIdled) {
    rightShip = null;
  } else if (rightShip.shipIdled) {
    leftShip = null;
  } else if (leftShip.slotChanged) {
    rightShip = null;
  } else if (rightShip.slotChanged) {
    leftShip = null;
  } else {
    // If it was friendly targeting, show the target instead
    if (leftShip.isPlayerShip === rightShip.isPlayerShip) {
      if (leftShip.isPlayerShip) {
        // It is the players turn, so show the defender on the left, and nothing right
        leftShip = step.defendingShip;
        rightShip = null;
      } else {
        // It is the opponent turn, show defender on right + nothing left
        rightShip = step.defendingShip;
        leftShip = null;
      }
    }
  }
    
  let leftImgStyle = {};
  let leftImage = "";  
  if (leftShip && leftShip.id > 0) {
    // Scale things up a bit
    let scale:number = leftShip.image_scale + +0.2;
    if (scale > 0.9) {
      scale = 0.9;
    }
    
    leftImgStyle = {
      "max-width": (scale*100) + '%'
    };

    leftImage = leftShip.image;   
    
    if (leftShip.currentArmor === 0) {
      leftImgStyle = {
        "max-width": (scale*100) + '%',
        "filter": "grayscale(100%)" 
      };
    }
  }

  let rightImgStyle = {};
  let rightImage = "";  
  if (rightShip && rightShip.id > 0) {
    // Scale things up a bit
    let scale:number = rightShip.image_scale + +0.2;
    if (scale > 0.9) {
      scale = 0.9;
    }
    
    rightImgStyle = {
      "max-width": (scale*100) + '%'
    };

    rightImage = rightShip.image;   
    
    if (rightShip.currentArmor === 0) {
      rightImgStyle = {
        "max-width": (scale*100) + '%',
        "filter": "grayscale(100%)" 
      };
    }
  }

  return (
    <div className={classes['battle-step']}>
      {leftShip  ?
        <div className={classes['battle-side']}>
          <div className={[classes['ship-holder'],classes['ship-holder-'+leftShip.rarity]].join(' ')}>                
            <div  className={classes['middle-row']}>
              <img alt="Ship" style={leftImgStyle} src={leftImage}></img>
            </div>
            {leftShip.currentArmor === 0 ? 
              <div className={classes['cross-holder']}>          
                <CrossIcon className={classes['cross-icon']} />          
              </div>
              : null
            }
          </div> 
          <div className={classes['side-row']}>
            <div className={classes['stat-row']}>
              <img alt="Shield" className={classes['stat-image']} src={shieldsImage} />
              <div className={classes['stat-base-text']}>{leftShip.currentShields}</div>  
            </div>  
            <div className={classes['stat-row']}>
              <img alt="Armor" className={classes['stat-image']} src={armorImage} />
              <div className={classes['stat-base-text']}>{leftShip.currentArmor}</div>  
            </div> 
          </div>  
        </div>
      : 
        <div  className={classes['empty-block']}>&nbsp;</div> 
      }
      <div className={classes['battle-description']}>{step.description}</div>
      {rightShip  ?
        <div className={classes['battle-side']}>
          <div className={classes['side-row']}>
            <div className={classes['stat-row']}>
              <img alt="Shields" className={classes['stat-image']} src={shieldsImage} />
              <div className={classes['stat-base-text']}>{rightShip.currentShields}</div>  
            </div>  
            <div className={classes['stat-row']}>
              <img alt="Armor" className={classes['stat-image']} src={armorImage} />
              <div className={classes['stat-base-text']}>{rightShip.currentArmor}</div>  
            </div>                       
          </div>
          <div className={[classes['ship-holder'],classes['ship-holder-'+rightShip.rarity]].join(' ')}>                 
            <div  className={classes['middle-row']}>
              <img alt="Ship" style={rightImgStyle} src={rightImage}></img>
            </div>      
            {rightShip.currentArmor === 0 ? 
              <div className={classes['cross-holder']}>          
                <CrossIcon className={classes['cross-icon']} />          
              </div>
              : null
            }                      
          </div>             
        </div> 
      : 
        <div  className={classes['empty-block']}>&nbsp;</div>       
      }
    </div>
  );
};

export default BattleLogStep;
