import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import classes from './MobileSideDrawer.module.css';
import {ReactComponent as CrossIcon} from '../../../assets/svg/cross.svg';

import Backdrop from '../../General/Misc/Backdrop';

interface MobileSideDrawerProps {  
  isOpen: boolean;
  closeFunction: Function;
}

const MobileSideDrawer = ( { isOpen, closeFunction }:MobileSideDrawerProps ) => {
  let attachedClasses = [classes['side-drawer'], classes['close']];
  if (isOpen) {
      attachedClasses = [classes['side-drawer'], classes['open']];
  }

  return (
    <>            
      <Backdrop show={isOpen} clicked={closeFunction}/>
      <div className={attachedClasses.join(' ')}>                          
        <div onClick={() => closeFunction()} className={classes['top-row']}>
          <CrossIcon className={classes['close-button']} />
        </div>
        <div className={classes['content-column']}>                   
          <Link className={classes['link']} to={{pathname: '/'}}>
            <div onClick={() => closeFunction()} className={classes['button-group']}>              
              <div className={classes['button-label']} >Battle Stream</div>
            </div>
          </Link>
          <Link className={classes['link']} to={{pathname: '/find-battle'}}>
            <div onClick={() => closeFunction()} className={classes['button-group']}>              
              <div className={classes['button-label']} >Battle Finder</div>
            </div>
          </Link>                
        </div>
      </div>
    </>
  );
};

export default MobileSideDrawer;