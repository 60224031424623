import {
  GET_BATTLE_STREAM_SUCCESS,
  GET_BATTLE_STREAM_FAILURE,
  VIEW_SHIP_DETAILS,
  CLEAR_SHIP_DETAILS,
  SET_GENERAL_ERROR,
  SET_GENERAL_BUSY_MESSAGE,
  SET_WAX_ERROR,  
  SET_BUSY_AUTO_LOGIN,
  CLEAR_GAME_ERROR,
  WATCH_BATTLE,
  CLEAR_BATTLE,
  SHOW_BATTLE_RESULTS,
  HIDE_BATTLE_RESULTS,
  SHOW_BATTLE_LOG,
  HIDE_BATTLE_LOG,
  UPDATE_MOBILE_MODE,
  FIND_BATTLES_FAILURE,
  FIND_BATTLES_SUCCESS,
  FIND_BATTLES
} from "../actions/actionTypes";

import { GameState, GameActions } from "../types/gameTypes";

const initialState: GameState = {
  battleStream: [],
  error: "",
  fatalError: "",
  shipDetails: null,
  generalError: "",
  generalBusyMessage: "",    
  buildVersion: "0.04",
  busyAutoLogin: false ,
  battleResult: null,
  mobileMode: false,
  showBattleResultsPopup: false,
  showBattleLog: false,
  foundBattles: []
};

export default (state = initialState, action: GameActions) => {
  switch (action.type) {
    case GET_BATTLE_STREAM_SUCCESS:
      return {
        ...state,
        battleStream: action.payload.battles
      };
    case GET_BATTLE_STREAM_FAILURE:
    case FIND_BATTLES_FAILURE:
      return {
        ...state,        
        error: action.payload.error,
        fatalError: action.payload.fatalError,
      };
    case FIND_BATTLES:
      return {
        ...state,
        foundBattles: []
      };
    case FIND_BATTLES_SUCCESS:
      return {
        ...state,
        foundBattles: action.payload.battles
      };
    case WATCH_BATTLE:
      return {
        ...state,        
        battleResult: action.payload
      };
    case CLEAR_BATTLE:
      return {
        ...state,        
        battleResult: null
      };
    case SHOW_BATTLE_RESULTS:
      return {
        ...state,
        showBattleResultsPopup: true
      };
    case HIDE_BATTLE_RESULTS:
      return {
        ...state,
        showBattleResultsPopup: false
      };
    case SHOW_BATTLE_LOG:
      return {
        ...state,
        showBattleLog: true
      };
    case HIDE_BATTLE_LOG:
        return {
          ...state,
          showBattleLog: false
        };
    case VIEW_SHIP_DETAILS:
      return {
        ...state,
        shipDetails: action.payload
      };
    case CLEAR_SHIP_DETAILS:
      return {
        ...state,
        shipDetails: null
      };
    
    case SET_GENERAL_ERROR:
      return {
        ...state,
        generalError: action.payload
      };
    case SET_GENERAL_BUSY_MESSAGE:
      return {
        ...state,
        generalBusyMessage: action.payload
      };    
    case SET_BUSY_AUTO_LOGIN:
      return {
        ...state,
        busyAutoLogin: action.payload
      };
    case SET_WAX_ERROR:
      return {
        ...state,
        waxErrorMessage: action.payload
      };    
    case CLEAR_GAME_ERROR:
      return {
        ...state,
        error: ""
      };
    case UPDATE_MOBILE_MODE:
      return {
        ...state,
        mobileMode: action.payload
      };        
    default:
      return {
        ...state,
      };
  }
};