import { Route, Switch, withRouter } from 'react-router-dom';
import ShowBattle from './components/ShowBattle/ShowBattle';
import Layout from "./hoc/Layout";
import { GameState } from './store/types/gameTypes';
import { useSelector } from 'react-redux';
import { getGameStateSelector } from './store/selectors/gameSelectors';
import BattleStream from './components/BattleStream/BattleStream';

import MobileBattleHistory from './components/Mobile/MobileBattleHistory/MobileBattleHistory';
import FindBattle from './components/FindBattle/FindBattle';
import MobileFindBattle from './components/Mobile/MobileFindBattle/MobileFindBattle';
import MWCalculators from './components/MWCalculators/MWCalculators';

const App = () => {  
  const gameState:GameState = useSelector(getGameStateSelector);


  return (
    <Layout>        
      {gameState.mobileMode === false ?
        <Switch>                                        
          <Route exact path="/show-battle" component={ShowBattle} /> 
          <Route path="/find-battle/:id" component={FindBattle} /> 
          <Route exact path="/find-battle" component={FindBattle} /> 
          <Route exact path="/mw-calculators" component={MWCalculators} /> 
          <Route path="/" component={BattleStream} />
        </Switch>        
      :
        <Switch>                                                  
          <Route exact path="/show-battle" component={ShowBattle} />  
          <Route path="/find-battle/:id" component={MobileFindBattle} /> 
          <Route exact path="/find-battle" component={MobileFindBattle} />  
          <Route exact path="/" component={MobileBattleHistory} /> 
        </Switch>
      }
    </Layout>
  );
}

export default withRouter(App);