import Phaser from "phaser";
import { useDispatch, useSelector } from "react-redux";
import classes from './ShowBattle.module.css';

import { useEffect, useState } from "react";
import Ship from "./Ship";

import BattleDisplay from "./BattleDisplay";
import { showBattleResults } from "../../store/actions/gameActions";
import { viewShipDetails } from "../../store/actions/gameActions";
import { getGameStateSelector } from "../../store/selectors/gameSelectors";

const ShowBattlePhaser = () => {  
  const dispatch = useDispatch();
  
  const [phaserInitialized, setPhaserInitialized] = useState(false);  
  const gameState = useSelector(getGameStateSelector);  
    
  let game;
  let playerShips;
  let enemyShips;
  let battleWasStarted = false;
  let battleCompleted = false;  
      
  // Instantiate Phaser when the component loads
  useEffect(() => {   
    if (!phaserInitialized && gameState.battleResult) {
      setPhaserInitialized(true);

      // temp code
      if (game) {      
        game.destroy(true, false);
      }
      
      const config = {
        type: Phaser.AUTO,      
        scale: {
          mode: Phaser.Scale.RESIZE,
          parent: 'phaser-root',
          width: '100%',
          height: '100%'
        },
        physics: {
          default: 'arcade',
          arcade: {
            debug: false,
          },
        },
        width: 800,
        height: 600,
        scene: {
          preload: preload,
          create: create,
          update: update
        },
        transparent: true,
        autoResize: true
      };
      
      game = new Phaser.Game(config);

      function preload() {    
        this.annihilatorFound = false;

        // Preload the ships
        for (let i = 0; i < gameState.battleResult.playerFleet.length; i++) {           
          if (gameState.battleResult.playerFleet[i].spritesheet_frames > 1) {
            this.load.multiatlas(
              'playerShip' + gameState.battleResult.playerFleet[i].id, 
              gameState.battleResult.playerFleet[i].spritesheet_json, 
              'https://cdn.cosmicclash.io/ships/galaxy' + gameState.battleResult.playerFleet[i].galaxy + '/');  
              
            // If it is from the spiral galaxy, we need the upgraded animation too
            // - Medusa ship also uses this
            // - Dark Matter as well, for decoys
            if (gameState.battleResult.playerFleet[i].galaxy === 4 ||
                gameState.battleResult.playerFleet[i].galaxy === 6 ||
                gameState.battleResult.playerFleet[i].id === 67) {

              this.load.multiatlas(
                'playerShipUpgraded' + gameState.battleResult.playerFleet[i].id, 
                gameState.battleResult.playerFleet[i].upgraded_spritesheet_json, 
                'https://cdn.cosmicclash.io/ships/galaxy' + gameState.battleResult.playerFleet[i].galaxy + '/');  
            }
        
          } else {
            this.load.image("playerShip" + gameState.battleResult.playerFleet[i].id, gameState.battleResult.playerFleet[i].image);
          }

          // Any annihilators?
          if (gameState.battleResult.playerFleet[i].id === 77) {
            this.annihilatorFound = true;
          }
        }
        for (let i = 0; i < gameState.battleResult.enemyFleet.length; i++) {          
          if (gameState.battleResult.enemyFleet[i].spritesheet_frames > 1) {
            this.load.multiatlas(
              'enemyShip' + gameState.battleResult.enemyFleet[i].id, 
              gameState.battleResult.enemyFleet[i].spritesheet_json, 
              'https://cdn.cosmicclash.io/ships/galaxy' + gameState.battleResult.enemyFleet[i].galaxy + '/'); 
              
            // If it is from the spiral galaxy, we need the upgraded animation too
            // - Medusa ship also uses this
            // - Dark Matter as well, for decoys
            if (gameState.battleResult.enemyFleet[i].galaxy === 4 ||
                gameState.battleResult.enemyFleet[i].galaxy === 6 ||
                gameState.battleResult.enemyFleet[i].id === 67) {

              this.load.multiatlas(
                'enemyShipUpgraded' + gameState.battleResult.enemyFleet[i].id, 
                gameState.battleResult.enemyFleet[i].upgraded_spritesheet_json, 
                'https://cdn.cosmicclash.io/ships/galaxy' + gameState.battleResult.enemyFleet[i].galaxy + '/');  
            }            

          } else {
            this.load.image("enemyShip" + gameState.battleResult.enemyFleet[i].id, gameState.battleResult.enemyFleet[i].image);
          }

          // Any annihilators?
          if (gameState.battleResult.playerFleet[i].id === 77) {
            this.annihilatorFound = true;
          }
        }  
        
        // Load the pack of annihilator images if needed
        if (this.annihilatorFound) {
          this.load.multiatlas(
            'annihilator_advanced', 
            'https://cdn.cosmicclash.io/ships/galaxy6/annihilator_advanced.json', 
            'https://cdn.cosmicclash.io/ships/galaxy6/');
        }

        // Load the swarm engine animation
        this.load.multiatlas(
          'swarm_animation', 
          'https://cdn.cosmicclash.io/ships/galaxy2/swarm_engine.json', 
          'https://cdn.cosmicclash.io/ships/galaxy2/');  

        // Load the ship displays
        this.load.multiatlas(
          'ship_display_common', 
          'https://cdn.cosmicclash.io/general/ship_display_common.json', 
          'https://cdn.cosmicclash.io/general/');  
        this.load.multiatlas(
          'ship_display_rare', 
          'https://cdn.cosmicclash.io/general/ship_display_rare.json', 
          'https://cdn.cosmicclash.io/general/');
        this.load.multiatlas(
          'ship_display_epic', 
          'https://cdn.cosmicclash.io/general/ship_display_epic.json', 
          'https://cdn.cosmicclash.io/general/');
        this.load.multiatlas(
          'ship_display_legendary', 
          'https://cdn.cosmicclash.io/general/ship_display_legendary.json', 
          'https://cdn.cosmicclash.io/general/');

        // Ship levels        
        this.load.multiatlas(
          'ship_display_levels', 
          'https://cdn.cosmicclash.io/general/ship_levels.json', 
          'https://cdn.cosmicclash.io/general/');  
                  
        // Load the weapons + explosions
        this.load.multiatlas(
          'general_battle_animations', 
          'https://cdn.cosmicclash.io/general/battle_general_animations_v7.json', 
          'https://cdn.cosmicclash.io/general/');  

        // Load in andromeda shields, weapons + explosions
        this.load.multiatlas(
          'andromeda_battle_animations', 
          'https://cdn.cosmicclash.io/general/andromeda_battle_anims_2.json', 
          'https://cdn.cosmicclash.io/general/'); 

        // Load in andromeda epic powers
        this.load.multiatlas(
          'andromeda_battle_animations_3', 
          'https://cdn.cosmicclash.io/general/andromeda_battle_anims_3.json', 
          'https://cdn.cosmicclash.io/general/'); 

        // Load the win / lose animations
        this.load.multiatlas(
          'win_lose_animations', 
          'https://cdn.cosmicclash.io/general/win_lose_animations.json', 
          'https://cdn.cosmicclash.io/general/'); 

        // Load the battle controls
        this.load.multiatlas(
          'battle_controls', 
          'https://cdn.cosmicclash.io/general/battle_controls_v3.json', 
          'https://cdn.cosmicclash.io/general/');  

        // Load in myrmidon animations
        this.load.multiatlas(
          'myrmidon_battle_animations', 
          'https://cdn.cosmicclash.io/general/myrmidon_animations.json', 
          'https://cdn.cosmicclash.io/general/'); 

        // Load in myrmidon animations part 2
        this.load.multiatlas(
          'phalanx_animation', 
          'https://cdn.cosmicclash.io/general/phalanx_animation.json', 
          'https://cdn.cosmicclash.io/general/');

        // Load in spiral animations
        this.load.multiatlas(
          'spiral_battle_animations', 
          'https://cdn.cosmicclash.io/general/spiral_animations.json', 
          'https://cdn.cosmicclash.io/general/'); 

        // Load in aquarius animations
        this.load.multiatlas(
          'aquarius_battle_animations', 
          'https://cdn.cosmicclash.io/general/aquarius_battle_animations.json', 
          'https://cdn.cosmicclash.io/general/'); 

        // Load in dark matter animations
        this.load.multiatlas(
          'dark_matter_battle_animations', 
          'https://cdn.cosmicclash.io/general/dark_matter_animations.json', 
          'https://cdn.cosmicclash.io/general/'); 

        // Load in all our sounds
        let effectsVolume = 0.1;
        let musicVolume = 0.1;
        this.load.audio('arrive', ['https://cdn.cosmicclash.io/audio/space-ship.mp3', 'https://cdn.cosmicclash.io/audio/space-ship.ogg'], {volume: effectsVolume});
        this.load.audio('laser', ['https://cdn.cosmicclash.io/audio/sound_spark_laser.mp3', 'https://cdn.cosmicclash.io/audio/sound_spark_laser.ogg'], {volume: effectsVolume});
        this.load.audio('mass_driver', ['https://cdn.cosmicclash.io/audio/mass_driver_shoot.mp3', 'https://cdn.cosmicclash.io/audio/mass_driver_shoot.ogg'], {volume: effectsVolume});
        this.load.audio('nuclear_missile', ['https://cdn.cosmicclash.io/audio/rocket-launch-missile.mp3', 'https://cdn.cosmicclash.io/audio/rocket-launch-missile.ogg'], {volume: effectsVolume});
        this.load.audio('shield', ['https://cdn.cosmicclash.io/audio/sci-fi-force-field-impact-13.mp3', 'https://cdn.cosmicclash.io/audio/sci-fi-force-field-impact-13.ogg'], {volume: effectsVolume});        
        this.load.audio('emp', ['https://cdn.cosmicclash.io/audio/006142245-electricity-shock-spark-15.mp3', 'https://cdn.cosmicclash.io/audio/006142245-electricity-shock-spark-15.ogg'], {volume: effectsVolume});

        this.load.audio('magnet', ['https://cdn.cosmicclash.io/audio/nanobot_3.mp3', 'https://cdn.cosmicclash.io/audio/nanobot_3.ogg'], {volume: effectsVolume});
        this.load.audio('repair', ['https://cdn.cosmicclash.io/audio/nanobot_2.mp3', 'https://cdn.cosmicclash.io/audio/nanobot_2.ogg'], {volume: effectsVolume});

        this.load.audio('explode_big', ['https://cdn.cosmicclash.io/audio/explosion-space-01.mp3', 'https://cdn.cosmicclash.io/audio/explosion-space-01.ogg'], {volume: effectsVolume});
        this.load.audio('explode_small', ['https://cdn.cosmicclash.io/audio/explosion-space-02.mp3', 'https://cdn.cosmicclash.io/audio/explosion-space-02.ogg'], {volume: effectsVolume});

        this.load.audio('win', ['https://cdn.cosmicclash.io/audio/035929054-short-brass-winner-fanfare-000.mp3', 'https://cdn.cosmicclash.io/audio/035929054-short-brass-winner-fanfare-000.ogg'], {volume: effectsVolume});
        this.load.audio('lose', ['https://cdn.cosmicclash.io/audio/042686236-game-over-7-horns.mp3', 'https://cdn.cosmicclash.io/audio/042686236-game-over-7-horns.ogg'], {volume: effectsVolume});

        this.load.audio('music', ['https://cdn.cosmicclash.io/audio/music_battle_1.mp3', 'https://cdn.cosmicclash.io/audio/music_battle_2.ogg'], {loop:true, volume: musicVolume});

        // Andromeda sounds
        this.load.audio('andromeda_projectile', ['https://cdn.cosmicclash.io/audio/andromeda_projectile.mp3', 'https://cdn.cosmicclash.io/audio/andromeda_projectile.ogg'], {volume: effectsVolume});
        this.load.audio('andromeda_swarm', ['https://cdn.cosmicclash.io/audio/andromeda_swarm.mp3', 'https://cdn.cosmicclash.io/audio/andromeda_swarm.ogg'], {volume: effectsVolume});
        this.load.audio('andromeda_missile', ['https://cdn.cosmicclash.io/audio/andromeda_missile.mp3', 'https://cdn.cosmicclash.io/audio/andromeda_missile.ogg'], {volume: effectsVolume});
        this.load.audio('andromeda_shields', ['https://cdn.cosmicclash.io/audio/andromeda_shields.mp3', 'https://cdn.cosmicclash.io/audio/andromeda_shields.ogg'], {volume: effectsVolume});

        // Myrmidon sounds
        this.load.audio('flamewave_attack', ['https://cdn.cosmicclash.io/audio/flamewave_attack.mp3', 'https://cdn.cosmicclash.io/audio/flamewave_attack.ogg'], {volume: effectsVolume});
        this.load.audio('flamewave_hit', ['https://cdn.cosmicclash.io/audio/flamewave_hit.mp3', 'https://cdn.cosmicclash.io/audio/flamewave_hit.ogg'], {volume: effectsVolume});
        this.load.audio('fire_protection_shield', ['https://cdn.cosmicclash.io/audio/fire_protection_shield.mp3', 'https://cdn.cosmicclash.io/audio/fire_protection_shield.ogg'], {volume: effectsVolume});
        this.load.audio('myrmidon_shield', ['https://cdn.cosmicclash.io/audio/myrmidon_shield.mp3', 'https://cdn.cosmicclash.io/audio/myrmidon_shield.ogg'], {volume: effectsVolume});

        // Spiral sounds
        this.load.audio('spiral_projectile', ['https://cdn.cosmicclash.io/audio/nanobot_1.mp3', 'https://cdn.cosmicclash.io/audio/nanobot_1.ogg'], {volume: effectsVolume});
        this.load.audio('super_lightning', ['https://cdn.cosmicclash.io/audio/super_lightning.mp3', 'https://cdn.cosmicclash.io/audio/super_lightning.ogg'], {volume: effectsVolume});

        // Aquarius sounds
        this.load.audio('aquarius_water', ['https://cdn.cosmicclash.io/audio/space_water.mp3', 'https://cdn.cosmicclash.io/audio/space_water.ogg'], {volume: effectsVolume});
        this.load.audio('aquarius_rocket', ['https://cdn.cosmicclash.io/audio/rocket_water.mp3', 'https://cdn.cosmicclash.io/audio/rocket_water.ogg'], {volume: effectsVolume});
        this.load.audio('aquarius_explosion', ['https://cdn.cosmicclash.io/audio/explosion_water.mp3', 'https://cdn.cosmicclash.io/audio/explosion_water.ogg'], {volume: effectsVolume});

        // Dark Matter sounds
        this.load.audio('dark_shields', ['https://cdn.cosmicclash.io/audio/dark_shields.mp3', 'https://cdn.cosmicclash.io/audio/dark_shields.ogg'], {volume: effectsVolume});
        this.load.audio('dark_corrosive', ['https://cdn.cosmicclash.io/audio/dark_corrosive.mp3', 'https://cdn.cosmicclash.io/audio/dark_corrosive.ogg'], {volume: effectsVolume});
        this.load.audio('dark_cloaking', ['https://cdn.cosmicclash.io/audio/dark_cloaking.mp3', 'https://cdn.cosmicclash.io/audio/dark_cloaking.ogg'], {volume: effectsVolume});
        this.load.audio('dark_epic_transform', ['https://cdn.cosmicclash.io/audio/dark_epic_transform.mp3', 'https://cdn.cosmicclash.io/audio/dark_epic_transform.ogg'], {volume: effectsVolume});
        this.load.audio('dark_laser', ['https://cdn.cosmicclash.io/audio/dark_laser.mp3', 'https://cdn.cosmicclash.io/audio/dark_laser.ogg'], {volume: effectsVolume});

        // Avatars        
        if (gameState.player && gameState.player.avatarId && gameState.player.avatarId > 0) {
          this.load.image('playerAvatar', 'https://cdn.cosmicclash.io/avatars/avatar_' + gameState.player.avatarId + '.png');
        }
        if (gameState.battleResult.enemyAvatarId && gameState.battleResult.enemyAvatarId > 0) {
          this.load.image('opponentAvatar', 'https://cdn.cosmicclash.io/avatars/avatar_' + gameState.battleResult.enemyAvatarId + '.png');
        }
      }
            
      function create() {                       
        // Show the ships across the full width
        this.shipWidth = this.game.canvas.clientWidth / 6;

        // Make sure the ship width isn't more than 1/5 of the the screen height
        if (this.shipWidth > this.game.canvas.clientHeight / 5) {
          this.shipWidth = this.game.canvas.clientHeight / 5;
        }

        let shipSpacer = (this.game.canvas.clientWidth - (this.shipWidth * 5)) / 5;        
        let baseScale = this.shipWidth / 512;
        this.baseScale = baseScale;
        let textSize = Math.floor(48 * baseScale);

        //console.log("baseScale", baseScale);

        let timeScale = 0.5;

        // Control variables
        this.isFastForward = false;
        this.isPaused = false;
        this.isMuted = false;
        
        // Add player name texts
        let fontSize = Math.floor(baseScale * 160);
        let floatingFontSize = Math.floor(fontSize / 1.5);
        if (fontSize > 48) {
          fontSize = 48;
          floatingFontSize = 32;
        }
        fontSize = fontSize + "px";   
        floatingFontSize = floatingFontSize + "px";
                
        if (gameState.mobileMode) {
          fontSize = "20px";
          floatingFontSize = "16px";
        }

        // Render an opponent avatar
        let avatarSpacer = 0;
        if (gameState.battleResult.enemyAvatarId && gameState.battleResult.enemyAvatarId > 0) {          
          this.opponentAvatarImage = this.add.image(baseScale*20, 0, 'opponentAvatar').setOrigin(0,0);
          this.opponentAvatarImage.scale = baseScale / 3;
          avatarSpacer = baseScale*180;
        }
                        
        this.opponentText = this.add.text(baseScale*20 + avatarSpacer, baseScale*20, gameState.battleResult.enemyName, { fontFamily: 'EquinoxBold', fontSize: fontSize, fontWeight: 800 });
        this.opponentText.alpha = 0;
        
        // Render a player avatar?
        avatarSpacer = 0;
        if (gameState.player && gameState.player.avatarId && gameState.player.avatarId > 0) {          
          this.playerAvatarImage = this.add.image(this.game.canvas.clientWidth - baseScale*20, this.game.canvas.clientHeight - this.opponentText.height - (baseScale*40), 'playerAvatar').setOrigin(1,0.5);
          this.playerAvatarImage.scale = baseScale / 3;
          avatarSpacer = baseScale*240;
        }
        
        this.playerText = this.add.text(this.game.canvas.clientWidth - baseScale*20 - avatarSpacer, this.game.canvas.clientHeight - this.opponentText.height, gameState.battleResult.playerName, 
          { align: 'right', fontFamily: 'EquinoxBold', fontSize: fontSize, fontWeight: 800, boundsAlignH: 'right'}).setOrigin(1);              
        this.playerText.alpha = 0;
                
        // Add all the ships
        playerShips = [];
        let tempX = 0;
        let shieldSprite;
        let levelSprite;
        let armorSprite;
        let shieldText;
        let armorText;
        let shieldDisplaySprite;
        let upgradeSprite;
        let specialSprite;
        let markedSprite;
        let pairedSprite;
        let floatingShieldText;
        let floatingArmorText;
        for (let i = 0; i < gameState.battleResult.playerFleet.length; i++) {
          // Create a shield + an armor sprite for this ship
          shieldSprite = this.add.sprite(-200, 0, "ship_display_" + gameState.battleResult.playerFleet[i].rarity, 
            "shields_" + gameState.battleResult.playerFleet[i].rarity + "_10.png");          
          shieldSprite.scale = baseScale * 0.6;          
          armorSprite = this.add.sprite(-200, 0, "ship_display_" + gameState.battleResult.playerFleet[i].rarity,  
            "armor_" + gameState.battleResult.playerFleet[i].rarity + "_10.png");          
          armorSprite.scale = baseScale * 0.6;        
          
          // Create a level sprite for this ship
          levelSprite = this.add.sprite(-200, 0, "ship_display_levels",  
           gameState.battleResult.playerFleet[i].rarity + "_" + gameState.battleResult.playerFleet[i].level + ".png");          
           levelSprite.scale = baseScale * 0.2; 

          // Create texts as well
          shieldText = this.add.text(-200, 0, '000', { fontFamily: 'EquinoxBold', fontSize: textSize + 'px' });
          armorText = this.add.text(-200, 0, '000', { fontFamily: 'EquinoxBold', fontSize: textSize + 'px' });

          // Create a shield display sprite
          shieldDisplaySprite = this.add.sprite(-200, 0, "shieldFriendly" + gameState.battleResult.playerFleet[i].id); 

          // Create a pairing sprite
          pairedSprite = this.add.sprite(-200, 0, "pairedShieldPlayer" + gameState.battleResult.playerFleet[i].id);   
          
          // Create an upgrade sprite
          upgradeSprite = this.add.sprite(-200, 0, "upgradeFriendly" + gameState.battleResult.playerFleet[i].id); 
          
          // Set the starting frame                    
          let frame = null;
          if (gameState.battleResult.playerFleet[i].spritesheet_frames > 1) {
            frame = '1.png';            
          }      
                              
          tempX = (.5 * this.shipWidth) + (i * this.shipWidth) + ((i+1) * shipSpacer);
                              
          let ship = new Ship(
            {
              scene: this,
              key: "playerShip" + gameState.battleResult.playerFleet[i].id,
              x: tempX,
              y: this.game.canvas.clientHeight + this.shipWidth,
              frame: frame
            }, 
            this.shipWidth, shipSpacer,
            gameState.battleResult.playerFleet[i], baseScale, false, 
            this.game.canvas.clientHeight-(this.game.canvas.clientHeight/3.5),
            shieldSprite, armorSprite, levelSprite, shieldText, armorText,shieldDisplaySprite, timeScale, this.game, upgradeSprite, pairedSprite
          );

          // Create a special sprite if needed                      
          specialSprite = this.add.sprite(-200, 0, "specialSpritePlayer" + gameState.battleResult.playerFleet[i].id);   
          ship.addSpecial(specialSprite);

          // Create a marked sprite
          markedSprite = this.add.sprite(-200, 0, "markedFriendly" + gameState.battleResult.playerFleet[i].id); 
          ship.addMarked(markedSprite);

          // Add some floating text for damage
          floatingShieldText = this.add.text(0,0, "", { fontFamily: 'EquinoxBold', fontSize: floatingFontSize, fontWeight: 800, strokeThickness: 2 });
          floatingArmorText = this.add.text(0,0, "", { fontFamily: 'EquinoxBold', fontSize: floatingFontSize, fontWeight: 800, strokeThickness: 2 });
          ship.addFloatingTexts(floatingShieldText, floatingArmorText);

          // Let it be clicked to show info
          ship.setInteractive({ useHandCursor: true })
          ship.on('pointerdown', () => showShipInfo(gameState.battleResult.playerFleet[i]));

          playerShips.push(ship);
        }

        enemyShips = [];
        for (let i = 0; i < gameState.battleResult.enemyFleet.length; i++) {
          // Create a shield + an armor sprite for this ship
          shieldSprite = this.add.sprite(-200, 0, "ship_display_" + gameState.battleResult.enemyFleet[i].rarity, 
            "shields_" + gameState.battleResult.enemyFleet[i].rarity + "_10.png");          
          shieldSprite.scale = baseScale * 0.6;          
          armorSprite = this.add.sprite(-200, 0, "ship_display_" + gameState.battleResult.enemyFleet[i].rarity,  
            "armor_" + gameState.battleResult.enemyFleet[i].rarity + "_10.png");          
          armorSprite.scale = baseScale * 0.6; 

          // Create a level sprite for this ship
          levelSprite = this.add.sprite(-200, 0, "ship_display_levels",  
           gameState.battleResult.enemyFleet[i].rarity + "_" + gameState.battleResult.enemyFleet[i].level + ".png");          
          levelSprite.scale = baseScale * 0.2; 

          // Create texts as well
          shieldText = this.add.text(-200, 0, '000', { fontFamily: 'EquinoxBold', fontSize: textSize + 'px' });
          armorText = this.add.text(-200, 0, '000', { fontFamily: 'EquinoxBold', fontSize: textSize + 'px' });
          
          // Create a shield display sprite
          shieldDisplaySprite = this.add.sprite(-200, 0, "shieldOpponent" + gameState.battleResult.enemyFleet[i].id);   
          
          // Create a pairing sprite
          pairedSprite = this.add.sprite(-200, 0, "pairedShieldOpponent" + gameState.battleResult.enemyFleet[i].id);   
                                    
          // Create a upgrade sprite
          upgradeSprite = this.add.sprite(-200, 0, "upgradeOpponent" + gameState.battleResult.enemyFleet[i].id);  
          
          let frame = null;
          if (gameState.battleResult.enemyFleet[i].spritesheet_frames > 1) {
            frame = '1.png';            
          }    
          
          tempX = (.5 * this.shipWidth) + (i * this.shipWidth) + ((i+1) * shipSpacer);
          
          let ship = new Ship(
            {
              scene: this,
              key: "enemyShip" + gameState.battleResult.enemyFleet[i].id,
              x: tempX,
              y: 0 - this.shipWidth,
              frame: frame
            }, 
            this.shipWidth, shipSpacer,
            gameState.battleResult.enemyFleet[i], baseScale, true, this.game.canvas.clientHeight/3.5,
            shieldSprite, armorSprite, levelSprite, shieldText, armorText, shieldDisplaySprite, timeScale, this.game, upgradeSprite, pairedSprite
          );

          // Create a special sprite if needed                      
          specialSprite = this.add.sprite(-200, 0, "specialSpriteEnemy" + gameState.battleResult.enemyFleet[i].id);   
          ship.addSpecial(specialSprite);

          // Create a marked sprite
          markedSprite = this.add.sprite(-200, 0, "markedOpponent" + gameState.battleResult.enemyFleet[i].id);  
          ship.addMarked(markedSprite);

          // Add some floating text for damage
          floatingShieldText = this.add.text(0,0, "", { fontFamily: 'EquinoxBold', fontSize: floatingFontSize, fontWeight: 800, strokeThickness: 2 });
          floatingArmorText = this.add.text(0,0, "", { fontFamily: 'EquinoxBold', fontSize: floatingFontSize, fontWeight: 800, strokeThickness: 2 });
          ship.addFloatingTexts(floatingShieldText, floatingArmorText);

          // Let it be clicked to show info
          ship.setInteractive({ useHandCursor: true })
          ship.on('pointerdown', () => showShipInfo(gameState.battleResult.enemyFleet[i]));

          enemyShips.push(ship);
        }

        // Move ships to their proper slot
        for (let i = 0; i < playerShips.length; i++) {          
          playerShips[i].initSlot();   
        }
        for (let i = 0; i < enemyShips.length; i++) {          
          enemyShips[i].initSlot();  
        }

        // Build the missile + explosion animations
        this.anims.create({
          key: 'animationNuclearMissile',
          frames: this.anims.generateFrameNames("general_battle_animations", {
            start: 1, end: 6, zeroPad: 2,
            prefix: 'nuclear_missile_', suffix: '.png'
          }),
          frameRate: 10,
          repeat: -1
        });
        this.anims.create({
          key: 'animationNuclearMissileCluster',
          frames: this.anims.generateFrameNames("general_battle_animations", {
            start: 1, end: 6, zeroPad: 2,
            prefix: 'nuclear_missile_cluster_', suffix: '.png'
          }),
          frameRate: 10,
          repeat: -1
        });
        this.anims.create({
          key: 'animationLaser',
          frames: this.anims.generateFrameNames("general_battle_animations", {
            start: 1, end: 12, zeroPad: 2,
            prefix: 'laser_bolt_', suffix: '.png'
          }),
          frameRate: 10,
          repeat: -1
        });
        this.anims.create({
          key: 'animationMassDriver',
          frames: this.anims.generateFrameNames("general_battle_animations", {
            start: 1, end: 12, zeroPad: 2,
            prefix: 'mass_driver_', suffix: '.png'
          }),
          frameRate: 10,
          repeat: -1
        });
        this.anims.create({
          key: 'animationExplosionSmall',
          frames: this.anims.generateFrameNames("general_battle_animations", {
            start: 1, end: 10, zeroPad: 2,
            prefix: 'explosion_small_', suffix: '.png'
          }),
          frameRate: 20,
          repeat: 0
        });
        this.anims.create({
          key: 'animationExplosionLarge',
          frames: this.anims.generateFrameNames("general_battle_animations", {
            start: 1, end: 10, zeroPad: 2,
            prefix: 'explosion_large_', suffix: '.png'
          }),
          frameRate: 10,
          repeat: 0
        });
        this.anims.create({
          key: 'animationDisableOrb',
          frames: this.anims.generateFrameNames("general_battle_animations", {
            start: 1, end: 21, zeroPad: 2,
            prefix: 'disable_orb_', suffix: '.png'
          }),
          frameRate: 10,
          repeat: 0
        });
        this.anims.create({
          key: 'animationNanobots',
          frames: this.anims.generateFrameNames("general_battle_animations", {
            start: 1, end: 12, zeroPad: 2,
            prefix: 'nano_bots_', suffix: '.png'
          }),
          frameRate: 10,
          repeat: 0
        });
        this.anims.create({
          key: 'animationNanobotsHealing',
          frames: this.anims.generateFrameNames("general_battle_animations", {
            start: 1, end: 10, zeroPad: 2,
            prefix: 'nano_healing_', suffix: '.png'
          }),
          frameRate: 10,
          repeat: 0
        });
        this.anims.create({
          key: 'animationLaserSplat',
          frames: this.anims.generateFrameNames("general_battle_animations", {
            start: 1, end: 8, zeroPad: 2,
            prefix: 'laser_bolt_splat_', suffix: '.png'
          }),
          frameRate: 20,
          repeat: 0
        });
        this.anims.create({
          key: 'animationMassDriverSplat',
          frames: this.anims.generateFrameNames("general_battle_animations", {
            start: 1, end: 9, zeroPad: 2,
            prefix: 'cannon_ball_splat_', suffix: '.png'
          }),
          frameRate: 20,
          repeat: 0
        });       
        this.anims.create({
          key: 'animationShieldFadeIn',
          frames: this.anims.generateFrameNames("general_battle_animations", {
            start: 1, end: 15, zeroPad: 2,
            prefix: 'shield_fade_in_', suffix: '.png'
          }),
          frameRate: 30,
          repeat: 0
        });
        this.anims.create({
          key: 'animationShieldFadeOut',
          frames: this.anims.generateFrameNames("general_battle_animations", {
            start: 1, end: 15, zeroPad: 2,
            prefix: 'shield_fade_out_', suffix: '.png'
          }),
          frameRate: 30,
          repeat: 0
        });
        this.anims.create({
          key: 'animationMissileMagnet',
          frames: this.anims.generateFrameNames("general_battle_animations", {
            start: 1, end: 18, zeroPad: 2,
            prefix: 'magnetic_wave_', suffix: '.png'
          }),
          frameRate: 20,
          repeat: 0
        });
        
        this.anims.create({
          key: 'animationWin',
          frames: this.anims.generateFrameNames("win_lose_animations", {
            start: 1, end: 23, zeroPad: 2,
            prefix: 'win_', suffix: '.png'
          }),
          frameRate: 10,
          repeat: 0
        });
        this.anims.create({
          key: 'animationLose',
          frames: this.anims.generateFrameNames("win_lose_animations", {
            start: 1, end: 23, zeroPad: 2,
            prefix: 'lose_', suffix: '.png'
          }),
          frameRate: 10,
          repeat: 0
        });

        // Andromeda animations
        this.anims.create({
          key: 'animationAndromedaShields',
          frames: this.anims.generateFrameNames("andromeda_battle_animations", {
            start: 1, end: 30, zeroPad: 2,
            prefix: 'andromeda_shields_', suffix: '.png'
          }),
          frameRate: 30,
          repeat: 0
        });
        this.anims.create({
          key: 'animationPlasmaGun',
          frames: this.anims.generateFrameNames("andromeda_battle_animations", {
            start: 1, end: 12, zeroPad: 2,
            prefix: 'plasma_', suffix: '.png'
          }),
          frameRate: 10,
          repeat: -1
        });
        this.anims.create({
          key: 'animationStingerMissile',
          frames: this.anims.generateFrameNames("andromeda_battle_animations", {
            start: 1, end: 6, zeroPad: 2,
            prefix: 'stinger_missile_', suffix: '.png'
          }),
          frameRate: 10,
          repeat: -1
        });
        this.anims.create({
          key: 'animationAndromedaDisintegration',
          frames: this.anims.generateFrameNames("andromeda_battle_animations", {
            start: 1, end: 14, zeroPad: 2,
            prefix: 'disintegrate_', suffix: '.png'
          }),
          frameRate: 10,
          repeat: 0
        });
        this.anims.create({
          key: 'animationSwarmDeploy',
          frames: this.anims.generateFrameNames("andromeda_battle_animations", {
            start: 1, end: 20, zeroPad: 2,
            prefix: 'bug_swarm_', suffix: '.png'
          }),
          frameRate: 10,
          repeat: 0
        });
        this.anims.create({
          key: 'animationPlasmaSplat',
          frames: this.anims.generateFrameNames("andromeda_battle_animations", {
            start: 1, end: 9, zeroPad: 2,
            prefix: 'bolt_splat_', suffix: '.png'
          }),
          frameRate: 20,
          repeat: 0
        });
        this.anims.create({
          key: 'animationSwarmEngines',
          frames: this.anims.generateFrameNames("swarm_animation", {
            start: 1, end: 10, zeroPad: 0,
            prefix: '', suffix: '.png'
          }),
          frameRate: 10,
          repeat: -1
        });

        this.anims.create({
          key: 'animationSpit',
          frames: this.anims.generateFrameNames("andromeda_battle_animations_3", {
            start: 1, end: 10, zeroPad: 2,
            prefix: 'spit_bolt_', suffix: '.png'
          }),
          frameRate: 30,
          repeat: 0
        });
        this.anims.create({
          key: 'animationReflect',
          frames: this.anims.generateFrameNames("andromeda_battle_animations_3", {
            start: 1, end: 9, zeroPad: 2,
            prefix: 'reflect_', suffix: '.png'
          }),
          frameRate: 30,
          repeat: 0
        });

        // Myrmidon animations
        this.anims.create({
          key: 'animationMyrmidonShields',
          frames: this.anims.generateFrameNames("myrmidon_battle_animations", {
            start: 1, end: 11, zeroPad: 2,
            prefix: 'fire_shield_', suffix: '.png'
          }),
          frameRate: 30,
          repeat: 0
        });
        this.anims.create({
          key: 'animationFirewave',
          frames: this.anims.generateFrameNames("myrmidon_battle_animations", {
            start: 1, end: 11, zeroPad: 2,
            prefix: 'fire_wave_', suffix: '.png'
          }),
          frameRate: 30,
          repeat: -1
        });
        this.anims.create({
          key: 'animationFirewaveSplat',
          frames: this.anims.generateFrameNames("myrmidon_battle_animations", {
            start: 1, end: 9, zeroPad: 2,
            prefix: 'flame_explosion_', suffix: '.png'
          }),
          frameRate: 30,
          repeat: 0
        });
        this.anims.create({
          key: 'animationFirewaveBurn',
          frames: this.anims.generateFrameNames("myrmidon_battle_animations", {
            start: 1, end: 10, zeroPad: 2,
            prefix: 'burning_', suffix: '.png'
          }),
          frameRate: 30,
          repeat: 0
        });
        this.anims.create({
          key: 'animationFireMissile',
          frames: this.anims.generateFrameNames("myrmidon_battle_animations", {
            start: 1, end: 6, zeroPad: 2,
            prefix: 'fire_missiles_', suffix: '.png'
          }),
          frameRate: 10,
          repeat: -1
        });
        this.anims.create({
          key: 'animationPhalanxShield',
          frames: this.anims.generateFrameNames("phalanx_animation", {
            start: 1, end: 17, zeroPad: 2,
            prefix: 'phalanx_shield_', suffix: '.png'
          }),
          frameRate: 20,
          repeat: 0
        });
        this.anims.create({
          key: 'animationArmorUp',
          frames: this.anims.generateFrameNames("myrmidon_battle_animations", {
            start: 1, end: 13, zeroPad: 2,
            prefix: 'armor_up_', suffix: '.png'
          }),
          frameRate: 10,
          repeat: 0
        });

        // Spiral animations
        this.anims.create({
          key: 'animationSpiralShields',
          frames: this.anims.generateFrameNames("spiral_battle_animations", {
            start: 1, end: 12, zeroPad: 2,
            prefix: 'spiral_shield_', suffix: '.png'
          }),
          frameRate: 30,
          repeat: 0
        });
        this.anims.create({
          key: 'animationSpiralProjectile',
          frames: this.anims.generateFrameNames("spiral_battle_animations", {
            start: 1, end: 10, zeroPad: 2,
            prefix: 'spiral_weapon_', suffix: '.png'
          }),
          frameRate: 30,
          repeat: -1
        });
        this.anims.create({
          key: 'animationSpiralCorrosive',
          frames: this.anims.generateFrameNames("spiral_battle_animations", {
            start: 1, end: 9, zeroPad: 2,
            prefix: 'spiral_corrosive_', suffix: '.png'
          }),
          frameRate: 30,
          repeat: -1
        });
        this.anims.create({
          key: 'animationSpiralMissile',
          frames: this.anims.generateFrameNames("spiral_battle_animations", {
            start: 1, end: 6, zeroPad: 2,
            prefix: 'spiral_missile_', suffix: '.png'
          }),
          frameRate: 30,
          repeat: -1
        });
        this.anims.create({
          key: 'animationSpiralShieldDrain',
          frames: this.anims.generateFrameNames("spiral_battle_animations", {
            start: 1, end: 15, zeroPad: 2,
            prefix: 'spiral_shield_drain_', suffix: '.png'
          }),
          frameRate: 30,
          repeat: 0
        });
        this.anims.create({
          key: 'animationSpiralTargeted',
          frames: this.anims.generateFrameNames("spiral_battle_animations", {
            start: 1, end: 13, zeroPad: 2,
            prefix: 'spiral_target_', suffix: '.png'
          }),
          frameRate: 5,
          repeat: -1
        });
        this.anims.create({
          key: 'animationSpiralTargetBeam',
          frames: this.anims.generateFrameNames("spiral_battle_animations", {
            start: 1, end: 6, zeroPad: 2,
            prefix: 'spiral_target_beam_', suffix: '.png'
          }),
          frameRate: 30,
          repeat: -1
        });
        this.anims.create({
          key: 'animationSpiralUpgrade1',
          frames: this.anims.generateFrameNames("spiral_battle_animations", {
            start: 1, end: 9, zeroPad: 2,
            prefix: 'spiral_upgrade1_', suffix: '.png'
          }),
          frameRate: 30,
          repeat: 0
        });
        this.anims.create({
          key: 'animationSpiralUpgrade2',
          frames: this.anims.generateFrameNames("spiral_battle_animations", {
            start: 1, end: 9, zeroPad: 2,
            prefix: 'spiral_upgrade2_', suffix: '.png'
          }),
          frameRate: 30,
          repeat: 0
        });

        // Aquarius animations
        this.anims.create({
          key: 'animationAquariusShields',
          frames: this.anims.generateFrameNames("aquarius_battle_animations", {
            start: 1, end: 12, zeroPad: 2,
            prefix: 'shield_', suffix: '.png'
          }),
          frameRate: 30,
          repeat: 0
        });
        this.anims.create({
          key: 'animationAquariusProjectile',
          frames: this.anims.generateFrameNames("aquarius_battle_animations", {
            start: 1, end: 12, zeroPad: 2,
            prefix: 'weapon_', suffix: '.png'
          }),
          frameRate: 30,
          repeat: -1
        });
        this.anims.create({
          key: 'animationAquariusCorrosive',
          frames: this.anims.generateFrameNames("aquarius_battle_animations", {
            start: 1, end: 12, zeroPad: 2,
            prefix: 'wave_', suffix: '.png'
          }),
          frameRate: 30,
          repeat: -1
        });
        this.anims.create({
          key: 'animationAquariusMissile',
          frames: this.anims.generateFrameNames("aquarius_battle_animations", {
            start: 1, end: 6, zeroPad: 2,
            prefix: 'missile_', suffix: '.png'
          }),
          frameRate: 30,
          repeat: -1
        });
        this.anims.create({
          key: 'animationAquariusBarrier',
          frames: this.anims.generateFrameNames("aquarius_battle_animations", {
            start: 1, end: 22, zeroPad: 2,
            prefix: 'barrier_', suffix: '.png'
          }),
          frameRate: 30,
          repeat: 0
        });
        this.anims.create({
          key: 'animationAquariusShieldConnect',
          frames: this.anims.generateFrameNames("aquarius_battle_animations", {
            start: 1, end: 2, zeroPad: 2,
            prefix: 'shield_connect_', suffix: '.png'
          }),
          frameRate: 5,
          repeat: -1
        });
        this.anims.create({
          key: 'animationAquariusWaterGlobe',
          frames: this.anims.generateFrameNames("aquarius_battle_animations", {
            start: 1, end: 12, zeroPad: 2,
            prefix: 'water_globe_', suffix: '.png'
          }),
          frameRate: 30,
          repeat: -1
        });

        // Dark Matter animations
        this.anims.create({
          key: 'animationDarkMatterShields',
          frames: this.anims.generateFrameNames("dark_matter_battle_animations", {
            start: 1, end: 12, zeroPad: 2,
            prefix: 'DarkShield_', suffix: '.png'
          }),
          frameRate: 30,
          repeat: 0
        });
        this.anims.create({
          key: 'animationDarkMatterProjectile',
          frames: this.anims.generateFrameNames("dark_matter_battle_animations", {
            start: 1, end: 10, zeroPad: 2,
            prefix: 'DarkLaser_', suffix: '.png'
          }),
          frameRate: 30,
          repeat: -1
        });
        this.anims.create({
          key: 'animationDarkMatterCorrosive',
          frames: this.anims.generateFrameNames("dark_matter_battle_animations", {
            start: 1, end: 9, zeroPad: 2,
            prefix: 'DarkWave_', suffix: '.png'
          }),
          frameRate: 30,
          repeat: -1
        });
        this.anims.create({
          key: 'animationDarkMatterMissile',
          frames: this.anims.generateFrameNames("dark_matter_battle_animations", {
            start: 1, end: 6, zeroPad: 2,
            prefix: 'DarkMissile_', suffix: '.png'
          }),
          frameRate: 30,
          repeat: -1
        });
        this.anims.create({
          key: 'animationDarkMatterCloud',
          frames: this.anims.generateFrameNames("dark_matter_battle_animations", {
            start: 1, end: 12, zeroPad: 2,
            prefix: 'DarkCloud_', suffix: '.png'
          }),
          frameRate: 30,
          repeat: 0
        });
        this.anims.create({
          key: 'animationDarkMatterSplat',
          frames: this.anims.generateFrameNames("dark_matter_battle_animations", {
            start: 1, end: 9, zeroPad: 2,
            prefix: 'DarkSplat_', suffix: '.png'
          }),
          frameRate: 30,
          repeat: 0
        });

        // Annihilator animations        
        if (this.annihilatorFound) {
          this.anims.create({
            key: 'animationAnnihilatorEnginesCorrosive',
            frames: this.anims.generateFrameNames("annihilator_advanced", {
              start: 1, end: 12, zeroPad: 2,
              prefix: 'corrosive_', suffix: '.png'
            }),
            frameRate: 30,
            repeat: -1
          });
          this.anims.create({
            key: 'animationAnnihilatorEnginesProjectile',
            frames: this.anims.generateFrameNames("annihilator_advanced", {
              start: 1, end: 12, zeroPad: 2,
              prefix: 'projectile_', suffix: '.png'
            }),
            frameRate: 30,
            repeat: -1
          });                    
        }
        
        // Set some state variables
        this.allInPlace = false;
        this.startShips = false;

        // Initialize the battle displayer        
        this.battleDisplay = new BattleDisplay(this, this.game, playerShips, enemyShips, 
          gameState.battleResult, baseScale, timeScale, () => battleComplete());
              
        // Add a start button
        this.startButton = this.add.sprite(this.cameras.main.centerX, this.cameras.main.centerY, 'battle_controls','button_start_battle_default.png')        
          .setInteractive({ useHandCursor: true })
          .on('pointerdown', () => clickStart(this))
          .on('pointerover', () => this.startButton.setFrame('button_start_battle_hover.png'))
          .on('pointerout', () => this.startButton.setFrame('button_start_battle_default.png'));
        this.startButton.scale = baseScale * 2; 
        this.startButton.alpha = 0;
                                
        // Tween it all in
        this.tweens.add(
          {
            targets: [this.opponentText,this.playerText,this.startButton],
            alpha: 1,				
            ease: 'linear',
            duration: 1000, 
            delay: 0  
          }
        );   
      }    
                 
      function update() {
        let bReady = true;

        // Wait for the start click
        if (this.startShips) {        
          // Call the update routine of all of our objects
          for (let i = 0; i < playerShips.length; i++) {          
            if (playerShips[i].update() === false) {
              bReady = false;
            }
          }
          for (let i = 0; i < enemyShips.length; i++) {          
            if (enemyShips[i].update() === false) {
              bReady = false;
            }
          }

          if (bReady && this.allInPlace === false) {
            this.allInPlace = true;
            this.battleDisplay.battleStarted = true;

            // Show the stats displays on all ships
            for (let i = 0; i < playerShips.length; i++) {          
              playerShips[i].showStatsDisplays();
            }
            for (let i = 0; i < enemyShips.length; i++) {          
              enemyShips[i].showStatsDisplays();
            }            
          }

          // Call the update routine on the battle display
          if (!this.isPaused) {
            this.battleDisplay.update();
          }
        }       
      }     
    }
    
  }, [ gameState.battleResult ]);

  function clickStart (phaserRef) {          
    if (phaserRef.startShips === false) {   
      // Start the ships
      phaserRef.startShips = true;  
      startTheShips();

      // Play ship sound
      try {
        phaserRef.game.sound.play("arrive");
      } catch (e) {}

      // Start the music
      try {
        phaserRef.game.sound.play("music");  
      } catch (e) {}
          
      // Hide start button 
      phaserRef.tweens.add(
        {
          targets: [phaserRef.startButton],
          alpha: 0,				
          ease: 'linear',
          duration: 1000, 
          delay: 0,
          onComplete: () => destroyStart(phaserRef)
        }
      );  

      // Placement variables
      let nextX = phaserRef.baseScale*75;
      let nextY = (phaserRef.game.canvas.clientHeight - (phaserRef.baseScale*75));

      // Create a control panel rectangle
      phaserRef.controlPanel = phaserRef.add.rectangle(0, nextY, phaserRef.baseScale*1050, phaserRef.baseScale*150,0x000000, 0.6);
      
      // Add a pause/play button
      phaserRef.pauseButton = phaserRef.add.sprite(nextX, nextY, 'battle_controls','control_panel_pause.png')        
        .setInteractive({ useHandCursor: true })
        .on('pointerdown', () => clickPause(phaserRef))
        .on('pointerover', () => phaserRef.pauseButton.setFrame('control_panel_pause_hover.png'))
        .on('pointerout', () => phaserRef.pauseButton.setFrame('control_panel_pause.png'));    
      phaserRef.pauseButton.scale = phaserRef.baseScale; 
      phaserRef.pauseButton.alpha = 0;
    
      // Add a fast forward button
      nextX = nextX + phaserRef.baseScale*125;
      phaserRef.ffButton = phaserRef.add.sprite(nextX, nextY, 'battle_controls','control_panel_forward.png')        
        .setInteractive({ useHandCursor: true })
        .on('pointerdown', () => clickFastForward(phaserRef))
        .on('pointerover', () => phaserRef.ffButton.setFrame('control_panel_forward_hover.png'))
        .on('pointerout', () => phaserRef.ffButton.setFrame('control_panel_forward.png'));     
      phaserRef.ffButton.scale = phaserRef.baseScale; 
      phaserRef.ffButton.alpha = 0;

      // Add a skip button
      nextX = nextX + phaserRef.baseScale*125;
      phaserRef.skipButton = phaserRef.add.sprite(nextX, nextY, 'battle_controls','control_panel_skip.png')        
        .setInteractive({ useHandCursor: true })
        .on('pointerdown', () => clickSkip(phaserRef))
        .on('pointerover', () => phaserRef.skipButton.setFrame('control_panel_skip_hover.png'))
        .on('pointerout', () => phaserRef.skipButton.setFrame('control_panel_skip.png'));  
      phaserRef.skipButton.scale = phaserRef.baseScale; 
      phaserRef.skipButton.alpha = 0;
          
      // Add a mute button
      nextX = nextX + phaserRef.baseScale*125;
      phaserRef.muteButton = phaserRef.add.sprite(nextX, nextY, 'battle_controls','control_panel_mute.png')        
        .setInteractive({ useHandCursor: true })
        .on('pointerdown', () => clickMute(phaserRef))
        .on('pointerover', () => phaserRef.muteButton.setFrame('control_panel_mute_hover.png'))
        .on('pointerout', () => phaserRef.muteButton.setFrame('control_panel_mute.png'));      
      phaserRef.muteButton.scale = phaserRef.baseScale; 
      phaserRef.muteButton.alpha = 0;

      // Set mute if we were previously muted
      let startMuted = window.localStorage.getItem('muteSimulation');
      if (startMuted && startMuted === 'true') {
        phaserRef.isMuted = true;
        phaserRef.game.sound.mute = true;
        phaserRef.muteButton.setFrame('control_panel_sound.png');
        phaserRef.muteButton.on('pointerover', () => phaserRef.muteButton.setFrame('control_panel_sound_hover.png'))
        phaserRef.muteButton.on('pointerout', () => phaserRef.muteButton.setFrame('control_panel_sound.png')); 
      }    
        
      // Tween all buttons in
      phaserRef.tweens.add(
        {
          targets: [phaserRef.pauseButton,phaserRef.ffButton,phaserRef.skipButton,phaserRef.muteButton],
          alpha: 1,				
          ease: 'linear',
          duration: 1000, 
          delay: 0  
        }
      );
    }      
  }

  function clickFastForward (phaserRef) {      
    if (phaserRef.isFastForward) {
      phaserRef.isFastForward = false;
      phaserRef.ffButton.setFrame('control_panel_forward.png');
      phaserRef.ffButton.on('pointerover', () => phaserRef.ffButton.setFrame('control_panel_forward_hover.png'))
      phaserRef.ffButton.on('pointerout', () => phaserRef.ffButton.setFrame('control_panel_forward.png')); 
    } else {
      phaserRef.isFastForward = true;
      phaserRef.ffButton.setFrame('control_panel_stop.png');
      phaserRef.ffButton.on('pointerover', () => phaserRef.ffButton.setFrame('control_panel_stop_hover.png'))
      phaserRef.ffButton.on('pointerout', () => phaserRef.ffButton.setFrame('control_panel_stop.png')); 
    }    
  }

  function clickSkip (phaserRef) {
    phaserRef.battleDisplay.skipToEnd();
    battleComplete();    
  }

  function clickMute (phaserRef) {
    if (phaserRef.isMuted) {
      phaserRef.isMuted = false;
      phaserRef.game.sound.mute = false;
      phaserRef.muteButton.setFrame('control_panel_mute.png');
      phaserRef.muteButton.on('pointerover', () => phaserRef.muteButton.setFrame('control_panel_mute_hover.png'))
      phaserRef.muteButton.on('pointerout', () => phaserRef.muteButton.setFrame('control_panel_mute.png')); 
      window.localStorage.setItem('muteSimulation', 'false');

    } else {
      phaserRef.isMuted = true;
      phaserRef.game.sound.mute = true;
      phaserRef.muteButton.setFrame('control_panel_sound.png');
      phaserRef.muteButton.on('pointerover', () => phaserRef.muteButton.setFrame('control_panel_sound_hover.png'))
      phaserRef.muteButton.on('pointerout', () => phaserRef.muteButton.setFrame('control_panel_sound.png')); 
      window.localStorage.setItem('muteSimulation', 'true');
    }
  }

  function clickPause (phaserRef) {
    if (phaserRef.isPaused) {
      phaserRef.isPaused = false;
      phaserRef.pauseButton.setFrame('control_panel_pause.png');
      phaserRef.pauseButton.on('pointerover', () => phaserRef.pauseButton.setFrame('control_panel_pause_hover.png'))
      phaserRef.pauseButton.on('pointerout', () => phaserRef.pauseButton.setFrame('control_panel_pause.png'));
    } else {
      phaserRef.isPaused = true;
      phaserRef.pauseButton.setFrame('control_panel_play.png');
      phaserRef.pauseButton.on('pointerover', () => phaserRef.pauseButton.setFrame('control_panel_play_hover.png'))
      phaserRef.pauseButton.on('pointerout', () => phaserRef.pauseButton.setFrame('control_panel_play.png'));
    }    
  }

  function destroyStart(phaserRef) {
    // Kill the start button
    phaserRef.startButton.destroy();
  }

  function startTheShips () {    
    battleWasStarted = true;
  }

  function battleComplete () {
    battleCompleted = true;    
    if (battleWasStarted) {
      setTimeout(() => continueComplete(),5000);    
    } else {
      continueComplete();
    }    
  }

  function showShipInfo(ship) { 
    if (battleCompleted === false) {   
      dispatch(viewShipDetails(ship));
    }
  }

  function continueComplete() {
    dispatch(showBattleResults());
    
    game.sound.stopAll();
  }

  return (    
    <div className={classes['main-content']}>            
      {gameState.battleResult ? 
        <div className={classes['battle-content']}>          
          <div id="phaser-root"></div>           
        </div>   
      :
        <div className={classes['waiting-content']}>Initiating Battle...</div>
      }
    </div>
  );
};

export default ShowBattlePhaser;