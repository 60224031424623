import axios from "../../axios-cc";
import { WaxAuthClient } from "wax-auth-client";
import { Anchor } from "ual-anchor";
import { Wax } from "@eosdacio/ual-wax";
import { UALJs } from "ual-plainjs-renderer";
import { loginFailure, loginSuccess } from "../actions/waxActions";
import { getGameDataRequest, setBusyAutoLogin, setGeneralBusyMessage } from "../actions/gameActions";

const auth = new WaxAuthClient();

let nonce;
let loggedInUser;
let waxAddress;

let anchor;
let wax;
let ual;

let dispatchRef;
let capToken;

const chains = [
  {
    chainId: "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
    rpcEndpoints: [{
      protocol: "https",
      host: "wax.greymass.com",
      port: "443",
  }]},  
  {
    chainId: "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
    rpcEndpoints: [{
      protocol: "https",
      host: "api.wax.alohaeos.com",
      port: "443",
  }]},
  {
    chainId: "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
    rpcEndpoints: [{
      protocol: "https",
      host: "api.waxsweden.org",
      port: "443",
  }]},
  {
    chainId: "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
    rpcEndpoints: [{
      protocol: "https",
      host: "wax.pink.gg",
      port: "443",
  }]},
  {
    chainId: "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
    rpcEndpoints: [{
      protocol: "https",
      host: "wax.eosphere.io",
      port: "443",
  }]}
];

// Initialize UAL, happens automatically on application load
export const initializeUAL = (token, rpcIndex, dispatch) => {
  dispatchRef = dispatch;
  capToken = token;

  try {
    anchor = new Anchor([chains[rpcIndex]], { appName: "cosmic-clash" });
    wax = new Wax([chains[rpcIndex]]);

    ual = new UALJs(
      userCallback,
      [chains[rpcIndex]],
      "Login",
      [wax, anchor],
      {
        containerElement: document.getElementById("ual-div"),
      }
    );
    ual.init();  

    // Fix the button text    
    const button = document.getElementById("ual-button");  
    if (button) {
      button.innerHTML = 'Login';      
    }  
  } catch (e) {
    console.log("Error Logging In", e);

    // Show play again    
    const button = document.getElementById("fgl-login-button");  
    if (button) {
      button.style.display = 'inherit';      
    }   
  }
}

// UAL calls this when it has initialized
const userCallback = async (users) => {
  console.log("userCallback", users);
  
  loggedInUser = users[0];
  waxAddress = await loggedInUser.getAccountName();

  getNonce();
}

const getNonce = async () => { 
  let response = await axios.post('/api/get-nonce', { waxAddress: waxAddress, token: capToken });
  nonce = response.data.nonce;

  if (!nonce) {
    // Login failed
    dispatchRef(loginFailure({ error: response.data.error, errorCode: response.data.errorCode, fatalError: response.data.fatalError})); 
    return;
  } 
  
  // Go right into verify
  verifyUAL(dispatchRef);  
}

export const verifyUAL = async (dispatch) => {  
  const tx = auth.getTransactionData(nonce, waxAddress);  
  const result = await loggedInUser.signTransaction(tx.data, tx.options);

  const proof = { 
    serializedTransaction: result.transaction.serializedTransaction,
    signatures: result.transaction.signatures
  };
  
  // Verify the session using the proof
  try {
    let response = await axios.post('/api/verify', { proof: proof });
    
    if (response.data.message && response.data.message === "success") {
      // Success!  We are now logged in
      dispatch(
        loginSuccess({
          userAccount: waxAddress                   
        })
      );

      // Hide the auto login
      dispatch(setBusyAutoLogin(false));      

    } else {        
      console.log("verify failed", response);
      dispatch(
        loginFailure({
          fatalError: "Failed to verify login, please try again later [201]", 
          errorCode: 201
        })
      ); 
    }
  } catch (e) {      
    console.log("verify error", e);
    dispatch(
      loginFailure({
        fatalError: "Unknown login issue, please try again later [202]",
        errorCode: 202
      })
    ); 
  }      
}

/*
  Worker Saga: Fired on USER_LOGIN action 
*/
export const deployFleet = async( amount ) => {
  try {    
     const result = await loggedInUser.signTransaction({
       actions: [{
         account: 'fgltokenmgmt',
         name: 'transfer',
         authorization: [{
           actor: waxAddress,
           permission: 'active',
         }],
         data: {
           from: waxAddress,
           to: 'cosmicclashr',
           quantity: amount.toFixed(4) + ' FGL',
           memo: 'Fleet Deployment Cost',
         },
       }]
     }, {
       blocksBehind: 3,
       expireSeconds: 1200,
     });
     
     // Return the result
     return ({
       message: "success",
       result: result.transactionId
     });
                 
   } catch (e) {  
    console.log("deploy failed", e);  
     return ({
       message: "failure",
       error: e.message
     });    
   }
 }

/*
  Log out from the UAL
*/
export const logout = async(history) => {  
  try {    
     await ual.logoutUser();

     // Clear the general busy
     dispatchRef(setGeneralBusyMessage(""));

     // Go to the home page
     history.push("/");

     // Refresh
     window.location.reload(true);
                 
   } catch (e) {  
    console.log("logout failed", e);         
   }
 }