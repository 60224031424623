import { useSelector } from "react-redux";
import Modal from '../components/General/Misc/Modal';

import { getUserSelector } from "../store/selectors/userSelectors";
import ErrorModal from "../components/General/Misc/ErrorModal";
import { WaxState } from "../store/types/waxTypes";
import { GameState } from "../store/types/gameTypes";
import { getGameStateSelector } from "../store/selectors/gameSelectors";
import ShipDetail from "../components/ShipDetail/ShipDetail";
import GeneralErrorModal from "../components/General/Misc/GeneralErrorModal";
import GeneralBusyModal from "../components/General/Misc/GeneralBusyModal";
import FatalErrorModal from "../components/General/Misc/FataErrorModal";
import BattleResults from "../components/BattleResults/BattleResults";
import WaxErrorModal from "../components/General/Misc/WaxErrorModal";
import MobileErrorModal from "../components/Mobile/General/MobileErrorModal";
import MobileGeneralErrorModal from "../components/Mobile/General/MobileGeneralErrorModal";
import MobileWaxErrorModal from "../components/Mobile/General/MobileWaxErrorModal";
import MobileFatalErrorModal from "../components/Mobile/General/MobileFataErrorModal";
import MobileShipDetail from "../components/Mobile/MobileShipDetail/MobileShipDetail";
import MobileBattleResults from "../components/Mobile/MobileBattleResults/MobileBattleResults";
import BusyAutoLoginModal from "../components/General/Misc/BusyAutoLoginModal";
import MobileBattleLog from "../components/Mobile/MobileBattleLog/MobileBattleLog";
import BattleLog from "../components/BattleLog/BattleLog";


const Layout = (props:any) => {
  const user:WaxState = useSelector(getUserSelector);
  const gameState:GameState = useSelector(getGameStateSelector);

  return (
    <>           
      <Modal show={user.pending} >    
        <GeneralBusyModal message="Logging In..." />        
      </Modal>

      <Modal show={user.error || user.fatalError ? true : false} >
        {gameState.mobileMode ?
          <MobileErrorModal />
        :
          <ErrorModal />
        }
      </Modal>
          
      <Modal show={gameState.shipDetails ? true : false} >        
        {gameState.mobileMode ?
          <MobileShipDetail />          
        :
          <ShipDetail />
        }          
      </Modal>
      
      <Modal show={gameState.generalError !== "" ? true : false} >
        {gameState.mobileMode ?
          <MobileGeneralErrorModal message={gameState.generalError} />
        : 
          <GeneralErrorModal message={gameState.generalError} />
        }
      </Modal>

      <Modal show={gameState.generalBusyMessage !== "" ? true : false} >        
        <GeneralBusyModal message={gameState.generalBusyMessage} />
      </Modal>
      
      <Modal show={gameState.error !== "" ? true : false} >
        {gameState.mobileMode ?
          <MobileGeneralErrorModal message={gameState.error} />
        :
          <GeneralErrorModal message={gameState.error} />
        }
      </Modal>

      <Modal show={gameState.fatalError !== "" ? true : false} >
        {gameState.mobileMode ?
          <MobileFatalErrorModal message={gameState.fatalError} />
        :
          <FatalErrorModal message={gameState.fatalError} />
        }
      </Modal>
                
      <Modal show={gameState.showBattleResultsPopup ? true : false} >
        {gameState.mobileMode ?
          <MobileBattleResults />
        :
          <BattleResults />
        }
      </Modal>

      <Modal show={gameState.showBattleLog ? true : false} >
        {gameState.mobileMode ?
          <MobileBattleLog />
        :
          <BattleLog />
        }
      </Modal>

      <Modal show={gameState.busyAutoLogin} >        
        <BusyAutoLoginModal />
      </Modal>
                           
      <main>          
        {props.children}
      </main>
    </>    
  );
};

export default Layout;
