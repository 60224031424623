import classes from './FatalErrorModal.module.css';
import {ReactComponent as CrossIcon} from '../../../assets/svg/cross.svg';

interface FatalErrorProps {
  message: string;
}

const FatalErrorModal = ( { message }:FatalErrorProps ) => {
    
  const clickOK = () => {
    window.location.href = '/';
  }

 return(
  <div className={classes['main-content']}>           
    <div className={classes['popup-content-image']}>         
      <div onClick={() => clickOK()} className={classes['cross-holder']}>        
        <CrossIcon className={classes['cross-icon']} />        
      </div>                       
      <div className={classes['popup-title']}>Fatal Error</div>                             
      <div className={classes['message-text']}>{message}</div>      
      <div className={classes['button-section']}>                   
        <div onClick={() => clickOK()} className={classes['return-button']}>OK</div>
      </div>
    </div>
  </div>
  );
};

export default FatalErrorModal;