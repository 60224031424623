import {
  VIEW_SHIP_DETAILS,
  CLEAR_SHIP_DETAILS,
  SET_GENERAL_ERROR,
  SET_GENERAL_BUSY_MESSAGE,
  SET_WAX_ERROR,
  SET_BUSY_AUTO_LOGIN,
  GET_BATTLE_STREAM_REQUEST,
  GET_BATTLE_STREAM_SUCCESS,
  GET_BATTLE_STREAM_FAILURE,
  CLEAR_GAME_ERROR,
  CLEAR_BATTLE,
  SHOW_BATTLE_LOG,
  HIDE_BATTLE_LOG,
  SHOW_BATTLE_RESULTS,
  HIDE_BATTLE_RESULTS,
  UPDATE_MOBILE_MODE,  
  WATCH_BATTLE,
  FIND_BATTLES,
  FIND_BATTLES_SUCCESS,
  FIND_BATTLES_FAILURE
} from "../actions/actionTypes";
import {  
  ViewShipDetailsRequest,
  ClearShipDetailsRequest,
  SetGeneralErrorRequest,
  SetGeneralBusyMessageRequest,
  SetWaxErrorMessageRequest,
  SetBusyAutoLoginRequest,
  GetBattleStreamRequest,
  GetBattleStreamSuccessPayload,
  GetBattleStreamSuccess,
  GetBattleStreamFailurePayload,
  GetBattleStreamFailure,
  IShip,
  ClearGameErrorRequest,
  ClearBattleRequest,
  ShowBattleLogRequest,
  HideBattleLogRequest,
  ShowBattleResultsRequest,
  HideBattleResultsRequest,
  UpdateMobileModeRequest,
  IBattleResult,
  WatchBattleRequest,
  FindBattlesRequest,
  FindBattlesSuccessPayload,
  FindBattlesFailurePayload,
  FindBattlesSuccess,
  FindBattlesFailure,  
} from "../types/gameTypes";


// Retrieving of Battle stream
export const getBattleStream = ( lastBattleId:number ): GetBattleStreamRequest => ({
  type: GET_BATTLE_STREAM_REQUEST,
  lastBattleId: lastBattleId
});
export const getBattleStreamSuccess = (
  payload: GetBattleStreamSuccessPayload
): GetBattleStreamSuccess => ({
  type: GET_BATTLE_STREAM_SUCCESS,
  payload,
});
export const getBattleStreamFailure = (
  payload: GetBattleStreamFailurePayload
): GetBattleStreamFailure => ({
  type: GET_BATTLE_STREAM_FAILURE,
  payload,
});

// Find battles
export const findBattles = ( battleId:string, playerName:string ): FindBattlesRequest => ({
  type: FIND_BATTLES,
  battleId: battleId,
  playerName: playerName
});
export const findBattlesSuccess = (
  payload: FindBattlesSuccessPayload
): FindBattlesSuccess => ({
  type: FIND_BATTLES_SUCCESS,
  payload,
});
export const findBattlesFailure = (
  payload: FindBattlesFailurePayload
): FindBattlesFailure => ({
  type: FIND_BATTLES_FAILURE,
  payload,
});

// Watch a battle
export const watchBattle = ( battle: IBattleResult ): WatchBattleRequest => ({
  type: WATCH_BATTLE,
  payload: battle
});

// Viewing the detail of a ship
export const viewShipDetails = ( ship: IShip ): ViewShipDetailsRequest => ({
  type: VIEW_SHIP_DETAILS,
  payload: ship
});
export const clearShipDetails = (): ClearShipDetailsRequest => ({
  type: CLEAR_SHIP_DETAILS
});

// Setting a general error popup
export const setGeneralError = ( message: string ): SetGeneralErrorRequest => ({
  type: SET_GENERAL_ERROR,
  payload: message
});

// Setting a general busy message
export const setWaxErrorMessage = ( message: string ): SetWaxErrorMessageRequest => ({
  type: SET_WAX_ERROR,
  payload: message
});

// Setting a general busy message
export const setGeneralBusyMessage = ( message: string ): SetGeneralBusyMessageRequest => ({
  type: SET_GENERAL_BUSY_MESSAGE,
  payload: message
});

// Setting auto login busy modal
export const setBusyAutoLogin = ( show: boolean ): SetBusyAutoLoginRequest => ({
  type: SET_BUSY_AUTO_LOGIN,
  payload: show
});

// Clear game state error
export const clearGameError = (): ClearGameErrorRequest => ({
  type: CLEAR_GAME_ERROR
});

export const clearBattleResults = (): ClearBattleRequest => ({
  type: CLEAR_BATTLE
});
export const showBattleLog = (): ShowBattleLogRequest => ({
  type: SHOW_BATTLE_LOG
});
export const hideBattleLog = (): HideBattleLogRequest => ({
  type: HIDE_BATTLE_LOG
});
export const showBattleResults = (): ShowBattleResultsRequest => ({
  type: SHOW_BATTLE_RESULTS
});
export const hideBattleResults = (): HideBattleResultsRequest => ({
  type: HIDE_BATTLE_RESULTS
});

// Setting mobile mode
export const updateMobileMode = ( mobile: boolean ): UpdateMobileModeRequest => ({
  type: UPDATE_MOBILE_MODE,
  payload: mobile
});
