import { combineReducers } from "redux";

import userReducer from "../reducers/userReducer";
import gameReducer from "./gameReducer";

const rootReducer = combineReducers({
  user: userReducer,
  game: gameReducer  
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;